import React, { useEffect } from 'react'
import { useActionData, useLoaderData, useNavigation, useParams } from 'react-router'
import Exemptions from '@components/exemptions/exemptions'
import useTimelineEvents from '@hooks/useTimelineEvents'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'
import { captureException } from '@sentry/react'
import { useToast } from '@hooks/useToast'
import StudentProfileTimeline from '@components/student-profile/timeline'
import { Alert, Button } from '@design-system'
import SoraLink from '@components/link'

export default function ExemptionsEditRoute() {
  const { student_id } = useParams()
  const { mutate: mutateTimeline } = useTimelineEvents(student_id)
  const loaderData = useLoaderData()
  const actionData = useActionData()
  const { staticExemptions, isReadOnly, employee_id, subjects } = loaderData
  const navigation = useNavigation()

  useToast(actionData)

  useEffect(() => {
    if (navigation.state === 'idle' && actionData?.toast.appearance === 'success') {
      fetch(`/api/timeline-event/create`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          student_id,
          type: 'Exemption Update',
          employee_id,
          context: actionData.context,
        }),
      }).then(() => mutateTimeline?.())
        .catch(console.error)
    }
  }, [navigation.state, actionData])

  return (
    <>
      {loaderData.hasFeatureFlagStudentProgramEnabled && (
        <Alert variant="notice">
          <Alert.Title>This page is being phased out.</Alert.Title>
          <Alert.Description>Please use the new Program page for the latest features and updates.</Alert.Description>
          <Alert.Actions>
            <Button asChild size="xs" variant="outlined">
              <SoraLink to={`../program/${loaderData.schoolStage}/overview`}>
                Go to Program page
              </SoraLink>
            </Button>
          </Alert.Actions>
        </Alert>
      )}
      <section className="flex gap-4">
        <div className="py-8">
          <Exemptions
            student_id={Number(student_id)}
            clearTopicId={navigation.state === 'idle' && actionData?.clearTopicId}
            staticExemptions={staticExemptions}
            isReadOnly={isReadOnly}
            subjects={subjects}
          />
        </div>
        <StudentProfileTimeline />
      </section>
    </>
  )
}

export const loader = async ({ params }) => {
  const { data } = await axios.get(`/pages/employee/students/${params.studentId}/exemptions`)
  return data
}

export const action = async ({ request, params }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  const { _action, units: allUnits, ...formBody } = serializedFormData
  const exemptUnits = Object.values(allUnits || {}).filter(u => u.is_exempt).map(u => ({ ...u, id: Number(u.id), is_exempt: !!u.is_exempt }))

  try {
    const { data } = await axios.post(`/pages/employee/students/${params.studentId}/exemptions`, {
      _action,
      units: exemptUnits,
      ...formBody,
    })
    return data

  } catch (error) {
    captureException(error)
    return {
      toast: {
        message: 'Oops. Something went wrong!',
        appearance: 'error',
      }
    }
  }

}
