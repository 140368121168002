import React from 'react'
import { unstable_Table as Table, Skeleton } from '@design-system'

export const TableSkeleton = ({ columnsLength, rows }) => (
  <Table.Body>
    {Array.from({ length: rows }, (_, j) => (
      <Table.Row key={j} >
        <Table.Cell colSpan={columnsLength}>
          <Skeleton key={j} className="h-11" />
        </Table.Cell>
      </Table.Row>
    ))}
  </Table.Body> 
)
