/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faArrowCircleRight,
  faArrowCircleLeft,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons'
import { useParams, useSearchParams } from 'react-router'
// hooks
import useGradProgress from './hooks/useGradProgress'
// components
import ProgressIndicator from '../ProgressIndicator'
import Slideover from '../../../components/slideover'
import Notification from '../../../components/notification'
// utils
import round from '../../../utils/round'
// content
import StrategiesContent from './slideover_content/strategies'
import LearnMoreContent from './slideover_content/learn_more'
import ProgressContent from './slideover_content/progress'
import CatchUpContent from './slideover_content/catch_up'
import LearnAboutProgressCard from '../learn-about-progress-card'
import differenceInMonths from 'date-fns/differenceInMonths'
import axios from 'axios'
import { Button } from '@designsystem'

export default function HsPanel({ data }) {
  const { student_id } = useParams()
  const { cycle_id } = useSearchParams()
  const [carouselPage, setCarouselPage] = useState(0)
  const handlePrevious = () => {
    setCarouselPage(prev => prev > 0 ? prev - 1 : prev)
  }
  const handleNext = () => {
    setCarouselPage(prev => prev <= 2 ? prev + 1 : prev)
  }
  const [sliderOpen, setSliderOpen] = useState(false)
  const [activeContentSlider, setActiveContentSlider] = useState(0)
  const [filter] = useState('on_program')

  const subjects = data.subjects || []
  const categoriesWithAbilities = data.categoriesWithAbilities || []
  const canEditProgram = data.canEditProgram

  const contentSliderTitles = {
    0: 'Strategies to improve your GPA ',
    1: 'Catch-Up for an on time graduation',
    2: 'Learn about units and abilities and how they impact your pace & GPA',
    3: 'Progress Reports',
  }

  const {
    gpa,
    overallProgress,
    isProgramValid,
    expectedGraduation,
    programValidnessMessages,
    mutate: mutateGradProgress,
    currentGPACalculationAt,
    nextGPACalculationAt,
  } = useGradProgress({ student_id, cycle_id, filter })

  const now = new Date()
  const grad = new Date(expectedGraduation)
  var timeUntilGraduation = grad.getTime() - now.getTime()
  var daysUntilGraduation = timeUntilGraduation / (1000 * 3600 * 24)

  const expectedProgress = expectedGraduation ? Math.floor(
    (1 -
      differenceInMonths(
        new Date(expectedGraduation),
        new Date()
      ) /
      48) *
    100
  ) : 100

  const overallProgressNumber = overallProgress
    ? parseFloat(overallProgress.slice(0, overallProgress.length - 1))
    : 0

  const toggleSlider = (id) => {
    if (sliderOpen) {
      setSliderOpen(false)
      setTimeout(() => {
        setSliderOpen(true)
        setActiveContentSlider(id)
      }, 500)
    } else {
      setSliderOpen(true)
      setActiveContentSlider(id)
    }
  }

  const getBarProgressForElectives = () => {
    const totalCompletions = subjects.reduce((acc, sub) => {
      let electiveCompletions = 0
      for (let i in sub.topics) {
        let topic = sub.topics[i]
        if (topic.is_elective) {
          electiveCompletions += topic.completions
        }
      }
      return acc + electiveCompletions
    }, 0)

    const totalTarget = subjects.reduce((acc, sub) => {
      let electiveTarget = 0
      for (let i in sub.topics) {
        let topic = sub.topics[i]
        if (topic.is_elective) {
          electiveTarget += topic.target
        }
      }
      return acc + electiveTarget
    }, 0)

    return round((totalCompletions / totalTarget) * 100)
  }

  const getBarProgressBySubject = (subject_id) => {
    const subjectIds = subject_id.length ? subject_id : [subject_id]
    const selectedSubjects = subjects.filter((sub) =>
      subjectIds.includes(sub.id) || subjectIds.includes(sub.parent_subject_id)
    )

    const totalCompletions = selectedSubjects.reduce((acc, sub) => {
      return acc + Number(sub.completions)
    }, 0)

    const totalTarget = selectedSubjects.reduce((acc, sub) => {
      return acc + Number(sub.target)
    }, 0)

    return round((totalCompletions / totalTarget) * 100)
  }

  const SubjectProgressbar = ({ progress }) => {
    return (
      <div className="relative w-full h-6 rounded-full bg-gray-30 dark:bg-gray-60 overflow-hidden">
        <span
          style={{ width: progress + '%' }}
          className="absolute bg-turquoise-40 dark:bg-green-30  h-full block rounded-l-full"
        />
      </div>
    )
  }

  const handleFixProgram = ({ type }) => {
    axios
      .post(`pages/employee/students/${student_id}/graduation`, {
        type,
      })
      .then(() => mutateGradProgress())
  }

  return (
    <div className="pb-12">
      <Slideover
        open={sliderOpen}
        setOpen={setSliderOpen}
        title={contentSliderTitles[activeContentSlider]}
      >
        {activeContentSlider === 0 && <StrategiesContent />}
        {activeContentSlider === 1 && <CatchUpContent />}
        {activeContentSlider === 2 && <LearnMoreContent />}
        {activeContentSlider === 3 && <ProgressContent />}
      </Slideover>
      <h1 className="group-[.flutter]/flutter:hidden font-bold text-4xl whitespace-nowrap mb-4">
        Graduation Progress
      </h1>
      <div className="space-y-8">
        {isProgramValid == 'No' && programValidnessMessages.length > 0 && (
          <Notification color="red">
            <h3 className="font-bold text-lg text-center">Program Invalid!</h3>
            <div className="flex items-center justify-between">
              <div className="flex flex-col mx-auto text-center">
                {programValidnessMessages.map((msg) => {
                  return <span key={msg}>{msg}</span>
                })}
              </div>
              {canEditProgram && (
                <div className="flex flex-col space-y-2">
                  <Button
                    onClick={() =>
                      handleFixProgram({ type: 'fix_with_defaults' })
                    }
                  >
                    Defaults fix!
                  </Button>
                  <Button
                    onClick={() =>
                      handleFixProgram({ type: 'fix_with_completeds' })
                    }
                  >
                    Fully completeds fix!
                  </Button>
                </div>
              )}
            </div>
          </Notification>
        )}
        <LearnAboutProgressCard page="graduation" />
      </div>
      <section className="w-full grid grid-cols-6 gap-4 mt-4">
        <div className="col-span-6 lg:col-span-4 relative">
          <section className='bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg p-6'>
            <header className='flex justify-between mb-6'>
              <div className='w-10'>
                {carouselPage > 0 && (
                  <FontAwesomeIcon
                    icon={faArrowCircleLeft}
                    className="h-8 w-8 text-gray-100 hover:text-gray-90 dark:text-gray-5 dark:hover:text-gray-20 cursor-pointer"
                    onClick={handlePrevious}
                  />
                )}
              </div>
              <h2 className='font-bold text-xl'>
                {carouselPage === 0 && 'GPA'}
                {carouselPage === 1 && 'Units'}
                {carouselPage === 2 && 'Abilities'}
              </h2>
              <div className='w-10 text-right'>
                {carouselPage < 2 && (
                  <FontAwesomeIcon
                    icon={faArrowCircleRight}
                    className="h-8 w-8 text-gray-100 hover:text-gray-90 dark:text-gray-5 dark:hover:text-gray-20 cursor-pointer"
                    onClick={handleNext}
                  />
                )}
              </div>
            </header>
            {carouselPage === 0 && (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-6">
                <div className="mx-auto flex flex-col relative">
                  <ProgressIndicator
                    percent={gpa > 4.0 ? 1 : gpa / 4.0}
                    twsize="60"
                    cutoff="0"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <span className="font-bold text-5xl">{gpa}</span>
                    </div>
                  </ProgressIndicator>
                  <p className="text-sm text-center mt-4">
                    Estimated GPA as of{' '}
                    {currentGPACalculationAt
                      ? new Date(currentGPACalculationAt).toLocaleDateString([], {
                        month: 'short',
                        day: 'numeric',
                      })
                      : '-'}
                  </p>
                  <p className="text-sm text-center">
                    Next GPA update will be calculated on{' '}
                    {nextGPACalculationAt
                      ? new Date(nextGPACalculationAt).toLocaleDateString([], {
                        month: 'short',
                        day: 'numeric',
                      })
                      : '-'}
                  </p>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex-1">
                    <h3 className="text-lg">Expected Graduation</h3>
                    <span className="text-2xl font-bold">
                      {expectedGraduation
                        ? new Date(expectedGraduation).getFullYear()
                        : '-'}
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="flex flex-row items-center space-x-2">
                      <h3 className="text-lg">Graduation Progress</h3>
                      <div>
                        {daysUntilGraduation < 365 &&
                          75 - overallProgressNumber > 10 && (
                            <div className="relative group">
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                                className="h-5 w-5 text-danger-60 cursor-pointer"
                              />
                              <span className="absolute right-[-100px] hidden group-hover:block z-50 border border-gray-30 shadow bg-white dark:bg-gray-100 dark:border-gray-90 p-4 rounded-lg text-sm w-[200px]">
                                You're at risk for not graduating on time. Meet
                                with your House Advisor to come up with a plan if
                                you're still planning to graduate this year.
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="w-24 h-24 block">
                      <ProgressIndicator
                        percent={parseInt(overallProgress) / 100}
                        twsize="24"
                        cutoff="0"
                      >
                        {overallProgress}
                      </ProgressIndicator>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {carouselPage === 1 && (
              <>
                <div className='grid grid-cols-6 items-center'>
                  <h2 className='hidden lg:block lg:col-span-1'></h2>
                  <div className='col-span-6 lg:col-span-5 grid grid-cols-4'>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>01</span>
                    </h3>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>02</span>
                    </h3>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>03</span>
                    </h3>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>04</span>
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col gap-6 relative my-4">
                  <div className='grid grid-cols-1 items-center'>
                    <h2 className='col-span-1'>Math</h2>
                    <div className='col-span-1'>
                      <SubjectProgressbar progress={getBarProgressBySubject(5)} />
                    </div>
                  </div>
                  <div className='grid rid-cols-1 items-center'>
                    <h2 className='col-span-1'>Science</h2>
                    <div className='col-span-1'>
                      <SubjectProgressbar progress={getBarProgressBySubject(6)} />
                    </div>
                  </div>
                  <div className='grid rid-cols-1 items-center'>
                    <h2 className='col-span-1'>Social Studies</h2>
                    <div className='col-span-1'>
                      <SubjectProgressbar progress={getBarProgressBySubject(7)} />
                    </div>
                  </div>
                  <div className='grid rid-cols-1 items-center'>
                    <h2 className='col-span-1'>E.L.A.</h2>
                    <div className='col-span-1'>
                      <SubjectProgressbar progress={getBarProgressBySubject(18)} />
                    </div>
                  </div>
                  <div className='grid rid-cols-1 items-center'>
                    <h2 className='col-span-1'>Electives</h2>
                    <div className='col-span-1'>
                      <SubjectProgressbar progress={getBarProgressForElectives()} />
                    </div>
                  </div>
                  <div
                    className={`absolute h-full w-[1px] border-l-4 border-success-50 dark:border-success-40 border-dotted -top-1`}
                    style={{ left: expectedProgress + '%' }}
                  >
                    <span className={`absolute -bottom-8 ${expectedProgress < 20 ? 'left-0' : 'right-0'} text-sm text-success-50 font-bold dark:text-success-40 whitespace-nowrap`}>
                      Target ({expectedProgress + '%'})
                    </span>
                  </div>
                </div>
              </>
            )}
            {carouselPage === 2 && (
              <>
                <div className='grid grid-cols-6 items-center'>
                  <h2 className='hidden lg:block lg:col-span-2'></h2>
                  <div className='col-span-6 lg:col-span-4 grid grid-cols-4'>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>01</span>
                    </h3>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>02</span>
                    </h3>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>03</span>
                    </h3>
                    <h3 className="uppercase tracking-wider font-bold text-xs lg:text-sm text-right">
                      Year <span className='block lg:inline-block'>04</span>
                    </h3>
                  </div>
                </div>
                <div className="flex flex-col gap-6 relative my-4">
                  {categoriesWithAbilities.map((category) =>
                    category.abilities.map(ability => (
                      <div key={ability.id} className='grid grid-cols-1 items-center gap-2'>
                        <h2 className='col-span-1'>{ability.title}</h2>
                        <div className='col-span-1'>
                          <SubjectProgressbar key={ability.id} progress={round((ability.completions / ability.target) * 100)} />
                        </div>
                      </div>
                    ))
                  )}
                  <div
                    className={`absolute h-full w-[1px] border-l-4 border-success-50 dark:border-success-40 border-dotted -top-1`}
                    style={{ left: expectedProgress + '%' }}
                  >
                    <span className={`absolute -bottom-8 ${expectedProgress < 20 ? 'left-0' : 'right-0'} text-sm text-success-50 font-bold dark:text-success-40 whitespace-nowrap`}>
                      Target ({expectedProgress + '%'})
                    </span>
                  </div>
                </div>
              </>
            )}
          </section>
        </div>
        <div className="col-span-6 lg:col-span-2 grid grid-cols-2 gap-4">
          <div
            className="bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg p-4 hover:shadow cursor-pointer"
            onClick={() => toggleSlider(0)}
          >
            <div className="flex items-center">
              <p className="flex-1 pr-4">Strategies to improve your GPA</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            className="bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg p-4 hover:shadow cursor-pointer"
            onClick={() => toggleSlider(1)}
          >
            <div className="flex items-center">
              <p className="flex-1 pr-4">Catch up for an on-time graduation</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            className="bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg p-4 hover:shadow cursor-pointer"
            onClick={() => toggleSlider(2)}
          >
            <div className="flex items-center">
              <p className="flex-1 pr-4">
                Learn about units, abilities, and how they affect your progress
                & GPA
              </p>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div
            className="bg-white dark:bg-gray-95 border border-gray-30 dark:border-gray-90 rounded-lg p-4 hover:shadow cursor-pointer"
            onClick={() => toggleSlider(3)}
          >
            <div className="flex items-center">
              <p className="flex-1 pr-4">Progress and achievements report</p>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
