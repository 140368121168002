import React from 'react'
import { LoaderFunctionArgs, redirect } from 'react-router'
import axios from 'axios'
import { EmptyState } from '@design-system'
import Mascot from './mascot.svg'

async function loader({ params }: LoaderFunctionArgs) {
  const { houseId } = params
  const { data } = await axios.get(`/backoffice/houses/${houseId}/overview/_index`)
  if (data.id) {
    return redirect(`/houses/${houseId}/overview/${data.id}`)
  }
  return {}
}

function Element() {
  return (
    <EmptyState>
      <EmptyState.Illustration>
        <img src={Mascot} alt="Nothing to report" />
      </EmptyState.Illustration>
      <EmptyState.Title>Nothing to report</EmptyState.Title>
      <EmptyState.Description>There is nothing to report this week</EmptyState.Description>
    </EmptyState>
  )
}

export const HouseOverviewIndexRoute = {
  loader,
  Element,
}


