import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useFetcher } from "react-router"
import { Button, Icon } from "@design-system"

export const MathPlacementSelect = ({ studentId, cycleId, currentPlacement, selectedId, canEdit = false }) => {
  const fetcher = useFetcher({ key: `student_math_placement_${studentId}` })
  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('student_id')) === Number(studentId)
  
  const ref = useRef(null)

  useEffect(() => {
    ref.current = fetcher.data?.availableMathPlacement
  }, [fetcher.data?.availableMathPlacement])
  
  const handleChange = async (event) => {
    return fetcher.submit(event.target.form)
  }

  if (fetcher.data?.availableMathPlacement) {
    return (
      <div className="flex flex-row gap-2 items-center">
        <fetcher.Form method="POST" className="flex-1" >
          <input type="hidden" name="_action" value="update_student_math_placement" />
          <input type="hidden" name="student_id" value={studentId} />
          <input type="hidden" name="cycle_id" value={cycleId} />
          <select name="math_placement_id" className="w-full rounded border border-gray-200 text-xs" disabled={isLoading} onChange={handleChange} defaultValue={selectedId ? selectedId : 'null'}>
            <option value="null">None</option>
            {fetcher.data?.availableMathPlacement.map((placement) => (
              <option key={`std_${studentId}_placement_${placement.id}`} value={placement.id} disabled={placement.completed} className="disabled:text-gray-50">
                {placement.title}
              </option>
            ))}
          </select>
        </fetcher.Form>
        <fetcher.Form method="GET" >
          <input type="hidden" name="_action" value="clean" />
          <Button variant="ghost" size="xs" loading={isLoading} >
            <Icon name="slash" size="sm" />
          </Button>
        </fetcher.Form>
      </div>
    )
  }

  const mathPlacementId = Number(fetcher.formData?.get('math_placement_id'))
  const resolvedPlacement = fetcher.formData?.get('math_placement_id') ? (ref.current.find((placement) => placement.id === mathPlacementId)?.title || 'None') : currentPlacement

  const isNone = resolvedPlacement === 'None'
  const isCompleted = resolvedPlacement === 'Completed'

  let colorStyle = 'px-3 bg-white text-black'
  if (isNone) colorStyle = 'px-3 bg-white text-danger font-bold'
  if (isCompleted) colorStyle = 'border-transparent text-green-40 font-bold'

  return (
    <div className="flex flex-row gap-2 items-center">
      <span className={`flex-1 border rounded py-1 text-sm text-blue-50 ${colorStyle}`}>{resolvedPlacement}</span>
      {(!isCompleted && canEdit) &&
        <fetcher.Form method="GET" >
          <input type="hidden" name="_action" value="list_student_math_placements" />
          <input type="hidden" name="student_id" value={studentId} />
          <input type="hidden" name="cycle_id" value={cycleId} />
          <Button type="submit" variant="ghost" size="xs" loading={isLoading}>
            <Icon name="edit-2" size="sm" />
          </Button>
        </fetcher.Form>
      }
    </div>
  )
}
MathPlacementSelect.propTypes = {
  cycleId: PropTypes.number,
  studentId: PropTypes.number,
  selectedId: PropTypes.number,
  currentPlacement: PropTypes.string,
  canEdit: PropTypes.bool,
}
