import axios from 'axios'
import React, { useState } from 'react'
import { useLoaderData, ActionFunctionArgs, Link } from 'react-router'
import { Button, Icon, Typography, unstable_Collapsible as Collapsible } from '@design-system'
import { LoaderResponse, PerkAndFeedbacks } from './types'
import SoraLink from '@components/link'
import { RenderedSlate } from '@components/forms/slate-textarea'

import EmptyFeedback from './empty-feedback.svg'

async function loader({ params }) {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/metrics`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/metrics`,
    formData
  )
  return res.data
}

function Element() {
  return (
    <div className="flex flex-col w-[460px] overflow-hidden bg-white border-x">
      <PageHeader />
      <PageBody />
    </div>
  )
}

function PageHeader() {
  return (
    <>
      <div className="flex flex-row justify-between pt-4 px-6 items-center">
        <Typography variant="heading-5" weight="bold">
          Expedition
        </Typography>
        <SoraLink className="flex-none" to={'..'} as={Link}>
          <Button variant="outlined" color="soft" size="xs">
            <Icon name="cross" />
          </Button>
        </SoraLink>
      </div>
    </>
  )
}

function PageBody() {
  const loaderData = useLoaderData() as LoaderResponse

  return (
    <div className="flex flex-col overflow-auto pt-6 px-6 gap-2 min-w-72 w-[28.75rem] grow">
      <div className="grid grid-cols-2 gap-2">
        {loaderData.showFlaggedAndOnTimeCards && (
          <>
            <div className="flex flex-col gap-4 p-4 border rounded-lg border-neutral-200">
              <Typography variant="subheadline">Tasks on time</Typography>
              <Typography variant="heading-2" weight="bold">
                {loaderData.metrics.newPercentageTasksOntime}
              </Typography>
            </div>
          </>
        )}
        <div className="flex flex-col gap-4 p-4 border rounded-lg border-neutral-200">
          <Typography variant="subheadline">Missed Tasks</Typography>
          <Typography variant="heading-2" weight="bold">
            {loaderData.metrics.newTotalMissed}
          </Typography>
        </div>
        <div className="flex flex-col gap-4 p-4 border rounded-lg border-neutral-200">
          <Typography variant="subheadline">Attendance</Typography>
          <Typography variant="heading-2" weight="bold">
            {loaderData.metrics.percentageAttendance}
          </Typography>
        </div>
      </div>

      <Typography variant="subheadline" weight="bold" className="my-6">
        Feedback History
      </Typography>
      {loaderData.unitsAndFeedbacks.length + loaderData.abilitiesAndFeedbacks.length > 0 ? (
        <>
          <PerkSection title={'Units'} perksAndFeedbacks={loaderData.unitsAndFeedbacks} />
          <PerkSection title={'Abilities'} perksAndFeedbacks={loaderData.abilitiesAndFeedbacks} />
        </>
      ) : (
        <div className="flex flex-1 flex-col items-center justify-center">
          <img className="object-scale-down mb-8" src={EmptyFeedback} alt="Empty perks" />
          <Typography variant="body" weight="bold">
            No feedback yet
          </Typography>
          <Typography variant="body" className="text-center">
            This student hasn't yet received units and abilities feedback for this expedition
          </Typography>
        </div>
      )}
    </div>
  )
}

function PerkSection({ title, perksAndFeedbacks }: { title: string; perksAndFeedbacks: Array<PerkAndFeedbacks> }) {
  if (!perksAndFeedbacks || perksAndFeedbacks.length <= 0) return null
  return (
    <>
      <Typography weight="bold">
        {title} ({perksAndFeedbacks.length})
      </Typography>
      <div className="flex flex-col gap-1 mb-6">
        {perksAndFeedbacks.map((perk: PerkAndFeedbacks) => {
          return (
            <div key={`outer-collapsible-${perk.id}`} className="p-2 bg-stone-50 rounded">
              <OuterCollapsible title={perk.title} averageMastery={perk.averageMastery}>
                {perk.feedbackData.map((el, index) => {
                  return (
                    <TaskCollapsible
                      key={`task-collapsible-${index}-${perk.id}-${el.createdAt}`}
                      title={el.taskTitle}
                      createdAt={el.createdAt}
                      flag={el.flag}
                    >
                      <RenderedSlate value={el.body} />
                    </TaskCollapsible>
                  )
                })}
              </OuterCollapsible>
            </div>
          )
        })}
      </div>
    </>
  )
}

interface OuterCollapsibleProps {
  /**
   * The title of the collapsible section.
   */
  title: string

  /**
   * The average mastery value, which can be a string or number.
   */
  averageMastery: string | number

  /**
   * The content to display within the collapsible section.
   */
  children: React.ReactNode

  /**
   * Indicates if the collapsible section should be open by default.
   * Defaults to false.
   */
  defaultOpen?: boolean
}

const OuterCollapsible: React.FC<OuterCollapsibleProps> = React.memo(function ({
  title,
  averageMastery,
  children,
  defaultOpen = false,
}) {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Collapsible defaultOpen={defaultOpen} onOpenChange={setOpen}>
      <Collapsible.Trigger asChild>
        <div className="flex justify-between cursor-pointer">
          <div className="flex flex-col">
            <Typography variant="callout" weight="bold">
              {title} {open.toString()}
            </Typography>
            <Typography variant="footnote">Current mastery: {averageMastery}</Typography>
          </div>
          <Icon name={open ? 'chevron-up' : 'chevron-down'} />
        </div>
      </Collapsible.Trigger>
      <Collapsible.Content>{children}</Collapsible.Content>
    </Collapsible>
  )
})

interface TaskCollapsibleProps {
  /**
   * Indicates the status of the task.
   * 'ontrack' means the task is progressing well.
   * 'needs_improvement' indicates the task requires attention.
   * Any other string value will display a default emoji.
   */
  flag: 'ontrack' | 'needs_improvement' | string

  /**
   * The title of the task.
   */
  title: string

  /**
   * Date parsable string e.g: '2024-04-27 00:44:52.434247+00'
   * This represents the creation date of the task.
   */
  createdAt: string

  /**
   * The content to display within the collapsible section.
   */
  children: React.ReactNode

  /**
   * Indicates if the collapsible section should be open by default.
   * Defaults to false.
   */
  defaultOpen?: boolean
}

const TaskCollapsible: React.FC<TaskCollapsibleProps> = React.memo(function ({
  flag,
  title,
  createdAt,
  children,
  defaultOpen = false,
}) {
  const [open, setOpen] = useState(defaultOpen)

  return (
    <Collapsible defaultOpen={defaultOpen} onOpenChange={setOpen}>
      <Collapsible.Trigger className="border-t mt-1 cursor-pointer" asChild>
        <div className="flex justify-between py-1">
          <div className="flex items-center gap-2">
            {FlagEmoji({ flag })}
            <Typography variant="footnote" weight="bold">
              {title}
            </Typography>
          </div>

          <div className="flex items-center gap-2">
            <Typography variant="footnote" className="border rounded px-2 py-1">
              {new Intl.DateTimeFormat('en-US').format(new Date(createdAt)).replace(/, \d{4}$/, '')}
            </Typography>
            <div className="p-2">{open ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />}</div>
          </div>
        </div>
      </Collapsible.Trigger>

      <Collapsible.Content>{children}</Collapsible.Content>
    </Collapsible>
  )
})

interface FlagEmojiProps {
  /**
   * Indicates the status of the task.
   * 'ontrack' means the task is progressing well and displays a thumbs-up emoji.
   * 'needs_improvement' indicates the task requires attention and displays a mountain emoji.
   * Any other string or null value will display a soon emoji.
   */
  flag: 'ontrack' | 'needs_improvement' | string | null
}

/**
 * FlagEmoji is a component that displays an emoji based on the provided flag status.
 * @returns {JSX.Element} The rendered FlagEmoji component.
 */
const FlagEmoji: React.FC<FlagEmojiProps> = function ({ flag }) {
  switch (flag) {
    case 'ontrack':
      return <div className="flex items-center p-1 text-center bg-white rounded-md">👍</div>
    case 'needs_improvement':
      return <div className="text-center p-1 bg-white rounded-md">⛰️</div>
    default:
      return <div className="text-center p-1 bg-white rounded-md">🔜</div>
  }
}

export const FacilitateExperienceTaskStudentMetricsRoute = {
  loader,
  action,
  Element,
}
