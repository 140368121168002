import React from 'react'
import PropTypes from 'prop-types'
import { Combobox, useFetcher } from '@design-system'
import { useSearchParams } from 'react-router'

export const SchoolYearSelector = ({ schoolYears = [], defaultValue }) => {
  const fetcher = useFetcher()
  const [_, setSearchParams] = useSearchParams()

  const isLoading = fetcher.state !== 'idle'

  const handleChange = (event) => {
    event.preventDefault()
    const urlParams = new URLSearchParams(document.location.search)
    const currentParams = Object.fromEntries(urlParams)
    setSearchParams({ ...currentParams, schoolYear: event.target.value })

    fetcher.submit(event.target.form)
  }

  return (
    <div className='flex flex-row justify-end items-center'>
      <fetcher.Form method="GET" onChange={handleChange} className='flex flex-col'>
        <label className='text-xs'>School Year</label>
        <Combobox name='schoolYear' defaultValue={defaultValue || schoolYears.slice(-1)[0].value} loading={isLoading}>
          {schoolYears.map(schoolYear => (
            <Combobox.Item key={schoolYear.value} value={schoolYear.value}>{schoolYear.label}</Combobox.Item>
          ))}
        </Combobox>
      </fetcher.Form>
    </div>
  )
}
SchoolYearSelector.propTypes = {
  schoolYears: PropTypes.array,
  defaultValue: PropTypes.number,
}
