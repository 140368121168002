import React from 'react'
import { Form, useActionData, useNavigation, useSubmit } from 'react-router'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import { useToast } from '@hooks/useToast'
import { ActionData } from './types'

export default function WorkbenchCannySyncUsersRoute() {
  const submit = useSubmit()
  const confirmAction = useConfirmModal()
  const navigation = useNavigation()
  useToast(useActionData() as ActionData)
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const confirmationMessage = {
      title: 'Confirm Sync', subtitle: 'Are you sure you want to sync the users?', children: <CannySyncConfirmationModalMessage />
    }
    if (await confirmAction(confirmationMessage)) {
      submit(formData, { method: 'PUT' })
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-bold text-2xl">Sync Users</h1>
      <span>Here, you can synchronize all users from Sora Home with Canny, ensuring all active users <i>(Employee, Student or Guardian)</i> will be added to Canny with their respective Custom Field Role.</span>
      <span>Roles that are currently mapped to Canny Custom Fields: <i>employee, student, guardian.</i></span>
      <span className="italic font-bold text-sm">*This sync auto executes daily at 6PM UTC Time. Execute this in case you recently changed something and want to see the changes reflected immediately.</span>
      <Form method="PUT" onSubmit={handleSubmit}>
        <Button
          type="submit"
          loading={navigation.state === 'submitting'}
        >
          {navigation.state === 'submitting' ? 'Syncing...' : 'Sync Users with Canny'}
        </Button>
      </Form>
    </div>
  )
}


function CannySyncConfirmationModalMessage() {
  return (
    <div className="pb-4">
      <span> This sync will:</span>
      <ul className="list-disc ml-4">
        <li>Add active users from Sora Home that are not currently in Canny with the correct role</li>
        <li>Delete from canny all users that are inactive in Sora Home</li>
      </ul>
    </div>
  )
}
