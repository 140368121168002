import React from 'react'
import axios from 'axios'
import { redirect, useLoaderData } from 'react-router'
import { parse, format, setDay } from 'date-fns'
import { Card, Pill, Typography, Button, Icon } from '@design-system'
import { type ExperienceCategoryVariant, PillExperienceCategory } from '@blocks/pill-experience-category'
import SoraLink from '@components/link'
import HouseMeetingPlaceholder from './house-meeting.webp'

interface HomeLoaderProps {
  employee: Employee
  meeting?: Meeting
  house?: House
  experiences: Experience[]
}
interface Employee {
  name: string
}
interface Meeting {
  title: string
  startTime: string
  conferenceUrl: string
  imageUrl?: string
}
interface House {
  id: number
  logoUrl: string
  title: string
  defaultStandupTime?: string
  studentsCount: number
}
interface Experience {
  id: number
  title: string
  category: ExperienceCategoryVariant
  imageUrl?: string
  imageAlt?: string
  daysOfTheWeek: number[]
  time: string
  facilitateType: string
}


const loader = async () => {
  const { data } = await axios.get('/backoffice/home')
  if (data.experiences.length === 0 && !data.house) {
    return redirect('/employee/expeditions/edit')
  }
  return data
}

function Element() {
  const { employee, house, experiences, meeting } = useLoaderData() as HomeLoaderProps
  return (
    <div className="flex flex-col gap-8">
      <section>
        <Typography variant="heading-3" weight="bold">Welcome, {employee.name}</Typography>
      </section>
      {meeting && (
        <section className="space-y-4">
          <Typography variant="heading-6" weight="bold">Quick actions</Typography>
          <Card className="h-56 dark bg-gray-100 object-cover object-center" style={{ backgroundImage: `url('${meeting.imageUrl ?? HouseMeetingPlaceholder}')` }}>
            <Card.Content padding="xs" className="p-6 flex flex-col md:flex-row justify-end md:justify-between md:items-end gap-4 h-full bg-gradient-to-b from-transparent to-black/50 rounded-lg">
              <div className="space-y-2">
                <Pill variant="blur">
                  <Pill.Value>
                    {new Date(meeting.startTime).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                  </Pill.Value>
                </Pill>
                <Typography variant="heading-4" weight="bold">{meeting.title}</Typography>
              </div>
              <Button asChild>
                <a href={meeting.conferenceUrl} target="_blank" rel="noreferrer">
                  <Icon name="video-camera" />
                  Start meeting
                </a>
              </Button>
            </Card.Content>
          </Card>
        </section>
      )}
      {house && (
        <section className="space-y-4">
          <Typography variant="heading-6" weight="bold">Your house</Typography>
          <Card actionable asChild>
            <SoraLink to={`/houses/${house.id}/overview`}>
              <img src={house.logoUrl} alt={house.title} className="w-32 my-6 ml-6 aspect-square object-center object-cover" />
              <Card.Content padding="xs" className="space-y-2">
                <Typography variant="heading-4" weight="bold">{house.title}</Typography>
                <Typography>
                  {house.studentsCount} students
                  {house.defaultStandupTime && ` | House meeting ${format(new Date(house.defaultStandupTime), 'h:mm a')}`}
                </Typography>
              </Card.Content>
              <Card.Arrow />
            </SoraLink>
          </Card>
        </section>
      )}
      {experiences.length > 0 && (
        <section className="space-y-4">
          <Typography variant="heading-6" weight="bold">Your experiences</Typography>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {experiences.map((experience) => (
              <SoraLink to={`/facilitate/${experience.facilitateType}/${experience.id}`} key={experience.id}>
                <article className="space-y-4">
                  <figure className="relative">
                    <img src={experience.imageUrl} alt={experience.imageAlt} className="rounded-xl aspect-video w-full" />
                    <div className="absolute inset-0 rounded-xl flex flex-col justify-end p-4 space-y-2 bg-gradient-to-b from-transparent to-alpha/50">
                      {experience.daysOfTheWeek && experience.time && (
                        <Pill variant="blur">
                          <Pill.Value>
                            {`${experience.daysOfTheWeek.map((day) => format(setDay(new Date(), day), 'E')).join(' / ')} - ${format(new Date(experience.time), 'h:mm a')}`}
                          </Pill.Value>
                        </Pill>
                      )}
                      <PillExperienceCategory variant={experience.category} />
                    </div>
                  </figure>
                  <Typography variant="subheadline" weight="bold" className="hover:underline">{experience.title}</Typography>
                </article>
              </SoraLink>
            ))}
          </div>
        </section>
      )}
    </div>
  )
}

export const HomeRoute = {
  loader,
  Element,
}
