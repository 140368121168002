import React from 'react'
import { useNavigation, useSearchParams } from 'react-router'
import { Pill, unstable_Tooltip as Tooltip, Skeleton } from '@design-system'
import pluralize from '@utils/pluralize'
import { Student } from './types'
import { Trend } from './trend'

interface CellTaskSubmissionProps extends Pick<Student, 'assignedTasksCount' | 'completedTasksCount' | 'trendCompletedTasksCount' | 'missedFinalTasksCount'> { }

export const CellTaskSubmission = ({ assignedTasksCount, completedTasksCount, trendCompletedTasksCount, missedFinalTasksCount }: CellTaskSubmissionProps) => {
  const [searchParams] = useSearchParams()
  const navigation = useNavigation()
  const timeframe = searchParams.get("timeframe") ?? "lastSevenDays"
  if (navigation.state === 'loading' || assignedTasksCount === undefined || completedTasksCount === undefined)
    return <Skeleton data-testid='skeleton' className="h-5" />
  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <Tooltip content={`Student has submitted ${completedTasksCount}/${assignedTasksCount} tasks during the ${timeframe === "lastSevenDays" ? 'last 7 days' : 'current cycle'}.`}>
          <span>{completedTasksCount}/{assignedTasksCount}</span>
        </Tooltip>
        <Trend label="Task submission" value={trendCompletedTasksCount} />
      </div>
      {missedFinalTasksCount && (
        <Pill color="danger-50">
          <Pill.Value>{missedFinalTasksCount} missed {pluralize(missedFinalTasksCount, 'final')}</Pill.Value>
        </Pill>
      )}
    </div>
  )
}
