import * as React from 'react'
import axios from 'axios'
import { ActionFunctionArgs, Form, LoaderFunctionArgs, useActionData, useLoaderData, useNavigate } from 'react-router'
import { useToast } from '@hooks/useToast'
import { DatePickerInput } from './date-picker'
import serializeFormData from '@utils/serializeFormData'
import { Button, Switch, Typography, Drawer } from '@design-system'

type LoaderData = {
  id: number,
  title: string,
  start_timestamp: string,
  end_timestamp: string,
  school_year: string,
  enable_activities_registration: boolean,
  show_ms_theme_options: boolean,
  show_experts: boolean,
  enable_heartbeat_experiences_sync: boolean,
  browse_available_at: string,
  request_list_freeze_start: string,
  request_list_freeze_end: string,
  swap_period_start: string,
  swap_period_end: string,
  active: boolean,
}

type ActionData = {
  redirect?: string,
  toast: {
    message: string,
    appearance: 'success' | 'error',
  }
}

type DateReducerState = {
  startTimestamp: Date | null,
  endTimestamp: Date | null,
  browseAvailableAt: Date | null,
  requestListFreezeStart: Date | null,
  requestListFreezeEnd: Date | null,
  swapPeriodStart: Date | null,
  swapPeriodEnd: Date | null,
}

type DateReducerAction = {
  type: 'update',
  payload: {
    name: string,
    value: Date,
  }
}

async function loader({ params }: LoaderFunctionArgs) {
  const res = await axios.get(`/backoffice/cycles/${params.cycleId}`)
  return res.data

}
async function action({ request, params }: ActionFunctionArgs) {
  const cycles = serializeFormData(await request.formData())
  const enable_activities_registration = cycles.enable_activities_registration === 'on'
  const show_ms_theme_options = cycles.show_ms_theme_options === 'on'
  const show_experts = cycles.show_experts === 'on'
  const enable_heartbeat_experiences_sync = cycles.enable_heartbeat_experiences_sync === 'on'
  const active = cycles.active === 'on'
  const res = await axios.post(`/backoffice/cycles/${params.cycleId}`, { ...cycles, enable_activities_registration, show_ms_theme_options, show_experts, enable_heartbeat_experiences_sync, active })
  return res.data
}

function reducer(state: DateReducerState, action: DateReducerAction) {
  if (action.type === 'update') {
    return {
      ...state,
      [action.payload.name]: action.payload.value,
    }
  }
  return state
}

function Element() {
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData
  const [datePickersState, dispatch] = React.useReducer(reducer, {
    startTimestamp: loaderData.start_timestamp ? new Date(loaderData.start_timestamp) : null,
    endTimestamp: loaderData.end_timestamp ? new Date(loaderData.end_timestamp) : null,
    browseAvailableAt: loaderData.browse_available_at ? new Date(loaderData.browse_available_at) : null,
    requestListFreezeStart: loaderData.request_list_freeze_start ? new Date(loaderData.request_list_freeze_start) : null,
    requestListFreezeEnd: loaderData.request_list_freeze_end ? new Date(loaderData.request_list_freeze_end) : null,
    swapPeriodStart: loaderData.swap_period_start ? new Date(loaderData.swap_period_start) : null,
    swapPeriodEnd: loaderData.swap_period_end ? new Date(loaderData.swap_period_end) : null,
  })
  const actionData = useActionData() as ActionData
  useToast(actionData)

  React.useEffect(() => {
    if (actionData?.redirect) {
      navigate(actionData.redirect)
    }
  }, [actionData?.redirect])

  return (
    <Drawer isOpen={true} onClose={() => navigate(-1)} width="lg">
      <Drawer.Title>{loaderData.title}</Drawer.Title>
      <Drawer.Content>
        <Form id="cycle-form" method="POST" className="flex flex-col gap-4">
          <DatePickerInput
            date={datePickersState.startTimestamp}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'startTimestamp', value: date } })}
            required
            fullWidth
            name="start_timestamp"
            label="Cycle start date"
            defaultValue={datePickersState.startTimestamp.toISOString()}
          />
          <DatePickerInput
            date={datePickersState.endTimestamp}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'endTimestamp', value: date } })}
            required
            fullWidth
            name="end_timestamp"
            label="Cycle end date"
            defaultValue={datePickersState.endTimestamp.toISOString()}
          />
          <DatePickerInput
            date={datePickersState.browseAvailableAt}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'browseAvailableAt', value: date } })}
            required
            fullWidth
            name="browse_available_at"
            label="Browse available at"
            defaultValue={datePickersState.browseAvailableAt.toISOString()}
          />
          <DatePickerInput
            date={datePickersState.requestListFreezeStart}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'requestListFreezeStart', value: date } })}
            required
            fullWidth
            name="request_list_freeze_start"
            label="Request list freeze start"
            defaultValue={datePickersState.requestListFreezeStart.toISOString()}
          />
          <DatePickerInput
            date={datePickersState.requestListFreezeEnd}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'requestListFreezeEnd', value: date } })}
            required
            fullWidth
            name="request_list_freeze_end"
            label="Request list freeze end"
            defaultValue={datePickersState.requestListFreezeEnd.toISOString()}
          />
          <DatePickerInput
            date={datePickersState.swapPeriodStart}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'swapPeriodStart', value: date } })}
            required
            fullWidth
            name="swap_period_start"
            label="Swap period start"
            defaultValue={datePickersState.swapPeriodStart.toISOString()}
          />
          <DatePickerInput
            date={datePickersState.swapPeriodEnd}
            handleChange={(date) => dispatch({ type: 'update', payload: { name: 'swapPeriodEnd', value: date } })}
            required
            fullWidth
            name="swap_period_end"
            label="Swap period end"
            defaultValue={datePickersState.swapPeriodEnd.toISOString()}
          />
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Typography variant="body" weight="bold" asChild>
                <label htmlFor="active">Make cycle active</label>
              </Typography>
              <Typography variant="callout">Make this cycle active for students and faculty</Typography>
            </div>
            <ActiveCycleSwitch active={loaderData.active} />
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Typography variant="body" weight="bold" asChild>
                <label htmlFor="enable_activities_registration">Enable Activities Registration</label>
              </Typography>
              <Typography variant="callout">Allow students to register for activities</Typography>
            </div>
            <Switch defaultChecked={loaderData.enable_activities_registration} name="enable_activities_registration" id="enable_activities_registration" />
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Typography variant="body" weight="bold" asChild>
                <label htmlFor="show_ms_theme_options">Show Ms Theme Options</label>
              </Typography>
              <Typography variant="callout">Show Theme options for MS students on expedition page</Typography>
            </div>
            <Switch defaultChecked={loaderData.show_ms_theme_options} name="show_ms_theme_options" id="show_ms_theme_options" />
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Typography variant="body" weight="bold" asChild>
                <label htmlFor="show_experts">Show Experts</label>
              </Typography>
              <Typography variant="callout">Show expert name on expedition page</Typography>
            </div>
            <Switch defaultChecked={loaderData.show_experts} name="show_experts" id="show_experts" />
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <Typography variant="body" weight="bold" asChild>
                <label htmlFor="enable_heartbeat_experiences_sync">Enable Heartbeat Experiences Sync</label>
              </Typography>
              <Typography variant="callout">Enable experiences sync on heartbeat workbench</Typography>
            </div>
            <Switch defaultChecked={loaderData.enable_heartbeat_experiences_sync} name="enable_heartbeat_experiences_sync" id="enable_heartbeat_experiences_sync" />
          </div>
        </Form>
      </Drawer.Content>
      <Drawer.Footer>
        <Button className="justify-end" form="cycle-form" type="submit">Apply Settings</Button>
      </Drawer.Footer>
    </Drawer>
  )
}

type ActiveCycleSwitchProps = Pick<LoaderData, 'active'>

function ActiveCycleSwitch({ active }: ActiveCycleSwitchProps) {
  // must be controlled, because if the switch is disabled, the form will not submit the field and it will end up without any active cycles.
  const [isActive, setIsActive] = React.useState(active)

  return (
    <>
      <input type="hidden" name="active" id="active" value={isActive ? 'on' : 'off'} />
      <Switch checked={isActive} disabled={active} onCheckedChange={(checked) => setIsActive(checked)} />
    </>
  )
}

export const CycleIdRoute = {
  loader, action, Element,
}