import React, { useState } from 'react'
import { Form, useSearchParams, useRevalidator } from 'react-router'
import { toast } from 'sonner'
import axios from 'axios'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import useToggle from '@hooks/useToggle'
import pluralize from '@utils/pluralize'
import { faArrowLeft, faArrowRight, faClose } from '@fortawesome/free-solid-svg-icons'
import { ExcuseActions, ExcuseId, IExcuseReport } from './types'
import { ReportTable } from './report-table'
import Select from '../../../../components/forms/select'
import DateTimePicker from '../../../../components/forms/date-time-picker'
import _debounce from 'lodash/debounce'

export function ExcuseReport({ data }: IExcuseReport) {
  const { revalidate } = useRevalidator()

  const confirm = useConfirmModal()

  const onSubmit = async (excuse_ids: ExcuseId, action: ExcuseActions) => {
    if (await confirm('Are you sure you want to ' + action + '?')) {
      if (!Array.isArray(excuse_ids)) excuse_ids = [excuse_ids].filter(Boolean)
      if (!excuse_ids?.length) return { toast: { message: 'No excuse selected', appearance: 'warning' } }
      try {
        await axios.put(`/pages/employee/attendances/report/excuse/${action}`, { excuse_ids })
        toast.success(pluralize(excuse_ids.length, 'Excuse') + ' handled successfully')
      } catch (e) {
        console.error(e)
        toast.error('No excuse could be handled')
      } finally {
        revalidate()
      }
    }
  }

  const onApproveExcuse = async (excuse_id: ExcuseId) => {
    await onSubmit(excuse_id, 'approve')
  }
  const onRejectExcuse = async (excuse_id: ExcuseId) => {
    await onSubmit(excuse_id, 'reject')
  }
  const onRemoveExcuse = async (excuse_id: ExcuseId) => {
    await onSubmit(excuse_id, 'remove')
  }

  return (
    <div className="flex flex-col h-full w-full" data-testid="excuse-report-table">
      <TableDataFilter />
      <Form method="POST">
        <ReportTable data={data.excuses} onApproveExcuse={onApproveExcuse} onRejectExcuse={onRejectExcuse} onRemoveExcuse={onRemoveExcuse} />
      </Form>
      <PaginationController totalCount={data.totalCount} />
    </div>
  )
}

function TableDataFilter() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFilters, toggleShowFilters] = useToggle(false)
  const [value, setValue] = useState(searchParams.get('search') || '')

  const onChangeFilter = _debounce((value: string, filterName: 'approved' | 'creator' | 'start_at' | 'end_at' | 'search') => {
    setSearchParams((sp: URLSearchParams) => {
      if (!value) {
        sp.delete(filterName);
      } else {
        sp.set(filterName, value);
      }

      // Ensure page count is reset to properly show filtered results
      sp.delete('page');

      return sp;
    });
  }, 300)

  const clearInput = () => {
    if (value) {
      onChangeFilter('', 'search')
      setValue('')
    }
  }

  const startAtParams = searchParams.get('start_at') ? Number(searchParams.get('start_at')) : Date.now() - 7 * 24 * 60 * 60 * 1000
  let endAtAtParams = searchParams.get('end_at') ? Number(searchParams.get('end_at')) : Date.now()
  if (endAtAtParams < startAtParams) {
    endAtAtParams = startAtParams
  }

  const approvedOpts = [
    { value: null, label: 'Both' },
    { value: 'true', label: 'Approved' },
    { value: 'false', label: 'Rejected' }
  ]
  const creatorOpts = [
    { value: null, label: 'Both' },
    { value: 'Guardian', label: 'Guardian' },
    { value: 'Employee', label: 'Employee' }
  ]

  return (
    <div className="flex flex-col space-y-1 w-full">
      <div className="flex items-center min-h-14">
        <span className="text-bold text-lg">Filters</span>
        {
          showFilters &&
          <div className='flex items-center space-x-5 ml-5 w-[50rem]'>
            <div className='flex flex-col w-full max-w-1/4'>
              <span className='text-xxs'>Approval Status</span>
              <Select
                placeholder='Select...'
                isClearable={false}
                isSearchable={false}
                options={approvedOpts}
                defaultValue={approvedOpts.find(ao => ao.value === searchParams.get('approved')) || approvedOpts[0]}
                onChange={(e) => {
                  onChangeFilter(e.value, 'approved')
                }}
              />
            </div>
            <div className='flex flex-col w-full max-w-1/4'>
              <span className='text-xxs'>Requester</span>
              <Select
                placeholder='Select...'
                isClearable={false}
                isSearchable={false}
                options={creatorOpts}
                defaultValue={creatorOpts.filter(co => co.value === searchParams.get('creator')) || creatorOpts[0]}
                onChange={(e) => {
                  onChangeFilter(e.value, 'creator')
                }}
              />
            </div>
            <div className='flex flex-col w-full max-w-1/4'>
              <span className='text-xxs'>Period Start</span>
              <DateTimePicker
                showTimeSelect={false}
                dateFormat='MMMM d, yyyy'
                value={startAtParams}
                onChange={(e) => {
                  if (!e.target.value) return
                  onChangeFilter((new Date(e.target.value)).getTime() + '', 'start_at')
                }}
              />
            </div>
            <div className='flex flex-col w-full max-w-1/4'>
              <span className='text-xxs'>Period End</span>
              <DateTimePicker
                showTimeSelect={false}
                dateFormat='MMMM d, yyyy'
                value={endAtAtParams}
                onChange={(e) => {
                  if (!e.target.value) return
                  onChangeFilter((new Date(e.target.value)).getTime() + '', 'end_at')
                }}
              />
            </div>
          </div>
        }
        <div className="ml-2">
          <Button onClick={toggleShowFilters} variant="outlined" size="xs" startIcon={showFilters ? faArrowLeft : faArrowRight} />
        </div>
      </div>
      <div className="flex w-full pr-1.5 items-center rounded-xl max-w-1/2 border-2 border-gray-10 border-invisible h-full bg-white focus-within:border-blue-40" data-testid="global-filter">
        <input
          className="flex-auto rounded-full pl-5 outline-none"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value)
            onChangeFilter(e.target.value, 'search')
          }}
          placeholder="Search for requester, email, reason or student"
          data-testid="global-filter-input"
        />
        <Button onClick={clearInput} startIcon={faClose} variant="nude" size="sm" color="gray" data-testid="global-filter-clear-btn" />
      </div>
    </div>
  )
}

function PaginationController({ totalCount }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const LIMIT = 50
  const getCurrentPage = () => Number(searchParams.get('page') || 1)
  const nextPage = () => {
    setSearchParams((sp: URLSearchParams) => {
      sp.set('page', '' + Math.min(Math.ceil(totalCount / LIMIT), (getCurrentPage() + 1)))
      return sp
    })
  }
  const previousPage = () => {
    setSearchParams((sp: URLSearchParams) => {
      sp.set('page', '' + Math.max(1, (getCurrentPage() - 1)))
      return sp
    })
  }

  const startRow = totalCount ? (getCurrentPage() - 1) * LIMIT + 1 : 0
  const endRow = Math.min(getCurrentPage() * LIMIT, totalCount)

  return (
    <div className="flex items-center pt-3 space-x-3">
      {(getCurrentPage() > 1) && <Button endIcon={faArrowLeft} size="xs" variant="outlined" onClick={previousPage} />}
      <span>Showing {startRow} to {endRow} rows</span>
      {(endRow < totalCount) && <Button endIcon={faArrowRight} size="xs" variant="outlined" onClick={nextPage} />}
    </div>
  )
}
