import React from 'react'
import { cn, Icon } from '@design-system'
import { cva, type VariantProps } from "class-variance-authority"

export interface SquareAbilityMasteryLevel extends VariantProps<typeof squareVariants> {
  level?: number
}

const squareVariants = cva(
  "relative group shrink-0 border rounded-xl flex justify-center items-center",
  {
    variants: {
      variant: {
        toDemonstrate: 'border-gray-20 dark:border-gray-85',
        inProgress: 'border-gray-20 dark:border-gray-85',
        exempt: 'border-gray-20 dark:border-gray-85',
        exposure: 'bg-[#DED8FF] border-[#DED8FF] text-[#4A28A1]',
        developing: 'bg-[#BCA4FF] border-[#BCA4FF] text-[#4A28A1]',
        demonstrating: 'bg-[#855CF8] border-[#855CF8] text-white',
        extending: 'bg-[#4A28A1] border-[#4A28A1] text-white',
      },
      size: {
        sm: 'h-8 w-8',
        md: 'h-11 w-11',
      }
    },
    defaultVariants: {
      variant: 'toDemonstrate',
      size: 'md'
    },
  }
)

const childrens = {
  toDemonstrate: () => (<></>),
  inProgress: () => (<Icon name="loader" size="sm" />),
  exempt: () => (<span className="font-extrabold text-lg text-black dark:text-white leading-none">E</span>),
  exposure: ({ level }) => (<span className="flex justify-center items-center">{level ?? '1'}</span>),
  developing: ({ level }) => (<span className="flex justify-center items-center">{level ?? '2'}</span>),
  demonstrating: ({ level }) => (<span className="flex justify-center items-center">{level ?? '3'}</span>),
  extending: ({ level }) => (<span className="flex justify-center items-center">{level ?? '4'}</span>),
}

/**
 * SquareAbilityMasteryLevel display a square with Ability Mastery Level
 */
export const SquareAbilityMasteryLevel = ({ variant, size, level }: SquareAbilityMasteryLevel) => {
  return (
    <div className={cn(squareVariants({ variant, size }))}>
      <span className="flex">{childrens[variant]?.({ level })}</span>
    </div>
  )
}
