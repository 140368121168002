import React from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import _refiner from 'refiner-js'
import { startFirebase } from '@services/push-notification'

import * as serviceWorker from './serviceWorker'
import './global.css'

import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router'
import App from './App'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const sentryEnabled = import.meta.env.VITE_APP_SENTRY_ENABLED
try {
  Sentry.init({
    dsn: 'https://4616c96f93444875a41a6a1d2e8bdff0@o485369.ingest.sentry.io/5540720',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
        block: ['#refiner-widget-frame']
      })
    ],
    tracesSampleRate: 0.05,
    release: import.meta.env.VITE_APP_GITHUB_SHA,
    environment: import.meta.env.VITE_APP_SENTRY_ENV || 'not_specified',
    enabled: sentryEnabled,
  })
} catch (error) {
  console.error(error)
}

const isProduction = import.meta.env.MODE === 'production'

if (isProduction) {
  try {
    _refiner('setProject', '1f381690-537a-11ec-b925-1b0e79a0f0c3')
  } catch (error) {
    console.error(error)
  }
  startFirebase()
}

const root = createRoot(
  document.getElementById('root')
)
root.render(
  <App />
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
