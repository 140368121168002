
import * as React from 'react'
import axios from 'axios'
import { LoaderFunctionArgs, useLoaderData, ActionFunctionArgs, Link, useParams } from 'react-router'
import SoraLink from '@components/link'
import serializeFormData from '@utils/serializeFormData'
import { Button, Icon, useFetcher, unstable_Tooltip as Tooltip, Typography, Avatar, Combobox } from '@design-system'

type ClubMember = {
  id: number,
  name: string,
  imageUrl: string,
  imageAlt: string,
  role: string,
}

type LoaderData = {
  currentMembers: ClubMember[],
  memberOptions: { value: number, label: string }[],
  clubTitle: string,
  shouldHideActions: boolean,
}

async function loader({ params }: LoaderFunctionArgs) {
  const { data } = await axios.get(`/backoffice/clubs/${params.clubId}/members`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = serializeFormData(await request.formData())
  const { data } = await axios.post(`/backoffice/clubs/${params.clubId}/members`, formData)
  return data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData
  const params = useParams()
  const primaryButtonTitle = loaderData.shouldHideActions ? 'Done' : 'Update members'

  return (
    <div className="overflow-auto mb-8">
      <div className="flex gap-2 mb-8">
        <Tooltip content="Back">
          <Button asChild variant="outlined" color="soft" size="sm" aria-label="Back">
            <SoraLink to="/clubs">
              <Icon name="arrow-left" size="sm" />
            </SoraLink>
          </Button>
        </Tooltip>
        <Typography variant="heading-3" weight="bold">Add members to {loaderData.clubTitle} </Typography>
      </div>
      <div className="flex flex-col gap-6">
        {!loaderData.shouldHideActions && (
          <>
            <Typography>Select club members to add to {loaderData.clubTitle}</Typography>
            <NewMembersCombobox memberOptions={loaderData.memberOptions} />
          </>
        )}
        <Typography variant="heading-6" weight="bold">Current members</Typography>
        <ul>
          {loaderData.currentMembers.map((cMember) => (
            <ClubMemberItem cMember={cMember} key={cMember.id} hideDeleteButton={loaderData.shouldHideActions} />
          ))}
        </ul>
      </div>
      <div className="bg-white border-t dark:bg-gray-90 border-t-1 items-center border-gray-40 px-40 h-16 w-full flex flex-row justify-end fixed bottom-0 left-0 right-0 z-10">
        <div className="space-x-2">
          <Button
            asChild
            size="sm"
            variant="outlined"
          >
            <Link to={`../clubs/${params.clubId}/edit`}>
              Back: General info
            </Link>
          </Button>
          <Button
            size="sm"
            asChild
          >
            <Link to="../clubs">
              {primaryButtonTitle}
            </Link>
          </Button>
        </div>
      </div>
    </div>
  )
}

function NewMembersCombobox({ memberOptions }: Pick<LoaderData, 'memberOptions'>) {
  const fetcher = useFetcher()

  return (
    <fetcher.Form
      method="POST"
      className="flex gap-2 items-end"
    >
      <input type="hidden" name="_action" value="add_member" />
      <Combobox
        key={`combobox-${memberOptions.length}`} // to reset every time the form is submitted
        required
        name="memberId"
        placeholder="Select member"
        className="w-full"
        label="Select club member"
        loading={fetcher.state === 'submitting'}
        search={<Combobox.Search placeholder="Search members" />}
      >
        {memberOptions.map((pMember) => (
          <Combobox.Item value={String(pMember.value)} key={`member_${pMember.value}`}>
            {pMember.label}
          </Combobox.Item>
        ))}
      </Combobox>
      <Button
        size="sm"
        type="submit"
        name="_action"
        className="mb-2"
        value="add_member"
        loading={fetcher.state === 'submitting'}
      >
        Add member
      </Button>
    </fetcher.Form>
  )
}

interface ClubMemberItemProps {
  cMember: ClubMember,
  hideDeleteButton: boolean,
}

function ClubMemberItem({ cMember, hideDeleteButton }: ClubMemberItemProps) {
  const fetcher = useFetcher()
  return (
    <li key={cMember.id} className="flex gap-4 py-2 border-t items-start border-gray-30 dark:border-gray-90 group">
      <Avatar size="xl">
        <Avatar.Image src={cMember.imageUrl} alt={cMember.imageAlt} />
        <Avatar.Fallback>{cMember.name}</Avatar.Fallback>
      </Avatar>
      <div className="flex flex-col md:flex-row md:items-center gap-4 grow">
        <div className="flex flex-col">
          <Typography variant="body" weight="bold" className="flex items-center gap-1">
            {cMember.name}
          </Typography>
          <Typography variant="callout">
            {cMember.role}
          </Typography>
        </div>
      </div>
      {cMember.role === 'Member' && !hideDeleteButton && (
        <fetcher.Form method="POST">
          <input type="hidden" name="_action" value="remove_member" />
          <Button variant="outlined" color="soft" value={cMember.id} name="memberId" size="sm" type="submit" loading={fetcher.state === 'submitting'}>
            <Icon name="trash-2" size="xs" />
          </Button>
        </fetcher.Form>
      )}
    </li>
  )
}

export const ClubsMembersRoute = {
  action,
  loader,
  Element,
}

