import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, Combobox, Icon, Spinner, useFetcher } from '@design-system'
import { ActionFunctionArgs, Form, LoaderFunctionArgs, useActionData, useLoaderData, useSubmit } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { useToast } from '@hooks/useToast'
import useClipboard from '@hooks/useClipboard'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toast } from 'sonner'
import { faCheck, faEdit, faExternalLink, faMedal, faTimes } from '@fortawesome/free-solid-svg-icons'
import Paginator from '@components/paginator'
import { useThrottle } from '@hooks/useThrottle'
import { TextField } from '@designsystem'

async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const res = await axios.post('/backoffice/workbench/rosetta-stone/manage-users', serializedFormData)
  return res.data
}

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const resp = await axios.get(`/backoffice/workbench/rosetta-stone/manage-users?${searchParams.toString()}`)
  return resp.data
}

interface LoaderData {
  students: Array<{
    id: number,
    name: string,
    email: string,
    username: string,
    language: string,
    instance: string,
    isActive: boolean,
  }>,
  headers: string[],
  rosettaLanguageOptions: Array<{
    language: string,
    instance: string,
  }>,
  totalPages: number,
  currentPage: number,
  instanceUrls: {
    gold: string,
    silver: string,
  }
}

interface ActionData {
  toast: {
    message: string,
    appearance: 'success' | 'error'
  }
}

function Element() {
  const loaderData = useLoaderData() as LoaderData
  const actionData = useActionData() as ActionData
  const [, copy] = useClipboard()
  const fetcher = useFetcher()
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedQuery = useThrottle(searchTerm, 400)
  const submit = useSubmit()

  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'

  useToast(actionData)

  useEffect(() => {
    if (fetcher.data?.success) {
      setSelectedStudent(null)
    }
  }, [fetcher.data])

  useEffect(() => {
    submit({ page: 1, search: debouncedQuery }, { method: 'GET' })
  }, [debouncedQuery])

  const students = loaderData.students

  return (
    <div className="flex flex-col gap-4 px-6">
      <Form id="filter-form" method="GET">
        <TextField
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          startAdornment={<Icon name="search" size="sm" />}
          name="search"
          placeholder="Search for student name, email or username"
          autoFocus
          autoComplete="off"
        />
      </Form>
      <div className="flex justify-center pt-2 pb-6">
        <Paginator formId="filter-form" totalPages={loaderData.totalPages} currentPage={loaderData.currentPage} />
      </div>
      <table className='text-center mb-2 w-full'>
        <thead className="text-xs">
          <tr className="bg-white dark:bg-gray-90">
            {loaderData.headers.map((header, i) => (
              <th key={i} className="top-0 sticky px-4 py-2 border border-gray-30 dark:border-gray-90">{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className="text-xs">
          {students.map(({ id, name, email, username, language, instance, isActive }, i) => (
            <tr key={i}>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{id}</td>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{name}</td>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">
                <div className="flex items-center justify-center">
                  {email}
                  <FontAwesomeIcon icon={faCopy} size="sm" className="hover:cursor-pointer ml-2 px-2 border rounded py-1" title="Copy student email" onClick={() => {
                    copy(email)
                    toast.success('Email copied!')
                  }} />
                </div>
              </td>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">{username}</td>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">
                {selectedStudent === id ? (
                  <fetcher.Form method="POST" className="flex flex-col gap-1">
                    <input type="hidden" name="id" value={id} />
                    <Combobox label="Select language" name="language" defaultValue={language} placeholder="Select language">
                      {loaderData.rosettaLanguageOptions.map(({ language, instance }, i) => (
                        <Combobox.Item key={i} value={language}>{language ? `${language} (${instance})` : 'None'}</Combobox.Item>
                      ))}
                    </Combobox>
                    <div className="flex gap-2 justify-end">
                      <Button color="danger" size="xs" disabled={loading} onClick={() => {
                        setSelectedStudent(null)
                      }}>
                        Cancel
                      </Button>
                      <Button type="submit" color="accent" size="xs" loading={loading}>
                        Save
                      </Button>
                    </div>
                  </fetcher.Form>
                ) : (
                  <div onClick={() => {
                    setSelectedStudent(id)
                  }} className="text-blue-50 text-xs ml-2 underline cursor-pointer">
                    {language}
                    <FontAwesomeIcon icon={faEdit} size="lg" className="hover:cursor-pointer ml-1" title="Edit language" />
                  </div>
                )}

              </td>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">
                {instance && (
                  <a href={loaderData.instanceUrls[instance]} target="_blank" rel="noreferrer" className="text-blue-50 text-xs ml-2 underline cursor-pointer">
                    {instance === 'gold' ? <FontAwesomeIcon icon={faMedal} size="sm" className="text-yellow-60 mr-2" />
                      : instance === 'silver' ? <FontAwesomeIcon icon={faMedal} size="sm" className="text-gray-60 mr-2" />
                        : null}
                    {instance}
                    <FontAwesomeIcon icon={faExternalLink} size="sm" className="hover:cursor-pointer ml-2" title="Open" />
                  </a>
                )}
              </td>
              <td className="bg-white dark:bg-gray-90 px-4 py-2 border border-gray-30 dark:border-gray-90">
                {isActive ? <FontAwesomeIcon icon={faCheck} size="sm" className="text-success-60" />
                  : <FontAwesomeIcon icon={faTimes} size="sm" className="text-danger-60" />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center pt-2 pb-6">
        <Paginator formId="filter-form" totalPages={loaderData.totalPages} currentPage={loaderData.currentPage} />
      </div>
    </div >
  )
}

export const WorkbenchRosettaStoneManageUsersRoute = { loader, action, Element }
