import React from 'react'
import { Button, Icon, unstable_Popover as Popover, unstable_Tooltip as Tooltip, Typography } from '@design-system'

export enum NotificationTabs {
  ALL = 'all',
  ACADEMIC = 'academic',
  PRODUCT = 'product',
  SCHOOL = 'school',
}

export const NotificationTabsText: Record<NotificationTabs, string> = {
  [NotificationTabs.ALL]: 'All Notifications',
  [NotificationTabs.ACADEMIC]: 'Academic Notifications',
  [NotificationTabs.PRODUCT]: 'Product Notifications',
  [NotificationTabs.SCHOOL]: 'School Notifications',
} as const


type NotificationFilter = {
  onSelectTab: (tab: string) => void,
  onClickPreferencesIcon?: () => void,
  selectedTab: NotificationTabs,
  gridCols?: number,
}

export function NotificationsFilter({ gridCols = 1, onSelectTab, selectedTab, onClickPreferencesIcon }: NotificationFilter) {
  return (
    <header className={`grid grid-cols-1 lg:grid-cols-${gridCols} border-b-1 border-b-gray-30`}>
      <div className="flex justify-between w-full">
        <h3 className="font-bold border-b-2 pb-4 border-b-accent">{NotificationTabsText[selectedTab]}</h3>
        <div className="flex space-x-4">
          <Tooltip content="Go to notification preferences">
            <Button variant="ghost" onClick={onClickPreferencesIcon}>
              <Icon name="gear" />
            </Button>
          </Tooltip>
          <Popover>
            <Popover.Trigger asChild>
              <div>
                <Tooltip content="Filter notifications">
                  <Button variant="ghost">
                    <Icon name="more-vert" />
                  </Button>
                </Tooltip>
              </div>
            </Popover.Trigger>
            <Popover.Content asChild className="p-0">
              <ul className="rounded-lg grid items-center overflow-hidden bg-white">
                {Object.values(NotificationTabs).filter((tab) => tab !== selectedTab).map((value) => (
                  <li key={value} className="cursor-pointer flex gap-2 p-3 hover:danger-50" onClick={() => onSelectTab(value)}>
                    <NotificationFilterIcon filterType={value} />
                    <Typography variant="callout">
                      {NotificationTabsText[value]}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Popover.Content>
          </Popover>
        </div>
      </div>
    </header>
  )
}

type NotificationFilterIconProps = {
  filterType: NotificationTabs
}

function NotificationFilterIcon({ filterType }: NotificationFilterIconProps) {
  switch (filterType) {
    case NotificationTabs.ACADEMIC:
      return <Icon name="users" size="sm" />
    case NotificationTabs.PRODUCT:
      return <Icon name="flag-2" size="sm" />
    case NotificationTabs.SCHOOL:
      return <Icon name="book-open" size="sm" />
    case NotificationTabs.ALL:
      return <Icon name="bell" size="sm" />
    default:
      return null
  }
}

