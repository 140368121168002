import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { toast } from 'sonner'
import { useNavigate, useRouteError } from 'react-router'
import { Button } from '@designsystem'
import NothingHere from './nothing-here.svg'

type ErrorComponentProps = {
  type?: 'FORM_ERROR' | 'DEFAULT' | 'CONNECTION_TIME_OUT' | 'NO_PERMISSION' | 'PAGE_NOT_FOUND'
}

type RouteError = {
  response?: {
    status: number
    data?: {
      message?: string
    }
  }
}

export const ErrorComponent = ({ type = 'DEFAULT' }: ErrorComponentProps) => {
  const error = useRouteError() as RouteError
  const navigate = useNavigate()

  if (error) console.error(error)

  useEffect(() => {
    if (error?.response?.data?.message)
      toast.error(error.response.data.message)
  }, [error])

  if (error?.response?.status === 401) {
    type = 'NO_PERMISSION'
  }

  const { title, message, buttonText, action } = (() => {
    switch (type) {
      case 'FORM_ERROR':
        return {
          title: 'We couldn\'t save your form',
          message: 'There was a hiccup while saving your form. Please make sure all fields are filled out correctly and resubmit. If you keep seeing this message, it\'s best to reach out to support.',
          buttonText: 'Try Again',
          action: () => window.location.reload(),
        }
      case 'CONNECTION_TIME_OUT':
        return {
          title: 'Connection Timed Out',
          message: 'It seems the connection is a bit slow. We\'re still trying, but if you\'d like, you can check your own connection or try again.',
          buttonText: 'Try Again',
          action: () => window.location.reload(),
        }
      case 'NO_PERMISSION':
        return {
          title: 'Looks like you don\'t have access to this',
          message: 'You\'ve stumbled upon an area that\'s off-limits with your current permissions. If you think you should have access, please contact your administrator or support.',
          buttonText: 'Back to home page',
          action: () => navigate('/'),
        }
      case 'PAGE_NOT_FOUND':
        return {
          title: 'Page not found',
          message: 'We\'ve looked everywhere but can\'t find that page. Maybe it\'s been moved or doesn\'t exist anymore.',
          buttonText: 'Back to home page',
          action: () => navigate('/'),
        }
      default:
        return {
          title: 'Something went wrong',
          message: 'It looks like we hit a snag and couldn\'t complete your request. Please try again in a bit, if the issue persists, reach out to our support team.',
          buttonText: 'Try Again',
          action: () => window.location.reload(),
        }
    }
  })()

  return (
    <div className="h-screen space-y-6 flex flex-col justify-center items-center my-8 w-full">
      <img className="ml-auto mr-auto h-52" loading="lazy" src={NothingHere} alt="Desert with cactus drawing" />
      <h1 className="font-bold text-lg text-center">{title}</h1>
      <h2 className="text-md text-center max-w-[500px]">{message}</h2>
      <Button onClick={action}>{buttonText}</Button>
    </div>
  )
}

type ErrorBoundaryProps = {
  children: React.ReactNode
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={() => (
        <ErrorComponent />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundary
