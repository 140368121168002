import React from 'react'
import { Button, Icon, unstable_Tooltip as Tooltip, useFetcher } from '@design-system'

interface StudentDetachProps {
  studentId: number
  actionName: 'detach_student' | 'detach_external_student'
}

export const StudentDetach = ({ studentId, actionName }: StudentDetachProps) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'
  return (
    <fetcher.Form method="POST" className='flex flex-row justify-end'>
      <input type="hidden" name="_action" value={actionName} />
      <input type="hidden" name="student_id" value={studentId} />
      <Tooltip content="Remove">
        <Button variant='ghost' size='sm' type="submit" loading={isLoading}>
          <Icon name="trash" size='sm' color='white' />
        </Button>
      </Tooltip>
    </fetcher.Form>
  )
}
