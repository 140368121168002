import React, { PropsWithChildren, useEffect } from 'react'
import { Button, Icon, IconProps, Avatar, DropdownMenu, useFetcher, Spinner, Switch, Typography } from '@design-system'
import SoraLink from '@components/link'
import useTheme from '@hooks/useTheme'

interface ProfileMenuProps extends PropsWithChildren {
  name: String
  imgUrl: string
}

export const Root = ({ name, imgUrl, children }: ProfileMenuProps) => (
  <DropdownMenu>
    <DropdownMenu.Trigger asChild>
      <Button data-testid="trigger" type="button" size="sm" variant="ghost" color="soft" className="p-0 !ring-0 gap-1">
        <Avatar size="lg">
          <Avatar.Image src={imgUrl} />
          <Avatar.Fallback>{name}</Avatar.Fallback>
        </Avatar>
        <Icon name="chevron-down" size="sm" />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content className="mr-3">
      {children}
    </DropdownMenu.Content>
  </DropdownMenu>
)

interface ProfileMenuUserProps {
  name: string
  email: string
  imgUrl: string
}

export const User = ({ name, email, imgUrl, }: ProfileMenuUserProps) => (
  <div className="p-4 flex gap-4 items-center">
    <Avatar size="2xl">
      <Avatar.Image src={imgUrl} />
      <Avatar.Fallback>{name}</Avatar.Fallback>
    </Avatar>
    <div>
      <Typography variant="subheadline" weight="bold">{name}</Typography>
      <Typography variant="footnote" className="text-gray-70 dark:text-gray-30">{email}</Typography>
    </div>
  </div>
)

interface ProfileMenuLinkProps {
  label: string
  target: string
  to: string
  icon: IconProps['name']
}

export const Link = ({ label, target, to, icon }: ProfileMenuLinkProps) => {
  return (
    <DropdownMenu.Item asChild>
      <SoraLink to={to} target={target}>
        <Icon name={icon} />
        {label}
      </SoraLink>
    </DropdownMenu.Item>
  )
}

export const DarkThemeToggle = () => {
  const [theme, toggleTheme] = useTheme()
  return (
    <DropdownMenu.Item onClick={toggleTheme} onSelect={(event: Event) => event.preventDefault()}>
      <Icon name="moon" />
      <span className="grow">Dark mode</span>
      <Switch checked={theme === 'dark'} />
    </DropdownMenu.Item>
  )
}

export const SupportButton = () => {
  const [isOpen, setIsOpen] = React.useState(false)

  function handleClick(e) {
    e.preventDefault()
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (window.Intercom) {
      if (isOpen) {
        window.Intercom('show')
      } else {
        window.Intercom('hide')
      }
    }
  }, [isOpen])

  return (
    <DropdownMenu.Item onClick={handleClick} onSelect={(event: Event) => event.preventDefault()}>
      <Icon name="comments-2" />
      <span className="grow">Support</span>
    </DropdownMenu.Item>
  )
}

export const Logout = ({ userId }: { userId: number }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle' && fetcher.formData?.get('_action') === 'signout'
  return (
    <DropdownMenu.Item disabled={isLoading} onSelect={(event: Event) => event.preventDefault()}>
      <fetcher.Form method="POST">
        <input type="hidden" name="_action" value="signout" />
        <input type="hidden" name="user_id" value={userId} />
        <button
          type="submit"
          data-cy="logout-btn"
          className="flex gap-4 items-center"
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : <Icon name="log-out" />}
          Logout
        </button>
      </fetcher.Form>
    </DropdownMenu.Item>
  )
}

export const ProfileMenu = Object.assign(Root, { User, Link, DarkThemeToggle, Logout, SupportButton })
