import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, useFetcher } from '@design-system'

export const TemplateRemove = ({ planId, templateId }) => {
  const fetcher = useFetcher()
  
  if (!templateId) return null

  const isLoading = fetcher.state === 'loading' && fetcher.formData?.get('planId') === String(planId)
  
  return (
    <fetcher.Form method="POST" className='flex flex-row'>
      <input type="hidden" name="_action" value='remove_plan_template' />
      <input type="hidden" name="planId" value={planId} />
      <Button type='submit' variant='ghost' size='xs' loading={isLoading}>
        <Icon name='trash' size='xs' />
      </Button>
    </fetcher.Form>
  )
}
TemplateRemove.propTypes = {
  planId: PropTypes.number,
  templateId: PropTypes.number,
}
