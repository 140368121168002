import React from 'react'
import PropTypes from 'prop-types'
import { useFetcher, Button, Icon } from '@design-system'

export const RemovePathway = ({ id }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' && fetcher.formData.get('id') === id

  return (
    <fetcher.Form method="POST">
      <input type='hidden' name='_action' value='remove_pathway' />
      <input type='hidden' name='id' value={id} />
      <Button variant='outlined' size='xs' loading={isLoading} disabled={isLoading}>
        <Icon name='trash' size='xs' />
      </Button>
    </fetcher.Form>
  )
}
RemovePathway.propTypes = {
  id: PropTypes.number.isRequired
}
