import * as React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData } from 'react-router'
import { Card, Icon, Tabs, Typography, unstable_Tooltip as Tooltip, Button } from '@design-system'

interface LoaderData {
  reviews: {
    total: number
    pendingExperienceLearningGoal: boolean
    pendingActivityIse: boolean
    pendingDualEnrollment: boolean
  }
  avgResponseTime: number
}

async function loader({ request }) {
  const url = new URL(request.url)
  const cycle_id = url.searchParams.get('cycle_id')
  const res = await axios.get(`/backoffice/facilitate?cycle_id=${cycle_id}`)
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData

  return (
    <>
      <header className="flex-col gap-4">
        <Typography variant="heading-3" weight="bold">Facilitate</Typography>
        <nav className="grid gap-4 grid-cols-1 md:grid-cols-2 my-8">
          <Card>
            <Card.Content>
              <div className="flex gap-3 items-center">
                <Icon name="pending-review" />
                <Typography variant="subheadline" weight="bold">Pending Review</Typography>
              </div>
              <Typography variant="heading-1" weight="bold">{loaderData.reviews.total ?? '-'}</Typography>
            </Card.Content>
          </Card>
          <Card >
            <Card.Content>
              <div className="flex items-center justify-between">
                <div className="flex gap-3 items-center">
                  <Icon name="clock-check" />
                  <Typography variant="subheadline" weight="bold">Average response time</Typography>
                </div>
                <Tooltip content={`The average response time is calculated based on the average time between the task due date\n and the assessment of learning goals and experiences, excluding weekends and holidays.\n This means if your number is negative, you're reviewing tasks before their date is due.`}>
                  <Button variant="ghost">
                    <Icon name="question" size="sm" />
                  </Button>
                </Tooltip>
              </div>
              <Typography variant="heading-1" weight="bold">{loaderData.avgResponseTime ? `${loaderData.avgResponseTime}hs` : '-'} {loaderData.avgResponseTime && loaderData.avgResponseTime <= 48 ? '🚀' : ''}</Typography>
            </Card.Content>
          </Card>
        </nav>
      </header>
      <Typography variant="heading-4" weight="bold" className="mb-4">Your experiences</Typography>
      <Tabs>
        <Tabs.Item title={`Experiences/Learning Goals ${loaderData.reviews.pendingExperienceLearningGoal ? ' •' : ''}`} to="expeditions-learning-goals" />
        <Tabs.Item title={`Activities and ISEs ${loaderData.reviews.pendingActivityIse ? ' •' : ''}`} to="activities-ises" />
        <Tabs.Item title={`Dual Enrollments ${loaderData.reviews.pendingDualEnrollment ? ' •' : ''}`} to="dual-enrollments" />
      </Tabs>
      <Outlet />
    </>
  )
}

export const FacilitateRoute = { Element, loader }
