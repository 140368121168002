import SlateTextarea, { BlockButton, LinkButton, MarkButton } from '@components/forms/slate-textarea'
import axios from 'axios'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ActionFunctionArgs, LoaderFunctionArgs, Outlet, useLoaderData, useLocation, useParams } from 'react-router'
import { LoaderResponse } from './types'
import SoraLink from '@components/link'
import { useFetcher, Avatar, Typography, Pill, Icon, Button, cn } from '@design-system'
import { MarkReviewedBanner } from './mark-reviewed-banner'
import { MessagesFeedRow } from './messages-feed-row'
import { CommentBankButton } from './comment-bank-button'
import { PillTask } from '@blocks/pill-task'
import { ArchiveFutureInstancesBanner } from './archive-future-instances-banner'

async function loader({ params }: LoaderFunctionArgs): Promise<LoaderResponse> {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}`,
    formData
  )

  return { status: res.status, ...res.data }
}

function Element() {
  const loaderData = useLoaderData() as LoaderResponse
  const params = useParams()
  const fetcher = useFetcher()
  const location = useLocation()

  const [isFlagEnabled, setFlagEnabled] = useState(false)

  const [slateCurrentValue, setSlateCurrentValue] = useState(null)
  const slateTextAreaRef = useRef<typeof SlateTextarea>()

  const lastElementOnPathname = location.pathname.split('/').slice(-1)[0]
  const isFeedback = lastElementOnPathname === 'feedback'
  const isAiTool = lastElementOnPathname === 'mid-cycle-report-ai'
  const isMetrics = lastElementOnPathname === 'metrics'

  useEffect(() => {
    // @ts-expect-error slate component is not typed so TS will throw an error
    if (slateTextAreaRef.current) slateTextAreaRef.current?.focus()
  }, [])

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'submit_comment') {
      setFlagEnabled(false)
      // @ts-expect-error slate component is not typed so TS will throw an error
      if (slateTextAreaRef.current) slateTextAreaRef.current?.clear()
    }
  }, [fetcher.data])

  return (
    <>
      <div className="flex flex-1 flex-col min-w-100 bg-white  shadow-[rgba(0,0,15,0.05)_-10px_0px_10px_0px]">
        <header className="flex px-6 h-[72px] items-center  border-y border-neutral-200 ">
          <Avatar size="xl">
            <Avatar.Image src={loaderData.imageUrl} alt={loaderData.studentName} />
            <Avatar.Fallback>
              {loaderData.studentName.charAt(0)}
            </Avatar.Fallback>
          </Avatar>
          <div className="flex flex-col pl-2 ">
            <SoraLink to={`/employee/students/${params.studentId}/overview`} target="_blank">
              <Typography variant="subheadline" weight="bold">
                {loaderData.studentName}
              </Typography>
            </SoraLink>
            <Typography variant="callout">{loaderData.grade} </Typography>
          </div>

          <div className="flex grow  justify-end gap-2 items-center">
            {loaderData.hasActiveExtensionRequest && <PillTask variant="extended-deadline" />}
            {loaderData.isFlagged && <PillTask variant="flagged" />}
            <PillTask variant={loaderData.taskStatus} />

            {loaderData.showExtendDeadlineButton && (
              <Button name="outline" variant="outlined" color="soft" asChild>
                <SoraLink to="deadline-extension">Deadline extension</SoraLink>
              </Button>
            )}

            <Button name="outline" variant={isMetrics ? 'contained' : 'outlined'} color="soft" asChild>
              <SoraLink to={isMetrics ? './' : 'metrics'}>
                <Icon name={isMetrics ? 'note-text-filled' : 'note-text'} />
              </SoraLink>
            </Button>
          </div>
        </header>

        {/* we could also do flex flex-col flex 1  */}
        <ul className="flex-1 flex flex-col-reverse gap-4 overflow-auto p-6">
          {loaderData.shouldProposeReview && (
            <MarkReviewedBanner startHidden={false} shouldProposeReview={loaderData.shouldProposeReview} />
          )}

          {loaderData.shouldProposeArchivingFutureExperienceInstances && (
            <ArchiveFutureInstancesBanner startHidden={false} shouldProposeArchivingFutureExperienceInstances={loaderData.shouldProposeArchivingFutureExperienceInstances} futureInstances={loaderData.futureInstances} />
          )}

          {loaderData.feedData.map((feedMessage) => (
            <Fragment key={`${feedMessage.key}`}>
              <MessagesFeedRow feedMessage={feedMessage} masteryLevels={loaderData.masteryLevels} />
            </Fragment>
          ))}
        </ul>

        <fetcher.Form className="px-6 pb-6" method="POST">
          <input type="hidden" name="studentId" value={params.studentId} />
          <input type="hidden" name="taskId" value={params.taskId} />
          <input type="hidden" name="isFlagged" value={isFlagEnabled ? 'true' : ''} />

          <SlateTextarea
            // @ts-expect-error slate component is not typed so TS will throw an error
            readOnly={!loaderData.canAssessTask}
            className="rounded-b-none border-b-transparent max-h-[50svh]"
            name="body"
            ref={slateTextAreaRef}
            placeholderText={`Provide feedback to ${loaderData.studentFirstName}'s assignment`}
            onChange={(value) => {
              setSlateCurrentValue(value)
            }}
            customTopBar={
              <>
                <div className={cn('flex items-center border-b-[1px] border-neutral-300 sticky top-0 bg-white z-5 h-9 py-1', !loaderData.canAssessTask ? 'cursor-not-allowed bg-gray-10' : '')}>
                  <MarkButton
                    disabled={!loaderData.canAssessTask}
                    format="bold"
                    icon={<Icon className="leading-['inherit']" name="text-bold" size="md" />}
                  />
                  <MarkButton
                    disabled={!loaderData.canAssessTask}
                    format="italic"
                    icon={<Icon className="leading-['inherit']" name="text-italic" size="md" />}
                  />
                  <BlockButton
                    disabled={!loaderData.canAssessTask}
                    format="numbered-list"
                    icon={<Icon className="leading-['inherit']" name="list-ordered" size="md" />}
                  />
                  <BlockButton
                    disabled={!loaderData.canAssessTask}
                    format="bulleted-list"
                    icon={<Icon className="leading-['inherit']" name="list-unordered" size="md" />}
                  />
                  <LinkButton disabled={!loaderData.canAssessTask} />
                  <MarkButton
                    disabled={!loaderData.canAssessTask}
                    format="strikethrough"
                    icon={<Icon className="leading-['inherit']" name="text-strikethrough" size="md" />}
                  />
                  <MarkButton
                    disabled={!loaderData.canAssessTask}
                    format="underline"
                    icon={<Icon className="leading-['inherit']" name="text-underline" size="md" />}
                  />
                </div>

                {loaderData.showFlaggedButton && (
                  <div className={isFlagEnabled ? 'px-3 pt-3' : 'hidden'}>
                    <Pill>
                      <Pill.Icon name="flag-filled" />
                      <Pill.Value>Flagged</Pill.Value>
                      <button type="button" className="cursor-pointer" onClick={() => setFlagEnabled(false)}>
                        <Pill.Icon name="cross" />
                      </button>
                    </Pill>
                  </div>
                )}
              </>
            }
            customBottomBar={
              <div className={cn('flex items-center rounded-b-lg border-x-[1px] border-b-[1px] border-neutral-300 bg-white px-2', !loaderData.canAssessTask ? 'cursor-not-allowed bg-gray-10' : '')}>
                <CommentBankButton
                  presetComments={[...loaderData.presetComments.experience, ...loaderData.presetComments.general]}
                  sideOffset={160}
                  alignOffset={-9}
                  linkTo="./comment-bank"
                  disabled={!loaderData.canAssessTask}
                  onSelectComment={(commentBody) => {
                    // @ts-expect-error slate component is not typed so TS will throw an error
                    if (slateTextAreaRef.current) slateTextAreaRef.current?.appendValue(commentBody)
                  }}
                  emptyLinkTo={'./comment-bank/preset-comment/create'}
                />

                <Button
                  asChild
                  variant={isFeedback ? 'contained' : 'ghost'}
                  color={isFeedback ? 'soft' : 'dark'}
                  size="sm"
                  disabled={!loaderData.shouldEnableFeedback || !loaderData.canAssessTask}
                >
                  <SoraLink to={isFeedback ? './' : 'feedback'} as={!loaderData.shouldEnableFeedback || !loaderData.canAssessTask ? 'button' : null}>
                    <Icon name={isFeedback ? 'plus-circle-filled' : 'plus-circle'} size="sm" />
                    Add feedback
                  </SoraLink>
                </Button>
                {loaderData.showFlaggedButton && (
                  <Button
                    type="button"
                    variant={isFlagEnabled ? 'contained' : 'ghost'}
                    color={isFlagEnabled ? 'soft' : 'dark'}
                    size="sm"
                    onClick={() => setFlagEnabled(!isFlagEnabled)}
                    disabled={!loaderData.isFlaggable || !loaderData.canAssessTask}
                  >
                    <Icon name={isFlagEnabled ? 'flag-filled' : 'flag'} size="sm" /> Flag Task
                  </Button>
                )}

                <div className="flex grow items-end justify-end pb-2 gap-2">
                  {loaderData.showAiReportButton && (
                    <Button
                      asChild
                      variant={isAiTool ? 'contained' : 'ghost'}
                      color={isAiTool ? 'soft' : 'dark'}
                      size="sm"
                      disabled={!loaderData.canAssessTask}
                    >
                      <SoraLink
                        to={isAiTool ? './' : 'mid-cycle-report-ai'}
                        as={!loaderData.showAiReportButton || !loaderData.canAssessTask ? 'button' : null}
                      >
                        <Icon name={isAiTool ? 'sparkles-filled' : 'sparkles'} size="sm" className={!loaderData.canAssessTask ? '' : 'text-blue-40'} />
                      </SoraLink>
                    </Button>
                  )}
                  <Button
                    size="sm"
                    type="submit"
                    name="_action"
                    value="submit_comment"
                    disabled={
                      fetcher.state !== 'idle' ||
                      !slateCurrentValue ||
                      !loaderData.canAssessTask ||
                      // @ts-expect-error slate component is not typed so TS will throw an error
                      slateTextAreaRef.current?.isEmpty(slateCurrentValue)
                    }
                  >
                    <Icon name="send" size="sm" />
                  </Button>
                </div>
              </div>
            }
          />
        </fetcher.Form>
      </div>

      <Outlet
        context={[
          (commentBody) => {
            // @ts-expect-error slate component is not typed so TS will throw an error
            if (slateTextAreaRef.current) slateTextAreaRef.current?.appendValue(commentBody)
          },
        ]}
      />
    </>
  )
}

export const FacilitateExperienceTaskStudentRoute = {
  loader,
  action,
  Element,
}
