import React, { useState } from 'react'
import axios from 'axios'
import debounce from 'lodash/debounce'
import matchMultipleProps from '@utils/filter/matchMultipleProps'
import Select from '@components/forms/select'
import { useLoaderData, useNavigation, Form, useActionData, redirect } from 'react-router'
import { Button } from '@design-system'
import { useToast } from '@hooks/useToast'

export default function ImpersonateStudent() {
  const navigation = useNavigation()
  const students = useLoaderData()
  const loading = navigation.state === 'submitting'
  const actionData = useActionData()
  const [buttonEnabled, setButtonEnabled] = useState(false)
  useToast(actionData)

  const searchFunction = debounce((query, callback) => {
    const searchedAbilities = students.filter(
      matchMultipleProps(query, ['label'])
    )

    callback(searchedAbilities)
  }, 500)

  return (
    <div className='w-[600px]'>
      <h1 className='text-2xl font-bold mb-4'>Mirror student view</h1>
      <p className='className="font-bold"'>Select a student</p>
      <Form method="POST">
        <Select
          className='mt-2'
          name='targetUserId'
          searchFunction={searchFunction}
          options={students}
          onChange={() => setButtonEnabled(true)}
          placeholder='Search by the student ID, name or email' />
        <div className='mt-4'>
          <Button
            type='submit'
            disabled={!buttonEnabled}
            loading={loading}
          >Impersonate</Button>
        </div>
      </Form>
    </div>
  )
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  const targetUserId = formData.get('targetUserId')

  return redirect(`/app-sso?impersonateUserId=${targetUserId}`)
}

export const loader = async () => {
  const { data } = await axios.get('/impersonate/students')
  return data
}
