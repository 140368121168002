import React from 'react'
import axios from 'axios'
import serializeFormData from '@utils/serializeFormData'
import { useLoaderData, useSearchParams } from 'react-router'
import { SchoolYearSelector } from './school-year-selector'
import { PathwaySelector } from './pathway-selector'
import { ColumnFilter } from './column-filter'

const loader = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'
  const schoolYear = searchParams.get('schoolYear')
  const houseId = searchParams.get('houseId')

  const result = await axios.get(`/backoffice/workbench/pathways/students`, { params: { action, schoolYear, houseId } })
  return result?.data
}

const action = async ({ params, request }) => {
  const formData = await request.formData()
  const action = formData.get('_action')
  const serializedFormData = serializeFormData(formData)

  try {
    const result = await axios.post(`/backoffice/workbench/pathways/students`, { ...serializedFormData, action })
    return  result?.data
  } catch (error) {
    return {
      toast: { message: 'There was an error running this action. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }
}

const Element = () => {
  const [searchParams] = useSearchParams()
  const { schoolYears, houses, students, humanitiesPathways, stemPathways } = useLoaderData()

  const selectedSchoolYear = Number(searchParams.get('schoolYear')) || schoolYears.slice(-1)[0].value

  return (
    <div className='flex flex-row justify-around relative'>
      <div className='flex flex-col p-2 gap-2'>
        <SchoolYearSelector schoolYears={schoolYears} defaultValue={selectedSchoolYear} />
        <table>
          <thead className='sticky top-0 bg-screen-primary z-20'>
            <tr className='text-left'>
              <th className='p-1'>ID</th>
              <th className='p-1'>Student</th>
              <th className='p-1'>
                <ColumnFilter field='houseId' title='House' options={houses}/>
              </th>
              <th className='p-1 w-[250px]'>Humanities Pathways</th>
              <th className='p-1 w-[250px]'>Stem Pathways</th>
            </tr>
            <tr>
              <th colSpan={5} className='bg-alpha/10 h-px'></th>
            </tr>
          </thead>
          <tbody>
            {students?.map(student => (
              <tr key={`${selectedSchoolYear}_${student.id}`}>
                <td className='p-1'>{student.id}</td>
                <td className='p-1'>{student.name} {student.preferred_name ? `(${student.preferred_name})` : ''}</td>
                <td className='p-1'>{student.house_title}</td>
                <td className='p-1'>
                  <PathwaySelector pathways={humanitiesPathways} defaultValue={student.humanities_pathway_id} schoolYear={selectedSchoolYear} studentId={student.id} assignId={student.humanities_assign_id} />
                </td>
                <td className='p-1'>
                  <PathwaySelector pathways={stemPathways} defaultValue={student.stem_pathway_id} schoolYear={selectedSchoolYear} studentId={student.id} assignId={student.stem_assign_id} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const WorkbenchPathwaysStudentsRoute  = {
  loader,
  action,
  Element,
}
