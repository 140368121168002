/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAngleRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router'
import { RadioGroup } from '@headlessui/react'
// hooks
import { filterUnit } from './hooks/useGradProgress'
import useQuery from '@hooks/useQuery'
// components
import UnitCard from './unit-card'
import UnitSlideover from './unit-slideover'
import AbilitySlideover from './ability-slideover'
import SubjectDisclosure from './subject-disclosure'
import FFRubricModal from '@components/ff-rubric-modal'
// content
import useCurrentUser from '@hooks/useCurrentUser'
import MasteryBar from '@components/mastery-bar'
import { Button, TextField } from '@designsystem'

export default function BottomSection({ data, mutate }) {
  const UNITS_TAB = 'units'
  const ABILITIES_TAB = 'abilities'
  const [queryParams, setParams] = useQuery()
  const { tab = UNITS_TAB } = queryParams
  const { student_id } = useParams()
  const [selectedTopic, setSelectedTopic] = useState()
  const [selectedAbilityCategory, setSelectedAbilityCategory] = useState()
  const currentUser = useCurrentUser()
  const [unitSliderOpen, setUnitSliderOpen] = useState(false)
  const [abilitySliderOpen, setAbilitySliderOpen] = useState(false)
  const [rubricModalOpen, setRubricModalOpen] = useState(false)
  const [activeUnit, setActiveUnit] = useState()
  const [activeAbility, setActiveAbility] = useState()
  const [filter, setFilter] = useState('on_program')

  const subjects = data?.subjects || []
  const categoriesWithAbilities = data?.categoriesWithAbilities || []

  const isEditable = currentUser?.role !== 'student' && currentUser?.role !== 'guardian'

  const showKnowledgeHubBanner = currentUser?.role === 'student' || currentUser?.role === 'guardian'
  const knowledgeHubLink = 'https://support.soraschools.com/en/articles/9503398-graduation-requirements-walkthrough'

  const shouldShowProgressBar = data?.isMsMasteryLevelFeatureFlagEnabled ? data?.schoolStage !== 'ms' : true

  const handleFilter = (filter) => {
    setParams({ ...queryParams, tab: filter })
  }

  const unitCount = subjects.flatMap(sub => sub.topics.flatMap(top => top.units.filter(unit => filterUnit(filter, unit)))).length

  const toggleActiveUnit = (unit) => {
    setActiveUnit(unit)
    setUnitSliderOpen(true)
  }

  const toggleActiveAbility = (ability) => {
    setActiveAbility(ability)
    setAbilitySliderOpen(true)
  }

  const searchUnits = (value) => {
    setFilter(`search$${value}`)
  }

  const scrollToTopic = (topic_id) => {
    let element = document.getElementById(`topic-${topic_id}`)
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  const scrollToAbilityCategory = (category) => {
    let element = document.getElementById(`category-${category}`)
    element && element.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className="pb-12">
      {activeAbility && (
        <FFRubricModal
          type="ABILITY"
          open={rubricModalOpen}
          setOpen={() => setRubricModalOpen(false)}
          id={activeAbility.id}
        />
      )}
      {activeUnit && (
        <UnitSlideover
          open={unitSliderOpen}
          setOpen={setUnitSliderOpen}
          unit_id={activeUnit.id}
          student_id={student_id}
          unit_title={activeUnit.title}
        />
      )}
      {activeAbility && <AbilitySlideover
        open={abilitySliderOpen}
        setOpen={setAbilitySliderOpen}
        ability={activeAbility}
      />}
      <div className="sticky z-40 pt-2 top-[8.5rem] bg-gray-2 dark:bg-gray-100 mb-8 group-[.flutter]/flutter:top-0">
        <header className="flex flex-col md:flex-row items-end justify-between gap-4 border-b-2 border-b-gray-30 dark:border-gray-90 min-h-[52px]">
          <RadioGroup
            value={tab}
            onChange={handleFilter}
            className="flex lg:w-sm w-full"
          >
            <RadioGroup.Option value={UNITS_TAB} className='grow'>
              {({ checked }) => (
                <button
                  className={`mb-[-.125rem] w-full h-full py-1.5 text-xs font-bold uppercase ${checked
                    ? 'border-b-2 border-accent text-accent'
                    : ''
                    }`}
                >
                  Units
                </button>
              )}
            </RadioGroup.Option>
            <RadioGroup.Option value={ABILITIES_TAB} className='grow min-h-[52px]'>
              {({ checked }) => (
                <button
                  className={`mb-[-.125rem] w-full h-full py-1.5 text-xs font-bold uppercase ${checked
                    ? 'border-b-2 border-accent text-accent'
                    : ''
                    }`}
                >
                  Abilities
                </button>
              )}
            </RadioGroup.Option>
          </RadioGroup>
          {tab === UNITS_TAB && <div className="self-end pb-2 flex flex-row gap-2 items-center">
            <span className="hidden md:block mr-2">{unitCount} units found</span>
            <span className='hidden md:block group-[.flutter]/flutter:hidden'>
              <Button
                variant="outlined"
                onClick={() => {
                  const container =
                    document.getElementById('unit-container-top')

                  container && container.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }}
              >
                back to top
              </Button>
            </span>
            <TextField
              type="text"
              name="search"
              placeholder="Search units"
              onChange={(e) => searchUnits(e.target.value)}
            />
            <TextField
              as="select"
              defaultValue='on_program'
              onChange={(e) => {
                setFilter(e.target.value)
              }}
            >
              <option value="all">Filter units</option>
              <option value="on_program">My Program</option>
              <option value="exempt">Exempted</option>
              <option value="not_started">Not started</option>
              <option value="attempted">Attempted</option>
              <option value="exposure">Exposure</option>
              <option value="developing">Developing</option>
              <option value="demonstrating">Demonstrating</option>
              <option value="extending">Extending</option>
              <option value="not_completed">Not Completed</option>
              <option value="completed">Completed</option>
              <option value="off_program">Off-program</option>
            </TextField>
          </div>}
        </header>
      </div>
      {tab === ABILITIES_TAB && (
        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="w-full lg:w-auto flex-auto lg:max-w-[30%] lg:overflow-y-scroll lg:sticky top-28 space-y-2">
            {
              categoriesWithAbilities.map((category) => {
                return (
                  <div
                    className="p-6 bg-white hover:bg-gray-5 dark:bg-gray-95 border border-gray-30 dark:border-gray-90 dark:hover:bg-gray-90 rounded cursor-pointer"
                    key={`abilities_tab_${category.title}`}
                    onClick={() => {
                      setSelectedAbilityCategory(category.title)
                      scrollToAbilityCategory(category.title)
                    }}
                  >
                    <span
                      className={`text-lg ml-4 ${selectedAbilityCategory === category.title &&
                        'font-bold border-l-4 pl-2'
                        }`}
                    >
                      {category.title}
                    </span>
                  </div>
                )
              })}
          </div>
          <div className="flex-1 space-y-2 lg:overflow-y-scroll">
            {categoriesWithAbilities.map((category) => {
              let abilities = category.abilities
              return (
                <>
                  <h5
                    key={`category_${category.title}`}
                    className="my-4"
                    id={`category-${category.title}`}
                  >
                    {category.title}
                  </h5>
                  {abilities.map((ability) => {
                    return (
                      <div
                        key={`ability_${ability.id}`}
                        className="w-full bg-white border border-gray-30 dark:border-gray-90 dark:bg-gray-95 p-6 flex justify-between items-center space-x-3"
                        onClick={() => setActiveAbility(ability)}
                      >
                        <div className="flex-1 flex gap-4 lg:gap-0 flex-col lg:flex-row lg:items-center justify-between">
                          <div className="flex-1">
                            <span className="text-lg">
                              {ability.title}
                            </span>
                            <span className="text-sm ml-2">
                              {ability.completions} of {ability.target} opportunities completed <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="cursor-pointer"
                                onClick={() => {
                                  setRubricModalOpen(true)
                                }}
                              />
                            </span>

                          </div>
                          <div className="items-center flex flex-col">
                            <span className="uppercase text-xs tracking-wide font-bold mr-2 whitespace-nowrap">Average mastery: {data?.isMsMasteryLevelFeatureFlagEnabled ? _getGradeName(ability.average, data?.schoolStage) : ability.average.toFixed(1)}</span>
                            {shouldShowProgressBar && <MasteryBar level={ability.average} />}
                          </div>
                        </div>
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          onClick={toggleActiveAbility}
                        />
                      </div>
                    )
                  })}
                </>
              )
            })}
          </div>
        </div>
      )}
      {tab === UNITS_TAB && (
        <div className="w-full flex flex-col lg:flex-row gap-8 items-start">
          <div className="flex-auto lg:max-w-[30%] lg:overflow-y-scroll lg:sticky top-20 space-y-2 lg:max-h-[calc(100vh-150px)] pb-40">
            {subjects.map(subject => {
              return (
                <SubjectDisclosure
                  key={`units_tab_${subject.id}`}
                  selectedTopic={selectedTopic}
                  setSelectedTopic={setSelectedTopic}
                  subject={subject}
                  scrollToTopic={scrollToTopic}
                  studentId={student_id}
                  isEditable={isEditable}
                  mutate={mutate}
                />
              )
            })}
          </div>
          <div className="flex-1 space-y-3">
            {
              showKnowledgeHubBanner &&
              <div className="flex items-center gap-3 bg-blue-5 py-4 pl-3 rounded-lg">
                <FontAwesomeIcon icon={faInfoCircle} size="lg" />
                <p className="text-sm">
                  Learn more about Sora's graduation requirements in our
                  {' '}<a href={knowledgeHubLink} target="_blank" className="text-blue-30 hover:text-blue-40" rel="noreferrer">Knowledge Hub guide.</a>
                </p>
              </div>
            }
            <span id="unit-container-top"></span>
            {unitCount === 0 && (
              <div className="w-full p-6">
                <h4 className="text-center font-bold text-xl">
                  No units found!
                </h4>
                <p className="text-center">
                  No units meet this search criteria, please try again.
                </p>
              </div>
            )}
            {subjects.map(subject =>
              subject.topics.map(topic =>
                topic.units.filter(unit => filterUnit(filter, unit)).length > 0 && (
                  <React.Fragment key={`topic_${topic.id}`}>
                    <h5 id={`topic-${topic.id}`}>
                      {subject.title} / {topic.title}
                    </h5>
                    <div className="space-y-2">
                      {topic.units.filter(unit => filterUnit(filter, unit)).map(unit => (
                        <UnitCard
                          key={`unit_${unit.id}`}
                          unit={unit}
                          subject_id={subject.id}
                          toggleActiveUnit={() => toggleActiveUnit(unit)}
                          schoolStage={data.schoolStage}
                          isMsMasteryLevelFeatureFlagEnabled={data.isMsMasteryLevelFeatureFlagEnabled}
                        />
                      ))}
                    </div>
                  </React.Fragment>
                )
              )
            )}
          </div>
        </div>
      )}
    </div>
  )
}

const _getGradeName = (level = 0, schoolStage) => {
  if (schoolStage === 'ms') {
    if (level === 0) {
      return 'Not Started'
    } else if (level < 1) {
      return 'Attempted'
    } else if (level < 2) {
      return 'Exposure'
    } else if (level < 3) {
      return 'Developing'
    } else if (level < 4) {
      return 'Demonstrating'
    } else {
      return 'Extending'
    }
  }

  return level.toFixed(1)
}
