import * as React from 'react'
import { Tabs } from '@design-system'
import { Outlet, useNavigate } from 'react-router'

function Element() {
  const navigate = useNavigate()
  const tabs = [
    { title: 'Update credit', link: './update-credit', },
    { title: 'Manage users', link: './manage-users', },
  ]
  React.useEffect(() => {
    if (location.pathname.endsWith('rosetta-stone')) {
      return navigate('./update-credit')
    }
  }, [])
  return (
    <>
      <nav>
        <Tabs>
          {tabs.map((tab) => (
            <Tabs.Item key={tab.title} title={tab.title} to={tab.link} />
          ))}
        </Tabs>
      </nav>
      <Outlet />
    </>
  )
}

export const WorkbenchRosettaStoneRoute = { Element }
