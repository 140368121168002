import React, { Fragment, PropsWithChildren, useState } from 'react'
import { Transition } from "@headlessui/react"
import formatDistance from 'date-fns/formatDistance'
import isSoraHomeLink from '@utils/isSoraHomeLink'
import NoNewNotificationsImage from './no_new_notifications.png'
import { Button, EmptyState, Icon, Typography, unstable_Popover as Popover } from '@design-system'

export type NotificationType = 'school' | 'academic' | 'product'

export interface INotification {
  id: number,
  body: string,
  read: boolean,
  createdAt: Date,
  ctaText?: null | string,
  iconAlt?: null | string,
  iconUrl?: null | string,
  imageAlt?: null | string,
  imageUrl?: null | string,
  redirectUrl?: string,
  title: string,
  type: NotificationType,
  category: string,
  content?: Array<unknown>
}
export type PreferenceItem = {
  title: string,
  email: boolean,
  mobile: boolean,
  web: boolean,
}

export type NotificationPreferences = Record<NotificationType, Record<string, PreferenceItem>>

type ListProps = PropsWithChildren<{
  hasNotifications: boolean,
}>

function List({ hasNotifications, children }: ListProps) {
  return hasNotifications ? (
    <ol className="flex flex-col">
      {children}
    </ol>
  ) : (
    <div className="flex flex-col space-y-2 justify-center items-center h-full">
      <EmptyState>
        <EmptyState.Illustration>
          <img src={NoNewNotificationsImage} alt="cactus in the desert illustration" title="No new notifications" />
        </EmptyState.Illustration>
        <EmptyState.Title>No new notifications</EmptyState.Title>
        <EmptyState.Description>You don't have any new notifications. Check back later!</EmptyState.Description>
      </EmptyState>
    </div>
  )
}

interface ItemProps extends INotification {
  onClick: () => any
  onDeleteNotification: () => void,
  isSelected?: boolean,
}

function Item({
  id,
  title,
  type,
  read,
  body,
  category,
  imageUrl,
  imageAlt,
  ctaText,
  createdAt,
  iconAlt,
  iconUrl,
  onClick,
  onDeleteNotification,
  isSelected,
  redirectUrl,
}: PropsWithChildren<ItemProps>) {
  const [show, setShow] = useState(true)

  return (
    <Transition
      appear
      show={show}
      afterLeave={onDeleteNotification}
      as={Fragment}
    >
      <Transition.Child
        className={`flex space-x-4 w-full relative py-5 pl-2 ${isSelected && 'bg-blue-2'}`}
        onClick={onClick}
        data-testid={`notification-${id}`}
        as="li"
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-400"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex h-min items-start space-x-2">
          {!read ? <i className="w-2 h-2 flex self-center rounded-full bg-blue-40" /> : null}
          <ItemIcon type={type} category={category} iconAlt={iconAlt} iconUrl={iconUrl} />
        </div>
        <div className={`flex flex-col w-full place-items-start ${redirectUrl ? 'cursor-pointer' : ''}`}>
          <div className="flex justify-between w-full items-center">
            <Typography weight="bold">{title}</Typography>
            <ItemPopover onDeleteNotification={() => setShow(false)} />
          </div>
          {imageUrl && <img src={imageUrl} alt={imageAlt} className="h-20 my-4 rounded-lg w-11/12 object-cover" />}
          <Typography className="mr-3 line-clamp-3 overflow-ellipsis">
            {body}
          </Typography>
          <Typography variant="footnote" className="mt-4">{formatDistance(new Date(createdAt), new Date(), { addSuffix: true })}</Typography>
          {ctaText && (
            <Button size="sm" variant="outlined" className="mt-4 self-start">
              {ctaText}
              {redirectUrl && !isSoraHomeLink(redirectUrl) ? <Icon size="xs" name="external-link" /> : null}
            </Button>
          )}
        </div>
      </Transition.Child>
    </Transition >
  )
}

type ItemIconProps = Partial<Pick<ItemProps, 'iconAlt' | 'iconUrl' | 'type' | 'category'>>

export function ItemIcon({ type, category, iconAlt, iconUrl }: ItemIconProps) {
  if (category === 'house_reports') {
    return (
      <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-green-5" title="Academic Notification">
        <span className="text-2xl mt-0.5">🍀</span>
      </span>
    )
  }
  switch (type) {
    case 'academic':
      return iconUrl
        ? <img src={iconUrl} alt={iconAlt} className="w-10 h-10 rounded-full" title="Academic Notification" />
        : (
          <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2 dark:bg-blue-30" title="Academic Notification">
            <Icon name="users" />
          </span>
        )
    case 'product':
      return (
        <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2 dark:bg-blue-30" title="Product Notification">
          <Icon name="flag" />
        </span>
      )
    case 'school':
      return (
        <span className="flex items-center justify-center min-w-10 w-10 h-10 rounded-full bg-blue-2 dark:bg-blue-30" title="School Notification">
          <Icon name="book-open" />
        </span>
      )
    default:
      return null
  }
}

type ItemPopoverProps = Pick<ItemProps, 'onDeleteNotification'>

function ItemPopover({ onDeleteNotification }: ItemPopoverProps) {
  // component must be controlled, becase we need to e.stopPropagation() on the button
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Popover open={isOpen}>
      <Popover.Trigger  asChild>
        <Button variant="ghost" aria-label="Notification Options" onClick={(e) => {
          e.stopPropagation()
          setIsOpen((prev) => !prev)
        }}>
          <Icon name="more-horiz" />
        </Button>
      </Popover.Trigger>
      <Popover.Content className="p-0">
        <ul>
          <li
            className="flex space-x-3 items-center p-4 w-40 cursor-pointer"
            onClick={onDeleteNotification}
          >
            <Icon size="sm" name="trash" />
            <Typography>
              Delete
            </Typography>
          </li>
        </ul>
      </Popover.Content>
    </Popover>
  )
}

export const Notification = {
  List,
  Item,
}


