import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Form, Link, redirect, useActionData } from 'react-router'
import SoraLink from '@components/link'
import { TextField } from '@designsystem'
import { Logo, Button } from '@design-system'
import serializeFormData from '@utils/serializeFormData'
import { useToast } from '@hooks/useToast'

async function loader() {
  const result = await axios.get('/pages/password/new')
  const shouldRedirect = result.data?.shouldRedirect

  if (shouldRedirect) {
    return redirect('/')
  }

  return null
}

async function action({ request }) {
  try {
    const searchParams = new URL(request.url).searchParams
    const formData = serializeFormData(await request.formData())
    const parsedParams = Object.fromEntries(searchParams.entries())
    const result = await axios.post('/pages/password/new', { ...formData, ...parsedParams })
    return {
      toast: {
        message: `Your password has been reset. You should be redirected to ${result.data}`,
        appearance: 'success',
      },
      redirect: result.data
    }
  } catch (error) {
    console.error(error)
    return {
      toast: {
        message: 'Something went wrong while reseting your password. Contact support.',
        appearance: 'error',
      }
    }
  }
}

interface ActionData {
  toast: {
    message: string,
    appearance: 'success' | 'error',
  },
  redirect?: string,
}

function Element() {
  const actionData = useActionData() as ActionData
  const [passwords, setPasswords] = useState({
    password: '',
    password2: '',
  })

  const isPassword2Dirty = useRef(false)

  useToast(actionData)
  useEffect(() => {
    if (actionData?.redirect) {
      setTimeout(() => {
        window.location.href = actionData.redirect
      }, 1500)
    }
  }, [actionData?.redirect])

  const arePasswordsEqual = isPassword2Dirty.current && passwords.password === passwords.password2

  return (
    <div className="h-full flex items-center justify-center">
      <div className="max-w-md w-full sm:border border-gray-30 dark:border-gray-85 md:rounded-xl flex flex-col items-center gap-8 p-8 bg-white dark:bg-gray-100 sm:dark:bg-gray-95">
        <a href="https://soraschools.com/" target="_blank" rel="noreferrer">
          <Logo size="lg" data-cy="login-page-sora-logo" />
        </a>
        <p className="text-center">Choose a new password</p>
        <Form className="w-full flex flex-col space-y-3" method="POST">
          <TextField
            required
            autoFocus
            type="password"
            name="password"
            placeholder="New password"
            onChange={(e) => setPasswords((prevState) => ({ ...prevState, password: e.target.value }))}
          />
          <TextField
            required
            type="password"
            name="password2"
            placeholder="Repeat new password"
            error={isPassword2Dirty.current && !arePasswordsEqual}
            helperText={isPassword2Dirty.current && !arePasswordsEqual && 'Passwords don\'t match'}
            onChange={(e) => {
              isPassword2Dirty.current = true
              setPasswords((prevState) => ({ ...prevState, password2: e.target.value }))
            }}
          />
          <span className="self-center">
            <Button type="submit" disabled={!arePasswordsEqual}>Create new password</Button>
          </span>
        </Form>
        <SoraLink as={Link} className="hover:underline font-bold" to="https://app.soraschools.com/">Back to guardian sign in</SoraLink>
      </div>
    </div>
  )
}

export const NewPasswordRoute = { loader, action, Element }
