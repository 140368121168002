import SoraLink from '@components/link'
import React from 'react'
import useSWR from 'swr'
import { Button } from '@designsystem'
import { faAdd } from '@fortawesome/free-solid-svg-icons'

const WorkbenchEmployees = () => {
  const { data } = useSWR('/pages/admin/workbench/employees')

  if (!data) return <span>Loading...</span>

  const { employees } = data
  if (!employees || employees.length === 0) return <span>No employees</span>

  return (
    <div className="bg-white p-3 space-y-2">
      <Button as={SoraLink} to="new" startIcon={faAdd}>
        Add an Employee
      </Button>
      <table className="w-full table-auto text-left border border-gray-10">
        <thead>
          <tr className="font-bold">
            <td className="border p-3 w-16">ID</td>
            <td className="border p-3 w-44">Name</td>
            <td className="border p-3 w-80">Email</td>
            <td className="border p-3 w-44">Job</td>
            <td className="border p-3 w-44">Perm. Group</td>
            <td className="border p-3 w-10">Test</td>
            <td className="border p-3 w-10">Active</td>
            <td className="border p-3 w-10">Admin</td>
            <td className="border p-3 w-10">Login disabled</td>
          </tr>
        </thead>
        <tbody>
          {employees.map(({ employee_id, name, email, job, permission_group, admin, is_active, is_test_user, is_login_disabled }, i) => (
            <tr key={i}>
              <td className="border p-3">{employee_id}</td>
              <td className="border p-3">
                <SoraLink
                  to={`${employee_id}`}
                  className="hover:underline text-blue-70"
                >
                  {name}
                </SoraLink>
              </td>
              <td className="border p-3">{email}</td>
              <td className="border p-3">{job}</td>
              <td className="border p-3 uppercase">{permission_group}</td>
              <td className="border p-3">{is_test_user ? 'yes' : 'no'}</td>
              <td className="border p-3">{is_active ? 'yes' : 'no'}</td>
              <td className="border p-3">{admin ? 'yes' : 'no'}</td>
              <td className="border p-3">{is_login_disabled ? 'yes' : 'no'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default WorkbenchEmployees
