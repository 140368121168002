import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useNavigate, useSearchParams, Link } from 'react-router'

export const NavigateCycle = ({ to, ...params }) => {
  const [searchParams] = useSearchParams()
  return <Navigate to={getNewPath(to, searchParams)} replace {...params} />
}
NavigateCycle.propTypes = {
  to: PropTypes.string,
}

export const LinkCycle = ({ to, children, ...params }) => {
  const [searchParams] = useSearchParams()
  return <Link to={getNewPath(to, searchParams)} {...params}>
    {children}
  </Link>
}
LinkCycle.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
}

export default function useNavigateWithCycle() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  return (path, ...params) => {
    const newPath = getNewPath(path, searchParams)
    return navigate(newPath, ...params)
  }
}

const getNewPath = (path, searchParams) => {
  const cycle_id = searchParams.get('cycle_id') || document.getElementsByName('cycle_id')?.[0]?.value
  if (!cycle_id || typeof path !== 'string') return path
  const [realPath, paramsString] = path.split('?')
  const newSearchParams = new URLSearchParams(paramsString)
  if (newSearchParams.get('cycle_id')) return path
  newSearchParams.set('cycle_id', cycle_id)
  const newPath = realPath + '?' + newSearchParams.toString()
  return newPath
}
