import React from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router'
import { isBlankValue, RenderedSlate } from '@components/forms/slate-textarea'
import { Button, Icon } from '@design-system'

export const loader = async ({ request }) => {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const result = await axios.get('/pages/admin/reports/materials', { params: searchParams })
  return result?.data
}

export default function MaterialsReportRoute() {
  const loaderData = useLoaderData() as { rows: Array<{ id: number, title: string, materials_and_tools: any, is_legacy: Boolean }> }

  return (
    <div className="grid grid-cols-3 my-20 bg-white">
      <h4 className="font-bold text-xl border-b border-gray-20 p-4">Experience</h4>
      <h4 className="font-bold col-span-2 text-xl border-b border-gray-20 p-4">Tools and Materials</h4>
      {loaderData.rows.map((row) => {
        return (
          <React.Fragment key={row.id}>
            <div className="border-b border-gray-20 p-4">{row.title}</div>
            <div className="col-span-2 border-b border-gray-20 p-4">
              {row.is_legacy &&
                <RenderedSlate value={row.materials_and_tools} />
              }
              {!row.is_legacy &&
              
              
                <div className='flex flex-col gap-2'>
                  {row.materials_and_tools.map((item, index) => {
                    return (
                      <div className='flex flex-col gap-2' key={index}>
                        <div className='flex flex-row gap-2 items-center'>
                          <div className='flex flex-col gap-1'>
                            <span className='text-xs text-alpha/60'>{item?.type || ''}</span>
                            <span className='text-lg font-bold'>{item?.title || 'Untitled'}</span>
                          </div>
                          {item.link && 
                            <a href={item.link} target="_blank" rel="noreferrer">
                              <Button variant='ghost'>
                                <Icon name='external-link' />
                              </Button>
                            </a>
                          }       
                        </div>
                        {(item.description && !isBlankValue(item.description)) && 
                          <RenderedSlate value={item.description} />
                        }
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

