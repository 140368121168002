
import React from 'react'
import axios from 'axios'
import { Form, useActionData, useNavigation, useSubmit } from 'react-router'
import { Button } from '@designsystem'
import useConfirmModal from '@hooks/useConfirmModal'
import { useToast } from '@hooks/useToast'

type ActionData = {
  toast: {
    message: string,
    appearance: 'success' | 'error'
  }
}

export async function action() {
  try {
    await axios.put('/pages/admin/workbench/google/force-holidays-sync')
    return {
      toast: {
        message: 'The Holidays Sync has started. It may take a while, please wait...',
        appearance: 'success'
      }
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while syncing...',
        appearance: 'error'
      }
    }
  }
}

export default function WorkbenchGoogleForceHolidaysRoute() {
  const submit = useSubmit()
  const confirmAction = useConfirmModal()
  const navigation = useNavigation()
  useToast(useActionData() as ActionData)
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.currentTarget)
    const confirmationMessage = {
      title: 'Confirm Sync', subtitle: 'Are you sure you want to sync the holidays?', children: <HolidaysSyncConfirmationModalMessage />
    }
    if (await confirmAction(confirmationMessage)) {
      submit(formData, { method: 'PUT' })
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <h1 className="font-bold text-2xl">
        Sync Holidays
      </h1>
      <span>Here, you can synchronize Sora Home's Holidays list with the School Holidays Calendar.*</span>
      <span className="italic font-bold text-sm">*The holidays list is auto refreshed every 24 hours. Execute this if you just made a recent change to the School Holidays Calendar and want to update sora home's holidays list.</span>
      <Form method="PUT" onSubmit={handleSubmit}>
        <Button
          type="submit"
          loading={navigation.state === 'submitting'}
        >
          {navigation.state === 'submitting' ? 'Syncing...' : 'Force Holidays Sync'}
        </Button>
      </Form>
    </div>
  )
}
function HolidaysSyncConfirmationModalMessage() {
  return (
    <span>This sync will refresh all holidays within Sora Home to match the School Holidays Calendar from Google Calendar.</span>
  )
}