import React, { useState } from 'react'
import axios from 'axios'
import { toast } from 'sonner'

import MyOtherSeparator from '@components/my-other-separator'
import { useThisPageTitle } from '@hooks/usePageTitle'
import ExpeditionTableRow from '@components/expedition/table-row'
import SearchBar from '@components/searchbar'
import useBulkSync from '@hooks/useBulkSync'
import useQuery from '@hooks/useQuery'
import { Alert, Button, Typography } from '@design-system'
import { Outlet, useLoaderData, useRevalidator, createSearchParams } from 'react-router'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'
import { Tab } from '@headlessui/react'
import { SubmenuPortal } from '@routes/index'
import SoraLink from '@components/link'

export default function EmployeeExpeditionsEditPage() {
  const [queryParams, setQueryParams] = useQuery()
  const revalidator = useRevalidator()
  const loaderData = useLoaderData()
  const mutate = revalidator.revalidate

  const expeditions = loaderData.themes
  const archivedExpeditions = loaderData.archivedExpeditions
  const selectedCycleId = loaderData.selectedCycleId
  const availableCyclesForDuplication = loaderData.availableCyclesForDuplication
  const userRole = loaderData.userRole
  const isNewExperienceFormEnabled = loaderData.isNewExperienceFormEnabled
  const userCohorts = loaderData.userCohorts
  const cycleId = loaderData.cycleId
  const types = loaderData.types
  const typesTitles = loaderData.typesTitles
  const archivedsOpen = queryParams.with_archiveds === 'true'

  useThisPageTitle('Managing Experiences')


  const { sync: bulkSync, data: bulkSyncData } = useBulkSync()
  const [isWaitingBulkSync, setIsWaitingBulkSync] = useState(false)
  const navigate = useNavigateWithCycle()

  const handleBulkSync = async () => {
    setIsWaitingBulkSync(true)
    await bulkSync()
    setIsWaitingBulkSync(false)
  }

  const toggleArchivedsOpen = () => {
    setQueryParams({ ...queryParams, with_archiveds: archivedsOpen ? 'false' : 'true' })
  }

  const toggleCohortView = () => {
    setQueryParams({ ...queryParams, cohorts: queryParams?.cohorts === 'all' ? 'primary' : 'all' })
  }

  const handleDuplicate = (
    expedition,
    { dupRegisters, bringChannel, dupSessions, dupLearningBlock, targetCycleId } = {}
  ) => {
    return axios
      .post(
        `/expeditions/${expedition.id}/duplicate?cycle_id=${selectedCycleId}`,
        {
          dupRegisters,
          dupSessions,
          bringChannel,
          targetCycleId,
          dupLearningBlock,
        }
      )
      .then(() => mutate())
      .then(() =>
        toast.success('Duplicated successfully on another cycle')
      )
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || error.message || 'Could not duplicate expedition, try again later!')
      })
  }

  const handlePublish = (id) =>
    axios
      .post(`/expeditions/${id}/publish`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not publish expedition, try again later!')
      })

  const handlePublishBulk = (cycleId, type) => () =>
    axios
      .post(`/expeditions/publish/${cycleId}/${type}`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not publish expedition, try again later!')
      })

  const handleDraft = (id) =>
    axios
      .post(`/expeditions/${id}/draft`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not draft expedition, try again later!')
      })

  const handleDraftBulk = (cycleId, type) => () =>
    axios
      .post(`/expeditions/draft/${cycleId}/${type}`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not draft expedition, try again later!')
      })

  const handleArchive = (id) =>
    axios
      .delete(`/expeditions/${id}`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not archive expedition, try again later!')
      })

  const handleSync = (id) =>
    axios
      .post(`/expeditions/${id}/sync-people`, {})
      .then((res) => {
        const { errors } = res.data.result
        for (const error of errors) toast(error)
        toast('Sync will start soon!')
      })
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not sync expedition, try again later!')
      })

  const handleUnarchive = (id) =>
    axios
      .patch(`/expeditions/${id}/unarchive`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not unarchive expedition, try again later!')
      })

  const handleDelete = (id) =>
    axios
      .delete(`/expeditions/${id}`, {
        data: {
          event: 'delete',
        },
      })
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not delete expedition, try again later!')
      })

  const handleReset = (id) =>
    axios
      .post(`/expeditions/${id}/reset`)
      .then(() => mutate())
      .catch((error) => {
        const message = error.response?.data?.error?.message
        toast.error(message || 'Could not reset expedition, try again later!')
      })

  const type = queryParams.type || 'expedition'

  const isPublishable = expeditions.filter(exp => exp.type === type).some(exp => exp.is_draft)
  const myExpeditions = expeditions.filter((exp) => exp.is_own && !exp.archived)
  const othersExpeditions = expeditions.filter(
    (exp) => !exp.is_own && !exp.archived
  )

  const handleOnChangeIndex = (index) => {
    navigate({ search: `?${createSearchParams({ cycle_id: cycleId, type: types[index] })}` })
  }

  return (
    <div id="expedition-management" data-cy="management-table" className="min-w-[75rem]">
      <Outlet />
      <Typography variant="heading-3" weight="bold" className="mb-6">Experiences</Typography>
      <Alert variant="notice" className='mb-6'>
        <Alert.Title>This page is being phased out.</Alert.Title>
        <Alert.Description>Please use the new Experience page for the latest features and updates.</Alert.Description>
        <Alert.Actions>
          <Button asChild size="xs" variant="outlined">
            <SoraLink to="/experiences">
              Go to new Experience page
            </SoraLink>
          </Button>
        </Alert.Actions>
      </Alert>
      <SubmenuPortal>
        <Tab.Group onChange={handleOnChangeIndex} selectedIndex={types.indexOf(type)}>
          <Tab.List className='flex h-full items-end'>
            {typesTitles.map((typeTitle) => (
              <Tab
                key={`tab_${typeTitle}`}
                className={({ selected }) => `
                  px-4 border-b-2 py-1.5 text-xs font-bold uppercase outline-none
                  ${selected ? 'border-accent text-accent' : 'border-transparent'}
                `}
              >
                {typeTitle}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      </SubmenuPortal>
      <header className="mb-4">
        <div className="w-full flex gap-1">
          {!!userCohorts.length && (
            <Button
              variant="outlined"
              color="accent"
              onClick={toggleCohortView}
            >
              {queryParams?.cohorts === 'all' ? 'Show my cohorts' : 'Show all cohorts'}
            </Button>
          )}
          <div className='w-full' />
          {userRole === 'admin' && (
            <Button
              variant="outlined"
              id="publish-draft-btn"
              onClick={isPublishable ? handlePublishBulk(cycleId, type) : handleDraftBulk(cycleId, type)}
            >
              {isPublishable ? 'Publish all' : 'Draft all'}
            </Button>
          )}
          {userRole === 'admin' && (
            <>
              {!bulkSyncData?.inProgress ? (
                <Button
                  variant="outlined"
                  onClick={
                    isWaitingBulkSync ? () => null : () => handleBulkSync()
                  }
                  id="bulk-sync-btn"
                >
                  Sync cycle expeditions
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  id="bulk-sync-in-progress-btn"
                  loading
                >
                  Syncing expeditions ({bulkSyncData.processedExpeditions}/
                  {bulkSyncData.totalExpeditions})
                </Button>
              )}
            </>
          )}
        </div>
        <SearchBar
          placeholder="Search by title, description, employee, student, units or abilities..."
          className="mt-4 w-full bg-white border-none shadow rounded-full px-4 py-2"
        />
      </header >
      <table className="table-fixed w-full mb-4">
        <thead className="text-xs uppercase">
          <tr>
            <th className="px-4 py-2">Title</th>
            <th className="px-4 py-2 w-60">Campus</th>
            <th className="px-4 py-2 w-40">Students In</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white shadow" id="my-own-expeditions">
          {myExpeditions.length > 0 ? (
            myExpeditions.map((exp) => (
              <ExpeditionTableRow
                key={exp.id}
                exp={exp}
                availableCycles={availableCyclesForDuplication}
                canEdit={true}
                role={userRole}
                onArchive={handleArchive}
                onSync={handleSync}
                onDuplicate={handleDuplicate}
                onPublish={handlePublish}
                onDraft={handleDraft}
                onReset={handleReset}
              />
            ))
          ) : (
            <tr>
              <td
                colSpan="4"
                className="text-center text-xs text-gray-40 py-4 border"
              >
                You own no experiences
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <MyOtherSeparator />
      <table
        className="table-fixed w-full mb-2 rounded mt-4"
        id="other-expeditions"
      >
        <tbody className="bg-white shadow">
          {
            othersExpeditions.map((exp) => (
              <ExpeditionTableRow
                key={exp.id}
                exp={exp}
                availableCycles={availableCyclesForDuplication}
                canEdit={userRole === 'admin'}
                role={userRole}
                onArchive={handleArchive}
                onSync={handleSync}
                onDuplicate={handleDuplicate}
                onPublish={handlePublish}
                onDraft={handleDraft}
                onReset={handleReset}
              />
            ))
          }
        </tbody>
      </table>
      <footer className="flex flex-col items-center">
        <button
          onClick={toggleArchivedsOpen}
          className="mt-8 justify-self-start hover:underline text-sm text-gray-60"
        >
          {archivedExpeditions.length && archivedsOpen ? 'Hide' : 'Show'} {archivedExpeditions.length} Archiveds...
        </button>
        <table
          hidden={!archivedsOpen}
          className="table-fixed w-full mb-2 rounded mt-4"
          id="other-expeditions"
        >
          <tbody className="bg-gray-20 shadow">
            {
              archivedExpeditions.map((exp) => (
                <ExpeditionTableRow
                  key={exp.id}
                  exp={exp}
                  canEdit={
                    userRole === 'admin' ||
                    exp.is_own
                  }
                  isNewExperienceFormEnabled={isNewExperienceFormEnabled}
                  availableCycles={availableCyclesForDuplication}
                  role={userRole}
                  onUnarchive={handleUnarchive}
                  onDelete={handleDelete}
                  onDuplicate={handleDuplicate}
                />
              ))}
          </tbody>
        </table>
      </footer>
    </div >
  )
}
