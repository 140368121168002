import React, { useEffect } from 'react'
import axios from 'axios'
import { useActionData, useLoaderData, useNavigate } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import OnboardingChecklistModal from '@components/onboarding/checklist/modal'
import { useToast } from '@hooks/useToast'

interface LoaderData {
  onboardingPhasesOptions: Array<{ label: string, value: number }>
}

type ActionData = {
  redirectUrl: string,
  toast: { message: string, appearance: 'success' | 'error' }
} | undefined

export async function loader({ params }) {
  const result = await axios.get(`/pages/admin/workbench/onboarding/${params.onboarding_group_id}/content/checklist/new`)
  return result.data
}

export async function action({ request, params }) {
  const formData = serializeFormData(await request.formData())
  const body = {
    id: formData.id,
    title: formData.title,
    guardian_description: formData.guardian_description,
    student_description: formData.student_description,
    due_date: formData.due_date,
    onboarding_phase_id: formData.onboarding_phase_id,
    visible_for: Object.keys(formData?.users || {}).length === 2 ? 'both' : Object.keys(formData?.users)[0],
  }
  try {
    await axios.post(`/pages/admin/workbench/onboarding/${params.onboarding_group_id}/content/checklist/new`, body)
    return {
      toast: { message: 'Checklist created', appearance: 'success' },
      redirectUrl: '..'
    }
  } catch (error) {
    return {
      toast: {
        message: 'Something went wrong while creating the checklist.',
        appearance: 'error',
      }
    }
  }
}

export default function WorkbenchOnboardingChecklistNewRoute() {
  const navigate = useNavigate()
  const actionData = useActionData() as ActionData
  const loaderData = useLoaderData() as LoaderData
  useToast(actionData)

  useEffect(() => {
    if (actionData?.redirectUrl) {
      navigate(actionData.redirectUrl)
    }
  }, [actionData?.redirectUrl])

  return (
    <OnboardingChecklistModal
      title="Create Checklist"
      onClose={() => navigate('..')}
      onboardingPhasesOptions={loaderData?.onboardingPhasesOptions}
    />
  )
}
