import axios from 'axios'
import React, { useRef, useState } from 'react'
import { ActionFunctionArgs, useParams, useOutletContext, Link } from 'react-router'
import { Button, Icon, Typography } from '@design-system'
import { RenderedSlate } from '@components/forms/slate-textarea'
import useSWR from 'swr'
import { TextField } from '@designsystem'
import { toast } from 'sonner'
import SoraLink from '@components/link'
import { cloneDeep } from 'lodash'

// !!!: This is a WIP component
// !!!: This component is a copy of the legacy AiChat component, should be refactored to fit the new architecture\

// TODO: This component is a copy of the legacy AiChat component, should be refactored to fit the new architecture
// TODO: Adapt this component to use the "Remix" approach (should we abolish SWR or should we have exceptions in its use?)
// TODO: Make streamings work
// TODO: fetch from new endpoint
// TODO: Fetch: userName, image URL
async function loader({ params }) {
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/mid-cycle-report-ai`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/mid-cycle-report-ai`,
    formData
  )
  return res.data
}

function Element() {
  return (
    <div className="relative flex flex-col w-[460px] overflow-hidden bg-white border-x">
      <PageHeader />
      <AiChat />
    </div>
  )
}

function PageHeader() {
  return (
    <>
      <div className="flex flex-row justify-between py-4 px-6 items-center">
        <Typography variant="heading-5" weight="bold">
          Mid-cycle report feedback
        </Typography>
        <SoraLink className="flex-none" to={'..'} as={Link}>
          <Button variant="outlined" color="soft" size="xs">
            <Icon name="cross" />
          </Button>
        </SoraLink>
      </div>
    </>
  )
}

function AiChat() {
  const [setSlateCurrentValue] = useOutletContext<Array<any>>()

  const { studentId, experienceId } = useParams()
  const [messages, setMessages] = useState([])
  const [textFieldValue, setTextFieldValue] = useState('')

  // Used to lock chat and prevent new messages to be sent
  const [isSendingMessage, setIsSendingMessage] = useState(false)

  // Used to scroll to bottom of the chat
  const bottomEl = useRef(null)

  const urlPath = `/sora-ai/reports/mid-cycle-draw`

  const { isValidating, mutate } = useSWR(`${urlPath}?student_id=${studentId}&theme_id=${experienceId}`, {
    revalidateOnFocus: false,
    onSuccess: (data) => {
      setMessages(data.messages.data)
      // Scroll if it is the first loading
      if (!messages) bottomEl?.current?.scrollIntoView() // Hard scrolling
    },
    onError: (err, key) => {
      toast.error('Failed to get initial report from Ai, please refresh & try again.')
    },
  })

  const handleSendMessage = () => async () => {
    try {
      setIsSendingMessage(true)
      const res = await axios.post(`${urlPath}`, {
        student_id: studentId,
        theme_id: experienceId,
        new_message: textFieldValue,
      })
      setMessages(res.data.messages.data)
      setTextFieldValue('')
      setIsSendingMessage(false)
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' })
    } catch (err) {
      setIsSendingMessage(false)
      toast.error('Failed to get message from Ai. Please refresh & try again.')
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setTextFieldValue(value)
  }

  const handleKeyDown = async (event) => {
    // I a special key is pressed we use the key
    if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) return
    // If we press enter, then send the message
    if (event.key === 'Enter') {
      await handleSendMessage()()
      return true
    }
  }

  const handleClearChat = async () => {
    try {
      setIsSendingMessage(true)
      await axios.delete(`${urlPath}?student_id=${studentId}&theme_id=${experienceId}`)
      setMessages([])
      mutate() // revalidate SWR
      setIsSendingMessage(false)
      bottomEl?.current?.scrollIntoView({ behavior: 'smooth' })
    } catch (err) {
      setIsSendingMessage(false)
      toast.error('Failed to get restart Ai chat. Please refresh & try again.')
    }
  }

  const shouldDisplayLoading = isValidating && (!messages || messages.length == 0)

  const isUserMessage = (message) => {
    return message ? message.role === 'user' : false
  }

  return (
    <div className="overflow-auto p-6">
      {/* LOADING WIDGET */}
      {shouldDisplayLoading && (
        <div key="loading-widget" className="flex flex-col justify-center grow">
          <p className="text-center text-gray-70	text-lg pt-6">
            We are generating
            <br /> the initial chat
          </p>
        </div>
      )}

      <section className="mx-0">
        <div className="flex flex-col mb-20 gap-8 ">
          {/* EVERY ROW IS A MESSAGE */}
          {messages &&
            messages.map((message) => {
              if (isUserMessage(message)) {
                return (
                  <div key={message.id} className="flex flex-row items-end gap-2 justify-end">
                    <div
                      className={`flex flex-col pxl-8 bg-neutral-900 bg-opacity-5  rounded-t-xl rounded-l-xl max-w-full`}
                    >
                      {/* MESSAGE */}
                      <RenderedSlate className="my-2 mx-5 break-normal" value={message.contentMarkdown} />
                    </div>
                  </div>
                )
              } else {
                return (
                  <div key={message.id} className="flex flex-row  gap-2 ">
                    <Icon name="sparkles" size="sm" className="text-blue-40" />
                    <div className={`flex flex-col  rounded-t-xl rounded-r-xl `}>
                      {/* MESSAGE */}
                      <RenderedSlate className="break-normal" value={message.contentMarkdown} />

                      {/* INSERT BUTTON */}
                      <div className="mt-2">
                        <Button
                          onClick={() => {
                            setSlateCurrentValue(cloneDeep(message.contentMarkdown))
                          }}
                          loading={false}
                        >
                          Insert
                        </Button>
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          {/* Used for scrolling to bottom */}
          <div ref={bottomEl}></div>
        </div>

        {/* Use relative if you want the text field scrollable too*/}
        {/* TEXT INPUT SECTION */}
        <div className="flex flex-col absolute bottom-0 left-0 right-0 bg-white border-t">
          <div className="my-4 mx-6">
            <TextField
              onKeyDown={handleKeyDown}
              disabled={isValidating || isSendingMessage}
              name="ai_instructions"
              placeholder="Tell AI what to do next..."
              onChange={handleChange}
              value={textFieldValue}
              startAdornment={
                <TextField.Adornment
                  as="div"
                  className={`flex rounded-full   text-blue-40  ${
                    isValidating || isSendingMessage ? 'animate-pulse' : ''
                  }`}
                >
                  <Icon name="sparkles" size="sm" />
                </TextField.Adornment>
              }
              endAdornment={
                <Button
                  size="xs"
                  type="button"
                  className={`rounded-full ${isValidating || isSendingMessage ? '' : 'cursor-pointer'} p-1`}
                  onClick={isValidating || isSendingMessage ? null : handleSendMessage()}
                  disabled={isValidating || isSendingMessage || textFieldValue.length === 0}
                >
                  <Icon name="send" size="xs" />
                </Button>
              }
            />
            <div className="flex flex-row text-xs font-thin mt-1	place-content-center">
              <div>Want to start over?</div>
              <a className="underline cursor-pointer text-blue-40" onClick={handleClearChat}>
                &nbsp;Reset report
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export const FacilitateExperienceTaskStudentMidCycleReportAiRoute = {
  loader,
  action,
  Element,
}
