import React from 'react'
import SoraLink from '@components/link'
import { Button, Icon, Typography, unstable_Tooltip as Tooltip, Card } from '@design-system'
import { useFetcher } from '@design-system'
import format from 'date-fns/format'

export function HouseCard({ house, isSyncingAll, canManageHouses }) {
  const fetcher = useFetcher()
  const isLoading = isSyncingAll || fetcher.state !== 'idle'

  const stringDate = `${format(new Date(Date.now()), 'Y-MM-dd')} ${house.default_standup_time}`
  const defaultStandupDate = house.default_standup_time && new Date(stringDate)

  return (
    <Card actionable className="group relative">
      <Card.Content asChild className="flex flex-col gap-6 w-full text-center">
        <SoraLink to={`/houses/${house.id}/overview`}>
          <figure className="h-32">
            {house.logo_url && (
              <img className="w-auto h-32" src={house.logo_url} alt={`${house.title}'s logo`} height="120" width="160" />
            )}
          </figure>
          <section className="space-y-2">
            <Typography variant="subheadline" weight="bold">{house.title}</Typography>
            <Typography variant="footnote" color="secondary" className="flex flex-col gap-0.5">
              <span>House Advisor: {house.advisor || 'TBD'}</span>
              <span className="text-nowrap text-ellipsis overflow-hidden">President: {house.president || 'TBD'}</span>
              {defaultStandupDate && (
                <span>
                  House meeting: {defaultStandupDate.toLocaleTimeString([], { timeZone: 'EST', hour: '2-digit', minute: '2-digit', hour12: true })} EST
                </span>
              )}
            </Typography>
          </section>
          <nav className="flex gap-2 justify-center">
            <Button asChild variant="outlined" color="soft" size="sm">
              <SoraLink to={`/houses/${house.id}/students`}>Students</SoraLink>
            </Button>
            <Button asChild variant="outlined" color="soft" size="sm">
              <SoraLink to={`/houses/${house.id}/attendances`}>Attendance</SoraLink>
            </Button>
          </nav>
        </SoraLink>
      </Card.Content>
      {canManageHouses && (
        <div className="hidden group-hover:flex absolute top-2 right-2 gap-1">
          <Tooltip content="Edit house">
            <Button asChild variant="ghost" size="xs">
              <SoraLink to={`/houses/edit/${house.id}`}>
                <Icon size="xs" name="edit-3" />
              </SoraLink>
            </Button>
          </Tooltip>
          <fetcher.Form method="POST">
            <input type='hidden' name='house_title' value={house.title} />
            <Tooltip content={`Sync ${house.title} events in Google Calendar`}>
              <Button variant="ghost" size="xs" type='submit' name='house_id' loading={isLoading} value={house.id}>
                <Icon size="xs" name="refresh-cw" />
              </Button>
            </Tooltip>
          </fetcher.Form>
        </div>
      )}
    </Card>
  )
}
