import React from 'react'
import PropTypes from 'prop-types'
import { Combobox, useFetcher } from '@design-system'

export const PathwaySelector = ({ pathways = [], defaultValue, schoolYear, studentId, assignId }) => {
  const fetcher = useFetcher()

  const isLoading = fetcher.state !== 'loading' && fetcher.formData?.get('studentId') === String(studentId)

  const handleChange = (event) => {
    event.preventDefault()
    fetcher.submit(event.target.form)
  }

  return (
    <fetcher.Form method="POST" onChange={handleChange}>
      <input type="hidden" name="_action" value='save_student_pathway' />
      <input type="hidden" name="schoolYear" value={schoolYear} />
      <input type="hidden" name="studentId" value={studentId} />
      {assignId && <input type="hidden" name="assignId" value={assignId} />}
      <Combobox name='pathwayId' defaultValue={defaultValue || ''} placeholder='Select a pathway' loading={isLoading} className='w-[250px] '>
        <Combobox.Item value=''>
          <span className='text-danger-50'>None</span>
        </Combobox.Item>
        {pathways.map(pathway => (
          <Combobox.Item key={`${schoolYear}_${pathway.id}`} value={pathway.id}>
            {pathway.title}
          </Combobox.Item>
        ))}
      </Combobox>
    </fetcher.Form>
  )
}
PathwaySelector.propTypes = {
  pathways: PropTypes.array,
  defaultValue: PropTypes.number,
  schoolYear: PropTypes.number,
  studentId: PropTypes.number,
  assignId: PropTypes.number,
}
