import React from 'react'
import { Avatar, Typography, unstable_Tooltip as Tooltip } from '@design-system'
import SoraLink from '@components/link'
import { Student } from './types'
import { CopyButton } from './copy-button'

interface CellStudentNameProps extends Pick<Student, 'email' | 'id' | 'profileImageUrl' | 'name' | 'yearStanding'> { }

export const CellStudentName = ({ email, id, profileImageUrl, name, yearStanding }: CellStudentNameProps) => (
  <CopyButton content={email} className="flex items-center gap-2">
    <Avatar size="lg">
      <Avatar.Image src={profileImageUrl} />
      <Avatar.Fallback>{name}</Avatar.Fallback>
    </Avatar>
    <div>
      <Tooltip content={name}>
        <Typography variant="callout" weight="bold" className="whitespace-nowrap hover:underline w-32 overflow-hidden text-ellipsis">
          <SoraLink to={`/employee/students/${id}`}>{name}</SoraLink>
        </Typography>
      </Tooltip>
      <Typography variant="callout" className="text-gray-80 dark:text-gray-40 whitespace-nowrap">
        {yearStanding}
      </Typography>
    </div>
  </CopyButton>
)
