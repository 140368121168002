import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  YEAR_OPTIONS,
  GRADE_OPTIONS,
  CREDIT_OPTIONS_MAP,
  NUMBER_OF_UNITS_PER_CREDIT,
  getCreditOptions,
} from '../exemptions.utils'
import { Button, TextField } from '@designsystem'
import { Form, useNavigation } from 'react-router'
import _orderBy from 'lodash/orderBy'
import pluralize from '@utils/pluralize'

export default function BaseExemptionForm({
  topic,
  defaultValues,
  onDiscardChanges,
}) {
  const navigation = useNavigation()
  const creditOpts = getCreditOptions(topic)
  const [formData, setFormData] = useState({
    credit: Object.keys(creditOpts)[0],
    units: _orderBy(topic?.units || [], u => u.title),
    ...defaultValues,
  })

  const numOfRequiredExemptions = Math.min(NUMBER_OF_UNITS_PER_CREDIT[formData.credit], formData.units.length)
  const allUnitsMustBeExempted = Object.values(creditOpts).length === 1 && formData.units.length === numOfRequiredExemptions
  const numOfExemptions = formData.units.filter(u => !!u.is_exempt).length
  const isSubmitBtnDisabled = navigation.state === 'submitting' || (!allUnitsMustBeExempted && formData.units.length && numOfExemptions < numOfRequiredExemptions)

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target

    if (name === 'credit') {
      return setFormData(prev => ({
        ...prev,
        credit: value,
        units: _orderBy(topic?.units || [], u => u.title).map(u => ({ ...u, is_exempt: false })),
      }))
    }

    const match = name.match(/^([^[]+)\[([^\]]+)\]\[([^\]]+)\]$/)
    if (match) {
      const [, formKey, id, dataKey] = match
      const unit = formData[formKey].find(v => Number(v.id) === Number(id))
      const newData = _orderBy(
        [...formData[formKey]].filter(v => Number(v.id) !== Number(id)).concat({ ...unit, [dataKey]: type === 'checkbox' ? checked : value }),
        u => u.title
      )
      return setFormData(prev => ({ ...prev, [formKey]: newData }))
    }

    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }))
  }

  return (
    <Form method="POST" className="space-y-4" onChange={handleFormChange}>
      {formData.topic_id ? (
        <>
          <input type="hidden" name="_action" value={formData.id ? 'updateExemption' : 'createExemption'} />
          <input type="hidden" name="is_elective" value={formData.is_elective} />
          <input type="hidden" name="topic_id" value={formData.topic_id} />
        </>
      ) : (
        <>
          <input type="hidden" name="_action" value={formData.id ? '' : 'createStaticExemption'} />
          {formData.clearKey && <input type="hidden" name="clearKey" value={formData.clearKey} />}
          <input type="hidden" name="subject_id" value={formData.subject_id} />
        </>
      )}
      {formData.id && (
        <input type="hidden" name="id" value={formData.id} />
      )}
      <TextField
        name="course"
        label="Transferred Course Name"
        data-cy="course_name_input"
        defaultValue={defaultValues?.course || ''}
      />
      <TextField
        name="school_name"
        label="School Name"
        data-cy="school_name_input"
        defaultValue={defaultValues?.school_name || ''}
      />
      <div className="flex justify-between items-end">
        <div className="flex flex-row space-x-4">
          <TextField
            label="Credits"
            as="select"
            defaultValue={formData.credit}
            name="credit"
          >
            {Object.keys(creditOpts).map((creditValue) => (
              <option key={creditValue} value={creditValue}>
                {CREDIT_OPTIONS_MAP[creditValue]}
              </option>
            ))}
          </TextField>
          <TextField
            name="grade"
            label="Grade"
            as="select"
            defaultValue={defaultValues?.grade || GRADE_OPTIONS[0]}
          >
            {GRADE_OPTIONS.map((grade) => (
              <option key={grade} value={grade}>
                {grade}
              </option>
            ))}
          </TextField>
          <TextField
            name="year"
            label="Year"
            as="select"
            defaultValue={defaultValues?.year || YEAR_OPTIONS[0]}
          >
            {YEAR_OPTIONS.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </TextField>
        </div>
      </div>
      {topic && formData.units.length && (
        <div className="flex flex-col">
          <h4 className="font-bold text-md mt-2">Exempted Units:</h4>
          <div>
            <ul className="columns-3">
              {formData.units.map(u => (
                <li className="flex items-center break-words" key={u.id}>
                  <input type="hidden" name={`units[${u.id}][id]`} value={u.id} />
                  <input type="hidden" name={`units[${u.id}][title]`} value={u.title} />
                  <input
                    type="checkbox"
                    className="mr-2 border rounded-md disabled:cursor-not-allowed disabled:opacity-50 hover:cursor-pointer"
                    name={`units[${u.id}][is_exempt]`}
                    checked={allUnitsMustBeExempted || !!u.is_exempt}
                    disabled={allUnitsMustBeExempted || (!u.is_exempt && numOfExemptions >= numOfRequiredExemptions)}
                    onChange={handleFormChange}
                  />
                  <p>{u.title}</p>
                </li>
              ))}
            </ul>
            {!allUnitsMustBeExempted && (
              <p className={`mt-1 text-danger-20 ${numOfExemptions < numOfRequiredExemptions ? '' : 'invisible'}`}>
                You need to
                select {numOfRequiredExemptions - numOfExemptions} more {pluralize(numOfRequiredExemptions - numOfExemptions, 'unit')}
              </p>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-row space-x-2 justify-end">
        <Button
          type="button"
          variant="outlined"
          onClick={onDiscardChanges}
        >
          Discard
        </Button>
        <Button
          type="submit"
          disabled={isSubmitBtnDisabled}
        >
          Save {formData.id ? 'Changes' : ''}
        </Button>
      </div>
    </Form>
  )
}
BaseExemptionForm.propTypes = {
  topic: PropTypes.object,
  children: PropTypes.node,
  onDiscardChanges: PropTypes.func,
  defaultValues: PropTypes.object,
}
