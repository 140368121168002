import React from 'react'
import { Outlet } from 'react-router'

const Component = () => {
  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Outlet />
    </React.Suspense>
  )
}

export const ExperiencesRoute = {
  Element: Component,
}
