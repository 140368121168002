import React from 'react'
import axios from 'axios'
import { useLoaderData, useNavigate } from 'react-router'
import { Dialog, Typography } from '@design-system'

interface Demonstration {
  demoNr: number
  expeditionName: string
  cycle: string
  level: string
}

interface LoaderData {
  title: string
  demonstrations: Demonstration[]
  additionalDemonstrations: Demonstration[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/program/${params.schoolStage}/overview/abilities/${params.abilityId}?${searchParams.toString()}`)
  return data
}

function Element() {
  const { title, demonstrations, additionalDemonstrations } = useLoaderData() as LoaderData
  const navigate = useNavigate()
  const handleClose = () => navigate(-1)
  return (
    <Dialog open onClose={handleClose}>
      <Dialog.Content side="right" className="overflow-auto min-w-[500px]">
        <Dialog.Header variant="outlined">
          <Dialog.Title>Ability Demonstrations</Dialog.Title>
          <Dialog.Close />
        </Dialog.Header>
        <Dialog.Body>
          <Typography variant="heading-5" weight="bold">{title}</Typography>
          {demonstrations?.map((demonstration) => (
            <DemonstrationItem key={demonstration.demoNr} {...demonstration} />
          ))}
          {additionalDemonstrations?.length > 0 && (
            <div className="mt-8">
              <Typography variant="subheadline" weight="bold">Additional Demonstrations</Typography>
              <Typography variant="callout" color="secondary">These demonstrations are not considered towards graduation progress</Typography>
            </div>
          )}
          {additionalDemonstrations?.map((demonstration) => (
            <DemonstrationItem key={demonstration.demoNr} {...demonstration} />
          ))}
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}

function DemonstrationItem({ expeditionName, cycle, level }: Demonstration) {
  return (
    <div className="border-b border-dashed border-gray-30 dark:border-gray-80 pb-3 pt-2">
      <Typography weight="bold">{expeditionName}</Typography>
      <div className="flex gap-2 justify-between">
        <Typography>{level}</Typography>
        <Typography variant="callout" color="secondary">{cycle}</Typography>
      </div>
    </div>
  )
}

export const EmployeeStudentProgramOverviewAbilitiesRoute = {
  Element,
  loader,
}


