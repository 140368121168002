import React, { useEffect } from 'react'
import axios from 'axios'
import { useLoaderData, Outlet, useLocation } from 'react-router'
import { PostHogProvider, usePostHog } from 'posthog-js/react'
import useTheme from '@hooks/useTheme'

async function loader() {
  try {
    const { data } = await axios.get(`/backoffice/_root`)
    return data
  } catch (error) {
    console.error(error)
  }
}

const Element = () => {
  const { posthogApiKey } = useLoaderData() as { posthogApiKey: string }
  const location = useLocation()
  const posthog = usePostHog()
  useTheme()
  useEffect(() => {
    if (location && posthog) {
      const url = `${window.origin}${location.pathname}${location.search}`
      posthog.capture(
        '$pageview',
        { '$current_url': url }
      )
    }
  }, [location, posthog])
  return (
    <PostHogProvider apiKey={posthogApiKey}>
      <Outlet />
    </PostHogProvider>
  )
}

export const RootRoute = {
  loader,
  Element
}
