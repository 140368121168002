import React from 'react'
import PropTypes from 'prop-types'
import { Button, useFetcher } from '@design-system'

export const BasicDetails = ({ pathway, types = [] }) => {
  const fetcher = useFetcher()
  return (
    <fetcher.Form method="POST">
      <input type="hidden" name="_action" value="update_pathway" />
      <input type="hidden" name="pathwayId" value={pathway.id} />
      <div className='flex flex-col py-2 gap-2'>
        <label htmlFor="title">Title</label>
        <input id="title" name="title" type="text" className='rounded border-alpha/30' defaultValue={pathway.title} required />
        <label htmlFor="type">Type</label>
        <select id="type" name="type" className='rounded border-alpha/30' defaultValue={pathway.type} required >
          <option value="">Select a type</option>
          {types.map(type => (
            <option key={type.id} value={type.id}>{type.title}</option>
          ))}
        </select>
      </div>
      <div className='flex flex-row py-2 justify-end'>
        <Button type='submit'>
          Update
        </Button>
      </div>
    </fetcher.Form>
  )
}
BasicDetails.propTypes = {
  pathway: PropTypes.object,
  types: PropTypes.array,
}
