import axios from 'axios'
import React from 'react'
import { useLoaderData, useNavigate } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { Button, useFetcher, Combobox, Typography, Dialog } from '@design-system'
import { TextField } from '@designsystem'

async function loader() {
  const { data } = await axios.get(`/backoffice/houses/new`)
  return data
}

async function action({ request }) {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  try {
    const { data } = await axios.post(`/backoffice/houses/new`, serializedFormData)
    return data
  } catch (error) {
    return {
      toast: {
        appearance: 'error',
        message: error.response.data.error.message
      },
      errors: error.response.data.errors,
    }
  }
}

function Element() {
  const loaderData = useLoaderData()
  const fetcher = useFetcher()
  const isSubmitting = fetcher.state === 'submitting'
  const navigate = useNavigate()

  return (
    <Dialog
      open
      onClose={() => {
        navigate('../')
      }}
    >
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>
            New House
          </Dialog.Title>
          <Dialog.Close />
        </Dialog.Header>
        <fetcher.Form noValidate method="POST">
          <Dialog.Body>
            <TextField required label="Title" name="title" error={fetcher.data?.errors?.find(err => err.fieldName === 'title')?.message} defaultValue='' />
            <Combobox
              required
              label="Campus"
              name="cohortId"
              placeholder="Type or select the house's campus"
              error={fetcher.data?.errors?.find(err => err.fieldName === 'cohortId')?.message}
              defaultValue=''>
              {loaderData.cohorts.map(op =>
                <Combobox.Item key={op.id} value={op.id}>{op.title}</Combobox.Item>
              )}
            </Combobox>
            <Combobox
              required
              label="Standup Time"
              name="defaultStandupTime"
              placeholder="Select the house default standup time"
              defaultValue="">
              <Combobox.Item value="10:00:00-0500">10:00 am EST (EST/CST Recommended)</Combobox.Item>
              <Combobox.Item value="13:20:00-0500">01:20 pm EST (MST/PST Recommended)</Combobox.Item>
            </Combobox>
          </Dialog.Body>
          <Dialog.Footer>
            <Button type="submit" loading={isSubmitting}>
              {isSubmitting ? 'Saving...' : 'Save House'}
            </Button>
          </Dialog.Footer>
        </fetcher.Form>
      </Dialog.Content>
    </Dialog>
  )
}

export const HouseNewRoute = {
  Element,
  loader,
  action,
}
