import React from 'react'
import { Typography } from '@design-system'
import { Student } from './types'

interface CellStatusProps extends Pick<Student, 'status'> { }

export const CellStatus = ({ status }: CellStatusProps) => {
  return (
    <p className="capitalize">{status}</p>
  )
}
