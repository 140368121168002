import * as React from 'react'
import axios from 'axios'
import { ActionFunctionArgs, useLoaderData } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { Button, Card, Icon, unstable_Table as Table, Typography, useFetcher } from '@design-system'
import SoraLink from '@components/link'

interface LoaderData {
  experiences: Array<{
    id: number,
    title: string,
    cohortTitle: string,
    enableHeartbeat: string,
    heartbeatChannelId: string,
    heartbeatGroupId: string,
    experts: Array<string>
  }>,
  headers: Array<string>,
  cycle: {
    cycle_id: number,
    title: string,
  },
  potentialHeartbeatExperiencesCount: number,
  activeHeartbeatExperiencesCount: number,
}

async function loader() {
  const res = await axios.get('/backoffice/workbench/student-heartbeat-server/experiences')
  return res.data
}

async function action({ request }: ActionFunctionArgs) {
  const form = serializeFormData(await request.formData())
  const experienceId = form?.experienceId ?? ''
  const res = await axios.post(`/backoffice/workbench/student-heartbeat-server/experiences/${experienceId}`, form)
  return res.data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData
  return (
    <div className="flex flex-col gap-4 px-6">
      <Card>
        <Card.Content>
          <Typography variant="heading-6">Syncing experiences for <b>{loaderData.cycle.title}</b></Typography>
          <Typography variant="callout" color="secondary" className="mt-4 flex flex-col">
            <p>Now you can only sync experiences for cycles that have the "Enable Heartbeat Experiences Sync" checkbox checked.</p>
            <p>
              If you need to change the active cycle for heartbeat experiences, you can do so on the <SoraLink to="/cycles" target="_blank" className="text-blue-60 hover:underline">cycles page.</SoraLink>
            </p>
            <p className='mt-2'>
              Syncing experiences will:
              <ul className="list-disc list-inside">
                <li><b>Remove from heartbeat</b> experiences from cycles other than {loaderData.cycle.title}.</li>
                <li><b>Remove from heartbeat</b> archived experiences from any cycle.</li>
                <li><b>Remove from heartbeat</b> experiences with "Enable Heartbeat" checkbox unchecked - from any cycle (column "Enable HB").</li>
                <li><b>Add to heartbeat</b> experiences from {loaderData.cycle.title} that have the "Enable Heartbeat" checkbox checked (column "Enable HB").</li>
              </ul>
            </p>
            <p className='mt-2'>This sync will ensure heartbeat channels are created for <b>{loaderData.activeHeartbeatExperiencesCount}</b> experiences that have the "Enable HB" set on {loaderData.cycle.title}.</p>
          </Typography>
        </Card.Content>
        <div className="px-4">
          <SyncFormButton title={`Sync all experiences on ${loaderData.cycle.title}`} name="_action" value="syncAllExperiences" />
        </div>
      </Card>

      <Card>
        <Card.Content>
          <Typography variant="heading-6">Bulk enable heartbeat for experiences on <b>{loaderData.cycle.title}</b></Typography>
          <Typography variant="callout" color="secondary" className="mt-4 flex flex-col">
            <p>This is a shortcut to allow you enable heartbeat easily for experiences on {loaderData.cycle.title}.</p>
            <p>
              If you need to change the active cycle for heartbeat experiences, you can do so on the <SoraLink to="/cycles" target="_blank" className="text-blue-60 hover:underline">cycles page.</SoraLink>
            </p>
            <p className='mt-2'>
              Bulk enabling heartbeat for experiences will affect the following experiences on <b>{loaderData.cycle.title}</b>:
              <ul className="list-disc list-inside">
                <li><b>Expeditions</b>.</li>
                <li><b>Math Learning Goals</b>.</li>
                <li><b>Learning Goals</b>.</li>
              </ul>
            </p>
            <p className='mt-2'>Running this will ensure heartbeat is enabled for <b>{loaderData.potentialHeartbeatExperiencesCount}</b> experiences on {loaderData.cycle.title}.</p>
            <p className='mt-2'>This will <b>not</b> sync the experiences with heartbeat, only enable that config in mass.</p>
            <p>If you intend to sync them, please use the sync button above.</p>
          </Typography>
        </Card.Content>
        <div className="px-4">
          <SyncFormButton title={`Bulk enable heartbeat for experiences on ${loaderData.cycle.title}`} name="_action" value="bulkEnableHeartbeat" />
        </div>
      </Card>

      <Table className="border-separate border border-gray-30 rounded-lg">
        <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
          <Table.Row>
            {loaderData.headers.map((header, index) => (
              <Table.Head key={index} className="w-48 first:pl-4">{header}</Table.Head>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&_tr:last-child]:border-1">
          {loaderData.experiences.map((experience) => (
            <Table.Row key={experience.id} className="last-of-type:rounded-b-md">
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.id}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.title}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.cohortTitle}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.experts.length > 0 ? experience.experts.join('/') : '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.enableHeartbeat || '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.heartbeatChannelId || '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">{experience.heartbeatGroupId || '-'}</Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <SyncFormButton title="Sync this experience" name="experienceId" value={experience.id.toString()} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

interface SyncFormButtonProps {
  title: string,
  name?: string,
  value?: string,
}

function SyncFormButton({ title, name, value }: SyncFormButtonProps) {
  const fetcher = useFetcher()
  const loading = fetcher.state === 'loading' || fetcher.state === 'submitting'
  return (
    <fetcher.Form method="POST" className="self-end">
      <Button type="submit" name={name} value={value} size="sm" loading={loading}>
        <Icon name="arrow-swap" size="xs" /> {title}
      </Button>
    </fetcher.Form>
  )
}

export const WorkbenchStudentHearbeatServerExperiencesRoute = { loader, action, Element }
