import axios from 'axios'
import { LoaderFunctionArgs, redirect } from 'react-router'

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const { data: appUrl } = await axios.get(`/backoffice/app-sso?${searchParams.toString()}`)
  return redirect(appUrl)
}

export const AppSsoRoute = {
  loader,
}
