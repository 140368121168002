
import * as React from 'react'
import axios from 'axios'
import { format } from 'date-fns'
import { useLoaderData, ActionFunctionArgs, useSearchParams, LoaderFunctionArgs } from 'react-router'
import SoraLink from '@components/link'
import serializeFormData from '@utils/serializeFormData'
import { Avatar, Button, Pill, Typography, useFetcher, unstable_Checkbox as Checkbox, Icon, DropdownMenu, Spinner } from '@design-system'
import useNavigateWithCycle from '@hooks/useNavigateWithCycle'

type LoaderData = Awaited<ReturnType<typeof loader>>

type Club = {
  id: number,
  title: string,
  archived: boolean,
  imageAlt: string,
  imageUrl: string,
  membersCount: number,
  clubBlockDayOfWeek: string,
  clubBlockTime: Date,
  membersPreview: {
    id: number,
    imageUrl: string,
    imageAlt: string
  }[]
}

async function loader({ request }: LoaderFunctionArgs): Promise<{ clubs: Record<string, Club[]>, schoolStageFilters: { label: string, value: string }[], showActions: boolean }> {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/clubs?${searchParams.toString()}`)
  return data
}

async function action({ request }: ActionFunctionArgs) {
  const formData = serializeFormData(await request.formData())
  const { data } = await axios.post(`/backoffice/clubs`, formData)
  return data
}

function Element() {
  const loaderData = useLoaderData() as LoaderData
  return (
    <div className="flex flex-col gap-8 justify-start">
      <div className="flex items-start space-y-3 flex-col md:flex-row md:justify-between md:items-center">
        <Typography variant="heading-3" weight="bold">Clubs</Typography>
        {loaderData.showActions && (
          <div className="flex gap-4 items-center">
            <Button size="sm" asChild>
              <SoraLink to="edit">
                Create new club
              </SoraLink>
            </Button>
            <SyncWithHeartbeatButton />
          </div>
        )}
      </div>
      <Filters schoolStageFilters={loaderData.schoolStageFilters} />
      <div className="space-y-16 w-full">
        {Object.entries(loaderData.clubs).map(([schoolStage, clubs]) => (
          <div className="flex gap-6 flex-col" key={schoolStage}>
            <Typography variant="heading-6" weight="bold">{schoolStage}</Typography>
            <div className="flex flex-wrap gap-4">
              {clubs.map((club) => (
                <ClubCard club={club} key={club.id} showActions={loaderData.showActions} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

function SyncWithHeartbeatButton() {
  const fetcher = useFetcher()
  const isSubmitting = fetcher.state === 'submitting'
  return (
    <fetcher.Form method="POST">
      <Button variant="outlined" size="sm" type="submit" name="_action" value="sync_all_clubs_with_heartbeat" loading={isSubmitting}>
        Sync all Clubs with Heartbeat
      </Button>
    </fetcher.Form>
  )
}

interface FiltersProps {
  schoolStageFilters: { label: string, value: string }[]
}

function Filters({ schoolStageFilters }: FiltersProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <div className="flex items-start space-y-3 flex-col md:flex-row md:justify-between md:items-center">
      <div className="gap-2 flex items-center">
        {schoolStageFilters.map((ssFilter) => (
          <Button
            key={ssFilter.value}
            size="sm"
            variant={searchParams.get('schoolStage') === ssFilter.value || (ssFilter.value === 'all' && !searchParams.get('schoolStage')) ? 'contained' : 'outlined'}
            onClick={() => setSearchParams((prevValues) => {
              prevValues.set('schoolStage', ssFilter.value)
              return prevValues
            })}
          >
            {ssFilter.label}
          </Button>
        ))}
      </div>
      <div className="flex gap-2 items-center cursor-pointer">
        <Checkbox
          id="showArchived"
          name="showArchived"
          checked={!!searchParams.get('showArchived')}
          onCheckedChange={(checked) => setSearchParams((prevValues) => {
            if (!checked) prevValues.delete('showArchived')
            else prevValues.set('showArchived', 'true')
            return prevValues
          })}
        />
        <label htmlFor="showArchived">
          Show archived
        </label>
      </div>
    </div>
  )
}

interface ClubCardProps {
  club: Club,
  showActions: boolean
}

function ClubCard({ club, showActions }: ClubCardProps) {
  const navigate = useNavigateWithCycle()
  const dropdownItemVerb = !showActions ? 'View' : 'Edit'
  const fetcher = useFetcher()

  return (
    <article>
      <figure className={`relative border border-gray-30 rounded-xl h-72 w-72 cursor-pointer overflow-hidden ${club.archived ? 'grayscale' : ''}`}>
        <img src={club.imageUrl} alt={club.imageAlt} className="rounded-xl h-full w-full aspect-square object-cover" onClick={() => navigate(`/clubs/${club.id}/edit`)} />
        <div className="absolute top-4 right-4 flex justify-end rounded-xl">
          <fetcher.Form method="POST">
            <input type="hidden" name="club_id" value={club.id} />
            <DropdownMenu>
              <DropdownMenu.Trigger asChild>
                <Button color="light" size="xs">
                  {fetcher.state !== 'idle' ? (
                    <Spinner size="sm" />
                  ) : (
                    <Icon name="more-vert-filled" size="xs" />
                  )}
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content align="end">
                {club.archived && showActions && (
                  <DropdownMenu.Item asChild>
                    <button type="submit" name="_action" value="unarchive_club" disabled={fetcher.state === 'submitting'} className="w-full text-start">
                    Unarchive club
                    </button>
                  </DropdownMenu.Item>
                )}
                {!club.archived && (
                  <>
                    <DropdownMenu.Item asChild>
                      <SoraLink to={`/clubs/${club.id}/edit`}>
                        {dropdownItemVerb} details
                      </SoraLink>
                    </DropdownMenu.Item><DropdownMenu.Item asChild>
                      <SoraLink to={`/clubs/${club.id}/members`}>
                        {dropdownItemVerb} members
                      </SoraLink>
                    </DropdownMenu.Item>
                    {showActions && (
                      <>
                        <DropdownMenu.Item asChild>
                          <button type="submit" name="_action" value="sync_unique_club_with_heartbeat" disabled={fetcher.state === 'submitting'} className="w-full text-start">
                            Sync with Heartbeat
                          </button>
                        </DropdownMenu.Item>
                        <DropdownMenu.Item asChild>
                          <button type="submit" name="_action" value="archive_club" disabled={fetcher.state === 'submitting'} className="w-full text-start">
                            Archive club
                          </button>
                        </DropdownMenu.Item>
                      </>
                    )}
                  </>
                )}
              </DropdownMenu.Content>
            </DropdownMenu>
          </fetcher.Form>
        </div>
        <div className="absolute left-4 bottom-4 items-end rounded-xl flex space-x-2">
          {club.archived ? (
            <Pill variant="blur">
              <Pill.Value>
                Archived
              </Pill.Value>
            </Pill>
          ) : (
            <>
              <Pill variant="blur">
                <Pill.Value>
                  {club.clubBlockDayOfWeek} - {format(new Date(club.clubBlockTime), 'h:mm a')}
                </Pill.Value>
              </Pill>
              <Pill variant="blur">
                <Pill.Value>
                  {club.membersCount} members
                </Pill.Value>
              </Pill>
            </>
          )}
        </div>
      </figure>
      <Typography variant="subheadline" weight="bold" className="hover:underline mt-2 text-ellipsis whitespace-nowrap overflow-hidden max-w-72">{club.title}</Typography>
      <div className="flex items-center justify-start mt-1">
        {club.membersPreview && club.membersPreview.map(({ imageUrl, imageAlt, id }) => (
          <Avatar size="lg" key={id} className="-mr-3">
            <Avatar.Image src={imageUrl} alt={imageAlt} />
            <Avatar.Fallback>{imageAlt}</Avatar.Fallback>
          </Avatar>
        ))}
        {club.membersCount > 5 && (
          <Avatar size="lg" className="-mr-3">
            <Avatar.Fallback>+{club.membersCount - club.membersPreview.length}</Avatar.Fallback>
          </Avatar>
        )}
      </div>
    </article>
  )
}

interface FormButtonProps {
  value: string | number,
  name: string,
  actionName: string,
  label: string,
  confirmation?: boolean
}

function FormButton({ value, name, actionName, label, confirmation = false }: FormButtonProps) {
  const fetcher = useFetcher()
  return (
    <fetcher.Form method="POST" className="flex grow">
      <input type="hidden" name="_action" value={actionName} />
      {confirmation ? <input type="hidden" name="_confirm" value="false" /> : null}
      <DropdownMenu.Item asChild>
        <button
          type="submit"
          name={name}
          value={value}
          disabled={fetcher.state === 'submitting'}
          className="text-left w-full"
          onClick={(e) => {
            fetcher.submit(e.currentTarget, { method: fetcher.formMethod })
          }}
        >
          {label}
        </button>
      </DropdownMenu.Item>
    </fetcher.Form>
  )
}

export const ClubsRoute = {
  action,
  loader,
  Element,
}

