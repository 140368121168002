import { Button, Typography } from '@design-system'
import React, { useEffect, useState } from 'react'
import { useFetcher, useLocation, useNavigate } from 'react-router'

interface MarkReviewedBannerProps {
  startHidden?: boolean
  shouldProposeReview: boolean
}

export function MarkReviewedBanner({ startHidden = true, shouldProposeReview }: MarkReviewedBannerProps) {
  const fetcher = useFetcher()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [hidden, setHidden] = useState(startHidden)

  useEffect(() => {
    fetcher.data?.shouldRedirect && navigate('..')
  }, [fetcher.data?.shouldRedirect])

  if (!shouldProposeReview) return null

  const isLoading = fetcher.state !== 'idle'
  return (
    <li
      className={`p-4 bg-yellow-100 rounded-lg justify-start items-center gap-10 inline-flex ${hidden ? 'hidden' : ''}`}
      aria-hidden={hidden}
    >
      <div className="grow">
        <Typography variant="body" weight="bold">
          Have you finished this review?
        </Typography>
        <Typography variant="body">Mark the task as reviewed or missed to notify the student.</Typography>
      </div>

      <div className="justify-start items-center gap-2 flex">
        <fetcher.Form method="POST" className="space-x-2">
          <input type="hidden" name="current_location" value={pathname} />
          <Button size="sm" type="submit" name="_action" value="review_task" loading={isLoading && fetcher.formData.get('_action') === 'review_task'} aria-busy={isLoading && fetcher.formData.get('_action') === 'review_task'} disabled={isLoading && fetcher.formData.get('_action') !== 'review_task'}>
            Mark as reviewed
          </Button>
          <Button size="sm" type="submit" name="_action" value="miss_task" loading={isLoading && fetcher.formData.get('_action') === 'miss_task'} aria-busy={isLoading && fetcher.formData.get('_action') === 'miss_task'} color="danger" disabled={isLoading && fetcher.formData.get('_action') !== 'miss_task'}>
            Mark as missed
          </Button>
        </fetcher.Form>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setHidden(true)}
          aria-label="Dismiss review section"
          disabled={isLoading}
        >
          Dismiss
        </Button>
      </div>
    </li>
  )
}
