import * as React from 'react'
import { Button, Icon, unstable_Popover as Popover, Typography } from '@design-system'
import { TextField } from '@designsystem'
import SoraLink from '@components/link'
import { Link } from 'react-router'
import { CommentList, FilterPresetCommentArrayArgs, PresetComment } from './types'
import { useState } from 'react'
import { cloneDeep } from 'lodash'
import EmptyCommentsMascot from './empty-comments-mascot.svg'

interface CommentBankButtonProps {
  presetComments: CommentList
  onSelectComment: { (commentBody: Array<object>): void }
  trigger?: React.ReactNode
  sideOffset?: number
  alignOffset?: number
  linkTo: string
  emptyLinkTo: string
  className?: string
  disabled?: boolean
}

export function CommentBankButton({
  presetComments,
  onSelectComment,
  trigger,
  sideOffset,
  alignOffset,
  linkTo,
  emptyLinkTo,
  className,
  disabled = false
}: CommentBankButtonProps) {
  const [open, setOpen] = React.useState(false)
  const [searchInputValue, setSearchInputValue] = useState<string>('')


  const filteredPresetComments = filterPresetCommentArray({
    commentArray: presetComments,
    filterText: searchInputValue,
  })

  return (
    <Popover
      onOpenChange={(change) => {
        setOpen(change)
      }}
      open={open}
    >
      {!trigger ? (
        <Popover.Trigger asChild>
          <Button type="button" variant={open ? `contained` : `ghost`} color={open ? 'soft' : 'dark'} size="sm" disabled={disabled}>
            <Icon name={open ? 'comment-plus-filled' : 'comment-plus'} />
            Preset Comment
          </Button>
        </Popover.Trigger>
      ) : (
        trigger
      )}

      <Popover.Content
        sideOffset={sideOffset}
        alignOffset={alignOffset}
        className={className ?? 'flex flex-col gap-2 min-h-80 max-h-96 w-[30vw]'}
      >
        <div className="flex">
          <TextField
            placeholder="Search comment"
            fullWidth
            startAdornment={<Icon name="search" />}
            onChange={(event) => setSearchInputValue(event.target.value)}
            defaultValue={searchInputValue}
          />

          <SoraLink to={linkTo} as={Link}>
            <Button
              type="button"
              variant="ghost"
              onClick={() => {
                setOpen(false)
              }}
            >
              <Icon name="external-link-rec" />
            </Button>
          </SoraLink>
        </div>

        {filteredPresetComments
          .map((el) => (
            <div
              key={el.id}
              className="flex justify-between p-4 bg-stone-50 rounded-lg cursor-pointer hover:bg-stone-100 group"
              onClick={(_) => {
                onSelectComment(cloneDeep(el.body))
              }}
            >
              <Typography variant="body" weight="bold" className="truncate inline">
                {el.title} -{' '}
                <Typography variant="body" className="truncate inline">
                  {el.textBody}
                </Typography>
              </Typography>

              <Typography
                className="flex items-center gap-2 invisible group-hover:visible"
                variant="footnote"
                weight="bold"
              >
                Insert comment
              </Typography>
            </div>
          ))
          .slice(0, 4)}

        {(presetComments ?? []).length === 0 && (
          <div className="flex items-center gap-2 self-center">
            <img className="object-scale-down h-60" src={EmptyCommentsMascot} alt="No comments mascot" />
            <div className="flex flex-col gap-6">
              <Typography variant="body">You do not have any saved preset comments</Typography>
              <Button className="flex-none w-min" size="sm" type="button">
                <SoraLink to={emptyLinkTo} as={Link}>
                  Create new comment
                </SoraLink>
              </Button>
            </div>
          </div>
        )}
      </Popover.Content>
    </Popover>
  )
}

/**
 * Filters an array of comment objects based on a filter text.
 *
 * @param {FilterCommentArrayProps} params - The parameters for filtering the comment array.
 * @returns {Comment[]} The filtered array of comment objects.
 */
function filterPresetCommentArray({ commentArray, filterText }: FilterPresetCommentArrayArgs): PresetComment[] {
  if (!commentArray || !Array.isArray(commentArray) || typeof filterText !== 'string') {
    return []
  }

  const lowerCaseFilterText = filterText.toLowerCase()

  return commentArray.filter((comment) => {
    return comment.title.toLowerCase().includes(lowerCaseFilterText)
  })
}
