import axios from 'axios'
import React, { useRef } from 'react'
import { LoaderFunctionArgs, useLoaderData, Form, Await, useSubmit, useSearchParams, useNavigation } from 'react-router'
import { Icon, Button, unstable_Select as Select, Typography } from '@design-system'
import { TextField } from '@designsystem'
import { DataTable } from './data-table'

async function getStudents({ houseId, search, filterBy }) {
  const { data } = await axios.get(`/backoffice/houses/${houseId}/students`)
  return getFilteredValue({ filterBy, search, data })
}

function getFilteredValue({ filterBy, search, data }) {
  return data.filter(row => {
    if (filterBy === 'emptyRequestLists') {
      return row.requestListLowestCount === 0
    }
    return true
  }).filter(row => {
    if (search) {
      return row.studentName.toLowerCase().includes(search.toLowerCase())
    }
    return true
  })
}

async function getStudentsWithMetrics({ houseId, timeframe, students }) {
  const { data: metrics } = await axios.get(`/backoffice/houses/${houseId}/students?timeframe=${timeframe}&metrics=true`)
  const studentsWithMetrics = students.map(student => {
    const studentWithMetrics = {
      ...student,
      ...metrics[student.studentId],
    }
    return studentWithMetrics
  })
  return studentsWithMetrics
}

async function loader({ request, params }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const houseId = params.houseId
  const timeframe = searchParams.get('timeframe') || 'lastSevenDays'
  const search = searchParams.get('search') || ''
  const filterBy = searchParams.get('filterBy') || ''
  const students = await getStudents({ houseId, search, filterBy })
  return {
    students,
    studentsWithMetrics: getStudentsWithMetrics({ houseId, timeframe, students })
  }
}

function Element() {
  const data = useLoaderData()
  const submit = useSubmit()
  let [searchParams] = useSearchParams()
  const navigation = useNavigation()
  const formRef = useRef<HTMLFormElement>()

  const formData = navigation.formData
  const timeframe = String(formData?.get('timeframe') || searchParams.get('timeframe') || 'lastSevenDays')
  const filterBy = String(formData?.get('filterBy') || searchParams.get('filterBy') || 'all')
  const search = String(formData?.get('search') || searchParams.get('search') || '')

  function handleClearFilterBy(e) {
    e.preventDefault()
    formRef.current.elements['filterBy'].value = 'all'
    submit(formRef.current)
  }

  return (
    <>
      <Form ref={formRef} method="GET" onChange={e => submit(e.currentTarget)} className="flex flex-col md:flex-row gap-2">
        <div className="w-full md:max-w-xs">
          <TextField
            startAdornment={<Icon name="search" size="sm" />}
            placeholder="Search for student"
            name='search'
            defaultValue={search}
            fullWidth
            autoFocus
          />
        </div>
        <Select key={filterBy} name="filterBy" defaultValue={filterBy}>
          <Select.Trigger className="md:max-w-xs w-full">
            <Select.Value placeholder="Filter by">
              <Typography color="secondary">Filter by</Typography>
            </Select.Value>
          </Select.Trigger>
          <Select.Content>
            <Select.Item value="all">All</Select.Item>
            <Select.Item value="emptyRequestLists">Empty Request List</Select.Item>
          </Select.Content>
        </Select>
        <div className="md:max-w-xs w-full">
          <Select name="timeframe" defaultValue={timeframe}>
            <Select.Trigger className="w-full">
              <Select.Value placeholder={`Attendance, Tasks: ${timeframe === 'currentCycle' ? 'Current Cycle' : 'Last 7 Days'}`}>
                <div className="flex gap-1.5">
                  <span>
                    Attendance, Tasks: {timeframe === 'currentCycle' ? 'Current Cycle' : 'Last 7 Days'}
                  </span>
                </div>
              </Select.Value>
            </Select.Trigger>
            <Select.Content>
              <Select.Group>
                <Select.Label>Timeframe for Attendance and Tasks</Select.Label>
                <Select.Item value="lastSevenDays">Last 7 Days</Select.Item>
                <Select.Item value="currentCycle">Current Cycle</Select.Item>
              </Select.Group>
            </Select.Content>
          </Select>
        </div>
      </Form>
      {filterBy === 'emptyRequestLists' && (
        <div>
          <Button onClick={handleClearFilterBy}>
            Empty Request List
            <Icon name="cross" />
          </Button>
        </div>
      )}
      <React.Suspense fallback={<DataTable data={data.students} />}>
        <Await
          resolve={data.studentsWithMetrics}
          errorElement={
            <p>Error loading students!</p>
          }
        >
          {(studentsWithMetrics) => (
            <DataTable data={studentsWithMetrics} />
          )}
        </Await>
      </React.Suspense>
    </>
  )
}

export const HouseStudentsRoute = {
  loader,
  Element,
}


