import React from 'react'
import { Button, Icon, useFetcher, unstable_Tooltip as Tooltip } from '@design-system'
import { ReportItem } from './types'

export const NavActions = ({ id, doneAt, dismissedAt }: Pick<ReportItem, 'id' | 'doneAt' | 'dismissedAt'>) => {
  const fetcher = useFetcher()
  return (
    <fetcher.Form method="POST" className="flex gap-2">
      <input type="hidden" name="houseReportItemId" value={id} />
      {!(doneAt || dismissedAt) ? (
        <>
          <Button type="submit" name="_action" value="dismiss" loading={fetcher.state !== 'idle' && fetcher.formData.get('_action') === 'dismiss'} variant="ghost" color="soft" size="sm" className="hidden md:group-hover:inline-block">Dismiss</Button>
          <Button type="submit" name="_action" value="markAsDone" loading={fetcher.state !== 'idle' && fetcher.formData.get('_action') === 'markAsDone'} variant="outlined" color="soft" size="sm">Mark as done</Button>
          <Button type="submit" name="_action" value="dismiss" loading={fetcher.state !== 'idle' && fetcher.formData.get('_action') === 'dismiss'} variant="outlined" color="soft" size="sm" className="md:hidden">Dismiss</Button>
        </>
      ) : (
        <Tooltip content="Undo">
          <Button type="submit" name="_action" value="undo" loading={fetcher.state !== 'idle' && fetcher.formData.get('_action') === 'undo'} variant="outlined" color="soft">
            <Icon name="check" />
          </Button>
        </Tooltip>
      )}
    </fetcher.Form>
  )
}
