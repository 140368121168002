import React from 'react'
import axios from 'axios'
import { Form, LoaderFunctionArgs, Outlet, useLoaderData, useSearchParams, useSubmit } from 'react-router'
import SoraLink from '@components/link'
import { useThisPageTitle } from '@hooks/usePageTitle'
import { Typography, unstable_Select as Select, Card, Icon, Pill } from '@design-system'
import format from 'date-fns-tz/format'

type LoaderData = {
  currentSchoolYear: string,
  schoolYears: Array<{ label: string, value: string }>,
  cycles: Array<{
    id: number,
    title: string,
    endTimestamp: string,
    startTimestamp: string,
    active: boolean,
    pills: Array<{ active: boolean, label: string }>,
  }>,
}

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const res = await axios.get(`/backoffice/cycles?${searchParams.toString()}`)
  return res.data
}

function Element() {
  useThisPageTitle('Cycle Management')
  const submit = useSubmit()
  const [searchParams] = useSearchParams()
  const { cycles, schoolYears, currentSchoolYear } = useLoaderData() as LoaderData
  const selectedSchoolYear = searchParams.get('schoolYear') || currentSchoolYear

  return (
    <div className="flex flex-col gap-8">
      <Typography variant="heading-3" weight="bold" className="mb-6">Cycles</Typography>
      <Form onChange={(e) => submit(e.currentTarget)} method="GET">
        <Typography asChild>
          <label htmlFor="schoolYear">School Year</label>
        </Typography>
        <Select name="schoolYear" defaultValue={selectedSchoolYear}>
          <Select.Trigger className="w-64">
            <Select.Value placeholder="Select a school year" />
          </Select.Trigger>
          <Select.Content>
            {schoolYears.map(({ label, value }) => (
              <Select.Item key={value} value={value}>{label}</Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Form>
      <div className="grid lg:grid-cols-3 gap-4">
        {cycles.map((cycle) => (
          <CycleCard key={cycle.id} cycle={cycle} />
        ))}
      </div>
      <Outlet />
    </div>
  )
}
type CycleCardProps = {
  cycle: Pick<LoaderData, 'cycles'>['cycles'][0]
}

function CycleCard({ cycle }: CycleCardProps) {
  const [searchParams] = useSearchParams()
  return (
    <Card asChild>
      <SoraLink to={{ pathname: `./${cycle.id}/edit`, search: searchParams.toString() }} className={cycle.active ? 'shadow-xl border-2 border-green-30' : ''}>
        <Card.Content className="gap-4 flex flex-col">
          <div className="flex flex-col gap-2">
            <Typography variant="heading-6" weight="bold">
              {cycle.title}
            </Typography>
            <div className="flex gap-2">
              <Icon name="calendar-arrow-right" />
              <Typography>{format(new Date(cycle.startTimestamp), "MMM, dd - yyyy (h:mma)")}</Typography>
            </div>
            <div className="flex gap-2">
              <Icon name="calendar-cross" />
              <Typography>{format(new Date(cycle.endTimestamp), "MMM, dd - yyyy (h:mma)")}</Typography>
            </div>
          </div>
          <div className="h-[1px] w-full bg-gray-30"></div>
          <CycleCardPillMapper pills={cycle.pills} />
        </Card.Content>
      </SoraLink>
    </Card>
  )
}

type CycleCardPillMapperProps = {
  pills: Pick<LoaderData, 'cycles'>['cycles'][0]['pills'],
}

function CycleCardPillMapper({ pills }: CycleCardPillMapperProps) {
  return (
    <div className="flex flex-wrap gap-2">
      {pills.map((pill) => (
        <Pill key={pill.label} color={pill.active ? 'success-30' : 'gray-10'} disabled={!pill.active}>
          <Pill.Value>{pill.label}</Pill.Value>
        </Pill>
      ))}
    </div>
  )
}

export const CyclesRoute = {
  loader,
  Element
}
