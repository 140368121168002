import React, { PropsWithChildren } from 'react'
import axios from 'axios'
import { ActionFunctionArgs, Form, useLoaderData, useSubmit } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { Alert, cn, Typography, Combobox, useFetcher, SwitchCard } from '@design-system'
import { format } from 'date-fns'

interface EmployeeStudentLearningAvailabilityData {
  hasEditPermission: boolean
  timezone: {
    label: string
    value: string
    acronym: string
  },
  timezoneOptions: {
    label: string
    value: string
    acronym: string
  }[],
  cycle: {
    id: number
    title: string
    startAt: string
    endAt: string
    canEdit: boolean
  },
  calendar: {
    title: string
    shortTitle: string
    schedules: {
      dayOfTheWeek: number
      title: string
      start: string
      end: string
      isAvailable: boolean
      learningBlockId: number
    }[]
  }[]
}

export async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/learning-availability?${searchParams.toString()}`)
  return data
}

async function action({ request, params }: ActionFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/employee/students/${params.studentId}/learning-availability?${searchParams.toString()}`, serializeFormData(formData))
    .catch(error => {
      return {
        data: {
          toast: {
            appearance: 'error',
            message: error.response.data.error.message
          },
          errors: error.response.data.errors,
        },
      }
    })
  return data
}

function Element() {
  const { hasEditPermission, timezone, timezoneOptions, cycle, calendar } = useLoaderData() as EmployeeStudentLearningAvailabilityData
  const submit = useSubmit()
  return (
    <div className="space-y-8">
      <div className="spacey-y-2">
        <Typography variant="heading-6" weight="bold">Learning Availability for {cycle.title}</Typography>
        <Typography variant="callout">
          {format(new Date(cycle.startAt), 'MMM, d - yyyy')} | {format(new Date(cycle.endAt), 'MMM, d - yyyy')}
        </Typography>
      </div>
      <p>Use this space to review students' learning availability with families.</p>
      <div className="space-y-1">
        <p><strong>Please note:</strong></p>
        <ul className="list-disc ml-4">
          <li>Each cycle may have its own availability, and any changes must be manually applied to all applicable cycles.</li>
          <li>Sora observes daylight saving time. If the family resides in a state that is <strong>not affected</strong> by it, this should be considered during the review.</li>
        </ul>
      </div>
      {hasEditPermission && !cycle.canEdit && (
        <Alert variant="notice">
          <Alert.Title>Schedules for the {cycle.title} were already released to students, select future cycles to update student's availability.</Alert.Title>
        </Alert>
      )}
      <Form method="GET" onChange={e => submit(e.currentTarget)} className="flex">
        <input type="hidden" name="cycle_id" value={cycle.id} />
        <Combobox required name="timezone" defaultValue={timezone.value}>
          {timezoneOptions.map(tz => (
            <Combobox.Item key={tz.value} value={tz.value}>{tz.label}</Combobox.Item>
          ))}
        </Combobox>
      </Form>
      <section className="bg-white dark:bg-gray-100 border border-gray-20 dark:border-gray-90 rounded-2xl p-4 grid grid-cols-2 lg:grid-cols-5 gap-4">
        {calendar.map((weekday, index) => (
          <article className={cn("flex-col gap-4 hidden lg:flex", index > 1 ? "hidden" : "flex")} key={weekday.title}>
            <header className="mb-2 text-center">
              <Typography weight="bold" className="uppercase">
                <span className="hidden lg:block">{weekday.title}</span>
                <span className="lg:hidden">{weekday.shortTitle} / {calendar[index + 3]?.shortTitle}</span>
              </Typography>
            </header>
            {weekday.schedules.map(schedule => (
              <ToggleSchedulesButton
                key={`${schedule.dayOfTheWeek}-${schedule.learningBlockId}`}
                defaultValue={schedule.isAvailable}
                learningBlockId={schedule.learningBlockId}
                disabled={!cycle.canEdit}
                hasEditPermission={hasEditPermission}
              >
                <input type="hidden" name="cycle_id" value={cycle.id} />
                <Typography variant="callout">{schedule.title}</Typography>
                <Typography variant="callout" weight="bold">{`${schedule.start} - ${schedule.end} (${timezone.acronym})`}</Typography>
              </ToggleSchedulesButton>
            ))}
            {weekday.schedules.length === 0 && (
              <div className="bg-gray-10 dark:bg-gray-95 p-3 rounded-sm flex justify-center items-center text-center whitespace-pre-line grow">
                {`School club meetings \nand open times`}
              </div>
            )}
          </article>
        ))}
      </section>
    </div>
  )
}

interface ToggleCardProps extends PropsWithChildren {
  defaultValue: boolean
  learningBlockId: number
  disabled: boolean
  hasEditPermission: boolean
}

const ToggleSchedulesButton = ({ children, defaultValue, learningBlockId, hasEditPermission, disabled }: ToggleCardProps) => {
  const fetcher = useFetcher()
  const value = fetcher.state === 'idle' ? defaultValue : !defaultValue
  return (
    <fetcher.Form method="POST">
      <SwitchCard type="submit" name="learningBlockId" value={learningBlockId} defaultValue={value} disabled={disabled || !hasEditPermission} viewOnly={!hasEditPermission}>
        <div>
          {children}
        </div>
      </SwitchCard>
    </fetcher.Form>
  )
}

export const EmployeeStudentLearningAvailabilityRoute = {
  Element,
  loader,
  action,
}


