import axios from 'axios'
import React, { useEffect } from 'react'

import { unstable_Table as Table, Button, useFetcher, FetcherForm } from '@design-system'
import { LoaderFunctionArgs, useLoaderData, useRevalidator, useSearchParams } from 'react-router'
import SoraLink from '@components/link'

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/workbench/zoom?${searchParams.toString()}`)
  return data
}

async function action({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/workbench/zoom?${searchParams.toString()}`, formData)
  return data
}

function Element() {
  const loaderData = useLoaderData()
  const fetcher = useFetcher()
  const [searchParams] = useSearchParams()
  const revalidator = useRevalidator()

  const hasPendingJobsAt = loaderData.hasPendingJobsAt
  const themes = loaderData.themes

  useEffect(() => {
    let timeoutId = null
    if (hasPendingJobsAt) {
      timeoutId = setTimeout(() => {
        revalidator.revalidate()
      }, 1000)
    }
    return () => timeoutId && clearTimeout(timeoutId)
  }, [hasPendingJobsAt])

  return (
    <div>
      <div className="flex justify-end pb-4">
        <fetcher.Form method="POST">
          <input type="hidden" name="cycleId" value={searchParams.get('cycle_id')} />
          <Button type="submit" name="_action" value="pull-cycle-conference-issues" loading={hasPendingJobsAt!!}>
            Pull all conferences issues
          </Button>
        </fetcher.Form>
      </div>
      <Table className="table-fixed border-separate w-full border border-gray-30 rounded-lg">
        <Table.Header className="bg-white dark:bg-gray-100 dark:border-gray-90">
          <Table.Row>
            <Table.Head className="first:pl-4">Experience</Table.Head>
            <Table.Head className="">Main Expert</Table.Head>
            <Table.Head className="">Conference URL</Table.Head>
            <Table.Head className="">Issues</Table.Head>
            <Table.Head className="">Action</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&_tr:last-child]:border-1">
          {themes.map((theme) => (
            <Table.Row key={theme.id} className="last-of-type:rounded-b-md">
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <SoraLink to={`/experiences/${theme.id}/edit`}>
                  {theme.title}
                </SoraLink>
              </Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                {theme.main_expert_name}
              </Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                {theme.conference_url}
              </Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                {theme.isPulling
                  ? <span className="text-red-500">Pulling...</span>
                  : <ul>
                    {theme.issues?.map(issue =>
                      <li key={issue}>{issue}</li>
                    ) || '-'}
                  </ul>
                }
              </Table.Cell>
              <Table.Cell className="first:pl-4 border-t border-t-gray-30 dark:border-t-gray-90">
                <FetcherForm method="POST">
                  {
                    ({ fetcher }) => <>
                      <input type="hidden" name="experienceId" value={theme.id} />
                      <Button type="submit" name="_action" size='sm' value="sync-experience-conference" loading={fetcher.state !== 'idle'}>
                        Force Sync
                      </Button>
                    </>
                  }
                </FetcherForm>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

export const WorkbenchZoomRoute = {
  loader,
  action,
  Element,
}


