import React from 'react'

import { Outlet, Navigate } from 'react-router'
import useCurrentUser from "@hooks/useCurrentUser"

export default function EmployeeRoute() {
  const currentUser = useCurrentUser()

  if (currentUser === undefined)
    return null

  if (!currentUser || currentUser?.role === 'student' || currentUser?.role === 'guardian') {
    return <Navigate to='/' />
  }

  return (
    <Outlet />
  )
}