import SoraLink from '@components/link'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLoaderData, ActionFunctionArgs, useOutletContext, useSearchParams, Outlet, useSubmit, Form } from 'react-router'
import {
  Button,
  DropdownMenu,
  Icon,
  Typography,
  Dialog,
  unstable_Checkbox as Checkbox,
  useFetcher,
  EmptyState,
} from '@design-system'
import { TextField } from '@designsystem'
import { FilterPresetCommentArrayArgs, LoaderResponse, PresetComment } from './types'
import { cloneDeep } from 'lodash'
import SlateTextarea, { BlockButton, LinkButton, MarkButton } from '@components/forms/slate-textarea'
import EmptyCommentsMascot from './empty-comments-mascot.svg'

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const result = await axios.get(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank?${searchParams.toString()}`
  )
  return result.data
}

async function action({ request, params }: ActionFunctionArgs) {
  const formData = await request.formData()
  const res = await axios.post(
    `/backoffice/facilitate/experiences/${params.experienceId}/tasks/${params.taskId}/students/${params.studentId}/comment-bank`,
    formData
  )
  return { status: res.status, ...res.data }
}

function Element() {
  const loaderData = useLoaderData() as LoaderResponse
  const submit = useSubmit()
  const [setSlateCurrentValue] = useOutletContext<Array<any>>()

  return (
    <div className="flex flex-col w-[460px] overflow-hidden bg-white border-x">
      <header className="flex flex-row justify-between pt-4 px-6 items-center">
        <Typography variant="heading-5" weight="bold">
          Comments
        </Typography>
        <SoraLink className="flex-none" to={'..'} as={Link}>
          <Button variant="outlined" color="soft" size="xs">
            <Icon name="cross" />
          </Button>
        </SoraLink>
      </header>
      <Form onChange={e => submit(e.currentTarget)} className="flex flex-row justify-between pt-4 px-6 items-center">
        <TextField
          name="search"
          placeholder="Search comment"
          fullWidth
          startAdornment={<Icon name="search" />}
        />
      </Form>
      <div className="flex flex-col flex-1 overflow-auto pt-6 px-6 border-b border-neutral-200 gap-2">
        {loaderData.experience.length > 0 && (
          <div>
            <Typography variant="subheadline" weight="bold">
              Expedition comments
            </Typography>
            <Typography variant="callout">Comments created for this expedition</Typography>
          </div>
        )}
        {loaderData.experience.map((el) => (
          <div
            key={el.id}
            className="flex p-4 bg-stone-50 rounded-lg items-center justify-between hover:bg-stone-100"
          >
            <div
              className="flex-col w-full truncate pr-4 cursor-pointer"
              onClick={(_) => {
                setSlateCurrentValue(cloneDeep(el.body))
              }}>
              <Typography variant="body" weight="bold" className="truncate">
                {el.title}
              </Typography>

              <Typography variant="body" className="truncate">
                {el.textBody}
              </Typography>
            </div>
            <CommentDropdownMenu commentId={el.id} title={el.title} body={el.body} type={el.type} />
          </div>
        ))}

        {loaderData.general.length > 0 && (
          <div className="pt-4">
            <Typography variant="subheadline" weight="bold">
              General comments
            </Typography>
            <Typography variant="callout">Comments you've created and are available to all your expeditions</Typography>
          </div>
        )}

        {loaderData.general.map((el) => (
          <div
            key={el.id}
            className="flex p-4 bg-stone-50 rounded-lg items-center justify-between hover:bg-stone-100"
          >
            <div
              className="flex-col w-full truncate pr-4 cursor-pointer"
              onClick={(_) => {
                setSlateCurrentValue(cloneDeep(el.body))
              }}>
              <Typography variant="body" weight="bold" className="truncate">
                {el.title}
              </Typography>

              <Typography variant="body" className="truncate">
                {el.textBody}
              </Typography>
            </div>
            <CommentDropdownMenu commentId={el.id} title={el.title} body={el.body} type={el.type} />
          </div>
        ))}

        {loaderData.experience.length === 0 && loaderData.general.length === 0 && (
          <EmptyState direction="column">
            <EmptyState.Illustration>
              <img src={EmptyCommentsMascot} alt="No comments mascot" />
            </EmptyState.Illustration>
            <EmptyState.Title>No saved comments</EmptyState.Title>
            <EmptyState.Description>You don't have any saved comments</EmptyState.Description>
          </EmptyState>
        )}
        <Outlet />
      </div>
      <footer className="flex justify-center">
        <Button size="lg" className="m-6" variant="outlined" asChild>
          <SoraLink to={'./preset-comment/create'} as={Link}>
            <Icon name="plus"></Icon>
            Create new comment
          </SoraLink>
        </Button>
      </footer>
    </div>
  )
}

interface CommentDropdownMenuProps {
  commentId: number
  title: string
  body: Array<any>
  type: 'user' | 'experience'
}

function CommentDropdownMenu({ commentId, title, body, type }: CommentDropdownMenuProps) {
  const fetcher = useFetcher()
  const [dialogOpen, setDialogOpen] = useState(false)

  useEffect(() => {
    if (fetcher.data?.status === 200 && fetcher.formData.get('_action') === 'edit_preset_comment') {
      setDialogOpen(false)
    }
  }, [fetcher.data])

  return (
    <>
      <DropdownMenu>
        <DropdownMenu.Trigger asChild>
          <Button className={`self-center`} variant="ghost" size="sm" color="soft">
            <Icon name="more-vert-filled" />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content>
          <DropdownMenu.Item className="hover:bg-neutral-100 flex items-center gap-2" asChild>
            <Link to={`./preset-comment/${commentId}`}>
              <Icon name="edit-3" />
              <Typography variant="callout">Edit Comment</Typography>
            </Link>
          </DropdownMenu.Item>

          <fetcher.Form method="POST" id="deleteCommentFromBankForm">
            <input type="hidden" name="commentId" value={commentId} />
            <DropdownMenu.Item className="text-danger hover:bg-neutral-100">
              <button
                className="flex items-center gap-2"
                type="submit"
                name="_action"
                value="delete_preset_comment"
                form="deleteCommentFromBankForm"
              >
                <Icon name="trash-2" color="danger" />
                <Typography variant="callout" color="danger">
                  Delete Comment
                </Typography>
              </button>
            </DropdownMenu.Item>
          </fetcher.Form>
        </DropdownMenu.Content>
      </DropdownMenu>
    </>
  )
}

/**
 * Filters an array of comment objects based on a filter text.
 *
 * @param {FilterPresetCommentArrayArgs} params - The parameters for filtering the comment array.
 * @returns {Comment[]} The filtered array of comment objects.
 */
function filterPresetCommentArray({ commentArray, filterText }: FilterPresetCommentArrayArgs): PresetComment[] {
  if (!commentArray || !Array.isArray(commentArray) || typeof filterText !== 'string') {
    return []
  }

  const lowerCaseFilterText = filterText.toLowerCase()

  return commentArray.filter((comment) => {
    return comment.title.toLowerCase().includes(lowerCaseFilterText)
  })
}

export const FacilitateExperienceTaskStudentCommentBankRoute = {
  loader,
  action,
  Element,
}
