import React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData } from 'react-router'
import { SoraNavLink } from '@components/link'
import { Typography, unstable_Tooltip as Tooltip } from '@design-system'

export async function loader() {
  const result = await axios.get('/backoffice/reports/index')
  return result.data
}

function Element() {
  const { permissions } = useLoaderData() as any
  return (
    <div className="flex grow gap-6 max-w-full h-full">
      <div className="space-y-3 border-x px-3 h-full border-x-gray-30">
        <Typography weight="bold" variant="heading-6">Reports</Typography>
        <ul className='h-full text-nowrap'>
          <SidebarOpt url="students/requests" label="Students' Requests" />
          <SidebarOpt url="students/missing/units" label="Student Missing Units" />
          <SidebarOpt url="students/availability" label="Student Availability" />
          <SidebarOpt url="registration-changes" label="Registration Changes" />
          <SidebarOpt url="experiences-roster" label="Experiences Roster" />
          <SidebarOpt url="units-completion" label="Units Completion" />
          <SidebarOpt url="materials" label="Materials" />
          {permissions.leadership_dashboard &&
            <SidebarOpt url="task-responses" label="Task Responses" />
          }
          <SidebarOpt url="master-schedules/experts/hs" label="Expert M. Schedules (HS)" />
          <SidebarOpt url="master-schedules/experts/ms" label="Expert M. Schedules (MS)" />
          <SidebarOpt url="master-schedules/students/hs" label="Student M. Schedules (HS)" />
          <SidebarOpt url="master-schedules/students/ms" label="Student M. Schedules (MS)" />
        </ul>
      </div>
      <div className="flex flex-col grow max-w-full relative">
        <div className='absolute top-0 left-0 right-0 bottom-0 z-50 justify-center items-center overflow-auto'>
          <React.Suspense fallback="Loading...">
            <Outlet />
          </React.Suspense>
        </div>
      </div>
    </div>
  )
}

interface SidebarOptProps {
  url: string,
  label: string,
}

function SidebarOpt({ url, label }: SidebarOptProps) {
  return (
    <li>
      <Tooltip content={label}>
        <SoraNavLink to={url} className={(active) => `hover:underline text-ellipsis line-clamp-1 ${active ? 'font-bold underline' : ''}`}>
          {label}
        </SoraNavLink>
      </Tooltip>
    </li>
  )
}

export const ReportsRoute = { Element, loader }
