import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import Select from 'react-select'
import { useLoaderData, useRevalidator, useSearchParams } from 'react-router'
import useSWR from 'swr'
import addHours from 'date-fns/addHours'
import format from 'date-fns/format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Disclosure, Tab } from '@headlessui/react'
import useToggle from '@hooks/useToggle'
import useCurrentUser from '@hooks/useCurrentUser'
import AttendanceDetailsModal from '@components/attendance/faculty/attendanceDetailsModal'
import ExcuseSubmissionModal from '@components/attendance/excuseSubmissionModal'
import ExcuseDetailsModal from '@components/attendance/excuseDetailsModal'
import StudentsMissesInDay from '@components/attendance/faculty/studentsMissesInDay'
import AttendanceMetrics from '@components/attendance/AttendanceMetrics'
import ExcusesCalendar from '@components/attendance/excusesCalendar'
import StudentLearningExperiencesAttendanceList from '@components/attendance/StudentLearningExperiencesAttendanceList'
import { Button } from '@designsystem'

export async function loader({ request }) {
  const searchParams = new URL(request.url).searchParams
  const requestParams = new URLSearchParams()
  if (searchParams.get('cycle_id')) requestParams.append('cycle_id', searchParams.get('cycle_id'))
  if (searchParams.get('student_id')) requestParams.append('student_id', searchParams.get('student_id'))
  const { data } = await axios.get(`/pages/attendance/faculty?${searchParams.toString()}`)
  return data
}

export default function AttendanceFaculty() {
  const [searchParams] = useSearchParams()
  const currentUser = useCurrentUser()
  const [selectedTab, setSelectedTab] = useState(TAB_INDEX[0])
  const [selectedHouse, setSelectedHouse] = useState(null)
  const [selectedExperience, setSelectedExperience] = useState(null)
  const [selectedStudent, setSelectedStudent] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedExcuse, setSelectedExcuse] = useState(null)
  const [attendanceDetailsModalOpen, setAttendanceDetailsModalOpen] = useToggle(false)
  const [excuseSubmissionModalOpen, setExcuseSubmissionModalOpen] = useToggle(false)
  const [excuseDetailsModalOpen, setExcuseDetailsModalOpen] = useToggle(false)

  const loaderData = useLoaderData()
  const revalidator = useRevalidator()
  const experiences = loaderData.themes
  const houses = loaderData.houses
  const activeStudents = loaderData.activeStudents
  const selectedStudentData = loaderData.selectedStudentData
  const selectedCycleId = loaderData.selectedCycleId

  useEffect(() => {
    if (selectedStudentData) setSelectedStudent({ value: selectedStudentData.id, label: selectedStudentData.name })
  }, [])

  const openAttendanceDetailsModal = (selectedPeriod) => {
    const { start = new Date() } = selectedPeriod
    const startYMD = format(new Date(start), 'yyyy-MM-dd')
    if (attendances[startYMD]) {
      setSelectedDate(start)
      setAttendanceDetailsModalOpen(true)
    }
  }

  const openExcuseDetailsModal = (event) => {
    if (event.event_type === 'excuse') {
      setSelectedExcuse(event)
      setExcuseDetailsModalOpen(true)
    }
  }

  const openExcuseSubmissionModal = (excuse = null) => {
    if (!excuse) excuse = { start_at: new Date(), end_at: addHours(new Date(), 1) }
    setSelectedExcuse(excuse)
    setExcuseSubmissionModalOpen(true)
  }

  const { data: studentAttendanceData, mutate: mutateStudentAttendanceData } = useSWR(() => selectedTab === 'all' && `/pages/attendance/metrics?cycle_id=${selectedCycleId}&student_id=${selectedStudent.value}`)
  const isStudentAttendanceDataLoading = !studentAttendanceData && selectedTab === 'all' && selectedStudent
  const { data: experienceAttendanceData, mutate: mutateExperienceAttendanceData } = useSWR(() => selectedTab === 'sessions' && `/pages/attendance/experienceMetrics?cycle_id=${selectedCycleId}&experience_id=${selectedExperience.value}`)
  const isExperienceAttendanceDataLoading = !experienceAttendanceData && selectedTab === 'sessions' && selectedExperience
  const { data: houseAttendanceData, mutate: mutateHouseAttendanceDataData } = useSWR(() => selectedTab === 'houses' && `/pages/attendance/houseEventsMetrics?cycle_id=${selectedCycleId}&house_id=${selectedHouse.value}`)
  const isHouseAttendanceDataLoading = !houseAttendanceData && selectedTab === 'houses' && selectedHouse

  const sessions = studentAttendanceData?.sessions || experienceAttendanceData?.experienceSessions || houseAttendanceData?.houseEventsSessions || []
  const attendances = studentAttendanceData?.attendances || experienceAttendanceData?.experienceAttendances || houseAttendanceData?.houseEventsAttendances || {}
  const excuses = studentAttendanceData?.excuses || []
  const cycleSessionAttendances = studentAttendanceData?.sessionMetrics || experienceAttendanceData?.experienceMetrics || houseAttendanceData?.houseEventMetrics || null
  const attendanceMetrics = studentAttendanceData?.attendanceMetrics || null
  const cycleEventMetrics = experienceAttendanceData?.experiencMetrics || houseAttendanceData?.houseEventMetrics || null

  const isLoading = !!(isStudentAttendanceDataLoading || isExperienceAttendanceDataLoading || isHouseAttendanceDataLoading)

  const setHasEdittedStudentAttendances = () => {
    revalidator.revalidate()
    mutateStudentAttendanceData()
    mutateExperienceAttendanceData()
    mutateHouseAttendanceDataData()
  }

  return <>
    <div className="grid grid-cols-12 gap-5 min-w-screen-lg">
      <div className="col-span-9">
        <ExcusesCalendar
          onSelectEvent={openExcuseDetailsModal}
          onSelectSlot={openAttendanceDetailsModal}
          showAbsenceBtn={false}
          excuses={excuses}
          sessions={sessions}
          attendances={attendances}
          isLoading={!!isLoading}
          defaultDate={searchParams.get('date') ?? new Date()}
        />
      </div>
      <div className="col-span-3">
        <Tab.Group onChange={(i) => { setSelectedTab(TAB_INDEX[i]) }}>
          <Tab.List className="flex p-1 space-x-1 rounded-xl bg-gray-10">
            <Tab key="Students"
              className={({ selected }) => `w-full py-1 px-2 text-xs lg:text-sm truncate font-medium text-gray-70 rounded-xl focus:outline-none ${selected ? 'bg-white shadow font-bold' : 'hover:bg-white/[0.12] hover:font-bold'}`}
            >
              ALL
            </Tab>
            <Tab key="Experiences"
              className={({ selected }) => `w-full py-1 px-2 text-xs lg:text-sm truncate font-medium text-gray-70 rounded-xl focus:outline-none ${selected ? 'bg-white shadow font-bold' : 'hover:bg-white/[0.12] hover:font-bold'}`}
            >
              EXPERIENCES
            </Tab>
            <Tab key="Houses"
              className={({ selected }) => `w-full py-1 px-2 text-xs lg:text-sm truncate font-medium text-gray-70 rounded-xl focus:outline-none ${selected ? 'bg-white shadow font-bold' : 'hover:bg-white/[0.12] hover:font-bold'}`}
            >
              HOUSES
            </Tab>
          </Tab.List>
          <div className="border my-4" />
          <Tab.Panels>
            <Tab.Panel>
              <AllView selectedStudent={selectedStudent} setSelectedStudent={setSelectedStudent} isLoading={isLoading} cycleSessionAttendances={cycleSessionAttendances} attendanceMetrics={attendanceMetrics} activeStudents={activeStudents} onClickAddAbsence={openExcuseSubmissionModal} />
              {!selectedStudent && <StudentsMissesInDay setSelectedStudent={setSelectedStudent} attType="all" />}
            </Tab.Panel>
            <Tab.Panel>
              <ExperiencesView allExperiences={experiences} setSelectedExperience={setSelectedExperience} selectedExperience={selectedExperience} isLoading={isLoading} cycleEventMetrics={cycleEventMetrics} />
              {!selectedExperience && <StudentsMissesInDay attType="session" />}
            </Tab.Panel>
            <Tab.Panel>
              <HousesView allHouses={houses} setSelectedHouse={setSelectedHouse} selectedHouse={selectedHouse} isLoading={isLoading} cycleEventMetrics={cycleEventMetrics} />
              {!selectedHouse && <StudentsMissesInDay attType="houseEvent" />}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
    {
      excuseDetailsModalOpen &&
      <ExcuseDetailsModal
        excuse={selectedExcuse}
        onClose={setExcuseDetailsModalOpen}
        onEdit={(excuse) => { setExcuseDetailsModalOpen(false); openExcuseSubmissionModal(excuse) }}
        onRemove={() => {
          setExcuseDetailsModalOpen(false)
          setHasEdittedStudentAttendances()
        }}
      />
    }
    {
      attendanceDetailsModalOpen &&
      <AttendanceDetailsModal
        selectedDate={selectedDate}
        attendances={attendances}
        onClose={setAttendanceDetailsModalOpen}
        onEdit={setHasEdittedStudentAttendances}
        view={selectedTab}
      />
    }
    {
      excuseSubmissionModalOpen &&
      <ExcuseSubmissionModal
        isAdmin={currentUser?.role === 'admin'}
        students={activeStudents}
        excusePeriod={selectedExcuse}
        selectedStudent={selectedStudent}
        onClose={setExcuseSubmissionModalOpen}
        onSave={setHasEdittedStudentAttendances}
      />
    }
  </>
}


const TAB_INDEX = [
  'all',
  'sessions',
  'houses'
]

const StudentsList = ({ students, noStudentMsg }) => (
  <Disclosure>
    {({ open }) => (
      <>
        <Disclosure.Button className="w-full focus:outline-none">
          <div className="flex items-center justify-between text-gray-70 rounded-lg">
            <span className="text-sm">Student List</span>
            <FontAwesomeIcon icon={faChevronDown} className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-gray-70`} />
          </div>
          {!open && <div className='mt-2 border w-full' />}
        </Disclosure.Button>
        <Disclosure.Panel as="div" className="max-h-80 overflow-y-auto">
          {
            students?.length ?
              <div className="space-y-2 flex flex-col text-gray-70">
                {
                  students.map((student, i) => (
                    <span key={i} className="text-xs lg:text-sm md:ml-3 truncate border-b w-10/12">{student.name}</span>
                  ))
                }
              </div>
              : <span className="text-xs lg:text-base">{noStudentMsg}</span>
          }
        </Disclosure.Panel>
      </>
    )}
  </Disclosure>
)
StudentsList.propTypes = {
  students: PropTypes.array,
  noStudentMsg: PropTypes.string,
}

const AllView = ({ isLoading, selectedStudent, setSelectedStudent, attendanceMetrics, cycleSessionAttendances, onClickAddAbsence, activeStudents }) => {
  const studentsOpts = activeStudents.map((s => ({ value: s.id, label: s.name }))) || []
  const { expeditions } = cycleSessionAttendances || {}

  if (isLoading) {
    return <div className="w-full flex justify-center mt-5">
      <FontAwesomeIcon icon={faSpinner} className="text-blue-70 text-5xl self-center" spin />
    </div>
  }

  const student = selectedStudent ? { id: selectedStudent.value, name: selectedStudent.label } : {}
  return (
    <>
      <Select
        placeholder="Select a student"
        className="w-full text-sm"
        options={studentsOpts}
        value={selectedStudent}
        onChange={(e) => { setSelectedStudent(e) }}
        isClearable
      />
      {
        selectedStudent &&
        <div className='flex flex-col mt-5 space-y-6'>
          <AttendanceMetrics student={student} metrics={attendanceMetrics} />
          <StudentLearningExperiencesAttendanceList experiences={expeditions} />
          <Button size="sm" onClick={() => onClickAddAbsence()} startIcon={faPlus}>
            Add Absence
          </Button>
        </div>
      }
    </>
  )
}
AllView.propTypes = {
  isLoading: PropTypes.bool,
  cycleSessionAttendances: PropTypes.object,
  attendanceMetrics: PropTypes.object,
  selectedStudent: PropTypes.object,
  setSelectedStudent: PropTypes.func,
  onClickAddAbsence: PropTypes.func,
  activeStudents: PropTypes.array,
}

const ExperiencesView = ({ allExperiences, isLoading, cycleEventMetrics, selectedExperience, setSelectedExperience }) => {
  const experiencesOpts =
    allExperiences
      .map(exp => ({ value: exp.id, label: exp.title, data: exp }))

  const { title, students } = selectedExperience?.data || {}

  if (isLoading) {
    return <div className="w-full flex justify-center mt-5">
      <FontAwesomeIcon icon={faSpinner} className="text-blue-70 text-5xl self-center" spin />
    </div>
  }

  return (
    <>
      <Select
        placeholder="Select an experience"
        className="w-full text-sm"
        options={experiencesOpts}
        value={selectedExperience}
        onChange={(e) => { setSelectedExperience(e) }}
        isClearable
      />
      {
        selectedExperience &&
        <div className="mt-5 sm:space-y-1 md:space-y-5">
          <div className="text-lg font-bold leading-none">{title}</div>
          <AttendanceMetrics metrics={cycleEventMetrics} />
          <StudentsList students={students} noStudentMsg="No student is enrolled to this experience." />
        </div>
      }
    </>
  )
}
ExperiencesView.propTypes = {
  allExperiences: PropTypes.array,
  isLoading: PropTypes.bool,
  cycleEventMetrics: PropTypes.object,
  selectedExperience: PropTypes.object,
  setSelectedExperience: PropTypes.func,
}

const HousesView = ({ allHouses, isLoading, cycleEventMetrics, selectedHouse, setSelectedHouse }) => {
  const houseOpts = allHouses.map(house => ({ value: house.id, label: house.title, data: house }))

  const { data: { title, president_id, students } = {} } = selectedHouse || {}
  const president = president_id ? {
    id: president_id,
    name: students.find(stu => stu.id === president_id)?.name || ''
  } : null

  if (isLoading) {
    return <div className="w-full flex justify-center mt-5">
      <FontAwesomeIcon icon={faSpinner} className="text-blue-70 text-5xl self-center" spin />
    </div>
  }

  return (
    <>
      <Select
        placeholder="Select a house"
        className="w-full text-sm"
        options={houseOpts}
        value={selectedHouse}
        onChange={(e) => { setSelectedHouse(e) }}
        isClearable
      />
      {
        selectedHouse &&
        <div className="mt-5 sm:space-y-1 md:space-y-5">
          <div className="text-lg font-bold leading-none">{title}</div>
          {
            president &&
            <div className="text-sm">
              <span className='font-bold'>House Head: </span>
              <span className='text-regular-dark'>{president.name}</span>
            </div>
          }
          <AttendanceMetrics metrics={cycleEventMetrics} />
          <StudentsList students={students} noStudentMsg="No student is enrolled to this house." />
        </div>
      }
    </>
  )
}
HousesView.propTypes = {
  allHouses: PropTypes.array,
  isLoading: PropTypes.bool,
  cycleEventMetrics: PropTypes.object,
  selectedHouse: PropTypes.object,
  setSelectedHouse: PropTypes.func,
}
