import React from 'react'
import { Link } from 'react-router'
import { Button } from '@designsystem'
import type { CampusOption } from '@features/workbench.onboarding.families'

interface TableEmptyStateProps {
  campusId: string,
  campusData: CampusOption[]
}

export default function TableEmptyState({ campusId, campusData }: TableEmptyStateProps) {
  let text = ''
  switch (campusId) {
    case '0':
      text = `You don't have any students in the onboarding phase.`
      break
    case '-1':
      text = `You don't have any unassigned students.`
      break
    default:
      const campusTitle = campusData.find(({ value }) => value === Number(campusId))?.label
      text = `You don't have any students assigned to ${campusTitle}.`
      break
  }
  return (
    <tr>
      <td colSpan={8} className="p-4">
        <div className="flex flex-col items-center">
          <img src="/assets/onboarding/sora-learning-adventures.png" alt="Sora learning adventures" />
          <h3 className="font-bold text-3xl mb-8">{text}</h3>
          {campusId !== '0' ? <Button variant="outlined" as={Link} role="link" to="../0">View all Students</Button> : null}
        </div>
      </td>
    </tr>
  )
}