import React, { useState } from 'react'
import axios from 'axios'
import { useLoaderData } from 'react-router'
import SoraLink from '@components/link'

import {
  Column,
  Table,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
  PaginationState,
  FilterFn,
} from '@tanstack/react-table'
import { Button } from '@designsystem'

export const loader = async () => {
  const result = await axios.get('/pages/admin/reports/students/missing/units')
  return result?.data
}

type StudentMissingUnits = {
  student_id: number
  student_name: string
  school_stage: string
  campus: string
  grade: number
  expected_graduation_year: number
  topic: string
  unit: string
  is_registered: string
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>
  table: Table<any>
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () => Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  if (typeof firstValue === 'number') {
    return  (
      <div>
        <datalist id={column.id + 'list'}>
          {sortedUniqueValues.slice(0, 5000).map((value: any) => (
            <option value={value} key={value} />
          ))}
        </datalist>
        <div className="flex space-x-2">
          <DebouncedInput
            type="text"
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={value =>
              column.setFilterValue([value, value])
            }
            placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
            className="w-full min-w-25 border shadow rounded"
            list={column.id + 'list'}
            debounce={1}
          />
        </div>
        <div className="h-1" />
      </div>
    )
  }

  if (Array.isArray(firstValue)) {
    return (
      <>
        <DebouncedInput
          type="text"
          value={(columnFilterValue ?? '') as string}
          onChange={value => column.setFilterValue(value)}
          placeholder={`Search...`}
          className="w-full border shadow rounded"
        />
        <div className="h-1" />
      </>
    )
  }

  return (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={value => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-full border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  )
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>)  {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input {...props} value={value} onChange={e => setValue(e.target.value)} />
  )
}

const Genres = ({ values }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      {values.map((genre, idx) => {
        return (
          <span key={idx} className="badge">
            {genre}
          </span>
        );
      })}
    </>
  );
};

export default function StudentMissingUnits () {
  const { data } : any = useLoaderData()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 25 })

  const columnHelper = createColumnHelper<StudentMissingUnits>()

  const columns = [
    columnHelper.accessor('school_stage', {
      header: () => <span className='whitespace-nowrap'>School Stage</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('campus', {
      header: 'Campus',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('student_id', {
      header: () => <span className='whitespace-nowrap'>Student ID</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('student_name', {
      header: () => <span className='whitespace-nowrap'>Student Name</span>,
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('grade', {
      header: 'Grade',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('expected_graduation_year', {
      header: 'Expected Graduation',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('topic', {
      header: 'Topic',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('unit', {
      header: 'Unit',
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('is_registered', {
      header: 'Is Registered',
      cell: info => info.getValue(),
    }),
  ]

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      pagination, 
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row justify-end py-2'>
        <Button as={SoraLink} to={'/api/pages/admin/reports/students/missing/units?output=csv'} target='_blank' role="link">Download Full Data (CSV)</Button>
      </div>
      <div className='overflow-x-auto p-2'>
        <span className='pl-3'>Result count: {table.getFilteredRowModel().rows.length}</span>
        <table className='w-full'>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className='text-left '>
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() !== 0 ? header.getSize() : undefined, minWidth: '180px', maxWidth: '500px' }} className='px-3'>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id} className='px-3 py-3 border-b'>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="flex flex-row items-center justify-end py-2 gap-3">
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            {'<<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
          <button
            className="border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            {'>>'}
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </strong>
          </span>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                table.setPageIndex(page)
              }}
              className="border p-1 rounded w-16"
            />
          </span>
          <select
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[25, 50, 100, data.length].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize === data.length ? 'All' : pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

