import React from 'react'
import axios from 'axios'
import { useLoaderData, useRevalidator } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import { StudentsSync } from './students-sync'
import { FacultySync } from './faculty-sync'

const loader = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action') || 'default'

  const result = await axios.get('/backoffice/workbench/google/groups/sync',  { params: { action } })
  return result?.data
}

const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  try {
    await axios.post(`/backoffice/workbench/google/groups/sync`, { ...serializedFormData, action }) 
  } catch (error) {
    return {
      toast: { message: 'There was an error. If the problem persists, contact our tech support team.', appearance: 'error' }
    }
  }

  return null
}

const Element = () => {
  const revalidator = useRevalidator()
  const { workingJobs = [] } = useLoaderData()

  if (workingJobs.length > 0) {
    setTimeout(() => {
      revalidator.revalidate()
    }, 10000)
  }

  return (
    <div className='flex flex-col min-w-fit gap-4'>
      <div>
        <span>Sync all students with the Google Groups:</span>
        <ul className='list-disc ml-6'>
          <li>middleschool.students@soraschools.com</li>
          <li>highschool.students@soraschools.com</li>
          <li>6th.students@soraschools.com</li>
          <li>7th.students@soraschools.com</li>
          <li>8th.students@soraschools.com</li>
          <li>9th.students@soraschools.com</li>
          <li>10th.students@soraschools.com</li>
          <li>11th.students@soraschools.com</li>
          <li>12th.students@soraschools.com</li>
        </ul>
      </div>
      <StudentsSync isProcessing={workingJobs.includes('MANAGE_STUDENTS_GROUPS_SYNC')} />
      <div>
        <span>Sync all faculty with the Google Groups:</span>
        <ul className='list-disc ml-6'>
          <li>high.school@soraschools.com</li>
          <li>middle.school@soraschools.com</li> 
        </ul>
      </div>
      <FacultySync isProcessing={workingJobs.includes('MANAGE_EXPERTS_GROUPS_SYNC')} />
    </div>
  )
}

export const WorkbenchGoogleGroupsSyncRoute = {
  loader,
  action,
  Element,
}
