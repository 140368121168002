import React, { Fragment, useState, useEffect } from 'react'
import { Await } from 'react-router'
import { ColumnDef } from '@tanstack/react-table'
import { useThrottle } from '@hooks/useThrottle'
import { Button, DropdownMenu, Command, cn, unstable_Tooltip as Tooltip, Icon, Spinner, useFetcher } from '@design-system'
import { Student } from './types'

interface ColumnFilterProps {
  column: ColumnDef<Student>
}

export const ColumnFilters = ({ filtersFn, column }) => {
  const [open, setOpen] = React.useState(false)
  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <Tooltip content={`Filter ${column.columnDef.header}`}>
        <DropdownMenu.Trigger asChild>
          <Button variant="ghost" size="xs" type="button" className={cn(!column.getIsFiltered() && "invisible opacity-0", "group-hover/table:visible group-hover/table:opacity-100 transition-opacity")}>
            <Icon name="filter" size="xs" />
          </Button>
        </DropdownMenu.Trigger>
      </Tooltip>
      <DropdownMenu.Content align="end" className="py-0 border-0">
        <React.Suspense fallback={
          <div className="flex justify-center items-center py-4">
            <Spinner />
          </div>
        }>
          <Await
            resolve={filtersFn}
            errorElement={
              <p>Error loading!</p>
            }
          >
            {(data) => (
              <Fragment>
                {column.columnDef.accessorKey === 'currentHouseTitle' && (
                  <ColumnFilterHouse data={data.houses} column={column} setOpen={setOpen} />
                )}
              </Fragment>
            )}
          </Await>
        </React.Suspense>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}

const ColumnFilterHouse = ({ data, column, setOpen }) => {
  const columnFilterValue = column.getFilterValue()
  const handleChange = houseId => checked => {
    column.setFilterValue(checked ? [houseId] : [])
    setOpen(false)
  }
  return (
    <Command>
      <Command.Input placeholder="Select house..." />
      <Command.List>
        <Command.Empty>No results found.</Command.Empty>
        {data.map((cohort) => (
          <Command.Group key={cohort.title} heading={cohort.title}>
            {cohort.houses.map(house => (
              <Command.Item key={house.id} value={house.id} onSelect={handleChange(house.id)}>
                {house.title}
                {columnFilterValue?.includes(house.id) && (
                  <Command.Shortcut>
                    <Icon name="check" size="xs" />
                  </Command.Shortcut>
                )}
              </Command.Item>
            ))}
          </Command.Group>
        ))}
      </Command.List>
    </Command>
  )

  return (
    <Fragment>
      {data.map((cohort) => (
        <DropdownMenu.Group key={cohort.title}>
          <DropdownMenu.Label className="flex justify-between gap-6">
            {cohort.title}
          </DropdownMenu.Label>
          {cohort.houses.map(house => (
            <DropdownMenu.CheckboxItem key={house.id} checked={columnFilterValue?.includes(house.id)} onCheckedChange={handleChange(house.id)} on>
              {house.title}
            </DropdownMenu.CheckboxItem>
          ))}
        </DropdownMenu.Group>
      ))}
    </Fragment>
  )
}
