import React from 'react'
import { useNavigation, useSearchParams, Await } from 'react-router'
import { Column, flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { unstable_Table as Table, Skeleton, unstable_Tooltip as Tooltip, DropdownMenu, Icon, Button, cn, Card } from '@design-system'
import { TableSkeleton } from './table-skeleton'
import { ColumnFilters } from './column-filters'
import { Student } from './types'

export function DataTable({ columns, data = [], columnVisibility, sorting, columnFilters, filtersFn }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleSorting = callback => {
    const newSorting = callback()
    const newParams = new URLSearchParams(searchParams)
    newParams.set('sorting', JSON.stringify(newSorting[0]))
    newParams.set('page', '1')
    setSearchParams(newParams)
  }
  const handleColumnFiltersChange = callback => {
    const newColumnFilters = callback()
    console.log(newColumnFilters)
    const newParams = new URLSearchParams(searchParams)
    newParams.set('columnFilters', JSON.stringify(newColumnFilters))
    newParams.set('page', '1')
    setSearchParams(newParams)
  }
  const navigation = useNavigation()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: handleSorting,
    onColumnFiltersChange: handleColumnFiltersChange,
    manualSorting: true,
    manualFiltering: true,
    state: {
      sorting: [sorting],
      columnVisibility,
      columnFilters,
      columnPinning: {
        left: ['id', 'name'],
      },
    },

  })

  const getClassNameFor = (column: Column<Student>) => {
    if (column.getIsPinned()) {
      if (column.id === 'id') return 'z-10 sticky left-0 pr-0 max-w-16 bg-gradient-to-r from-white via-white/80 via-90% to-transparent dark:from-gray-100 dark:via-gray-100/8 md:bg-white md:dark:bg-gray-100'
      if (column.id === 'name') return `md:z-10 md:sticky md:left-16 md:first:left-0 md:first:pl-4 pl-0 md:bg-gradient-to-r md:from-white md:via-white/80 md:via-90% md:to-transparent md:dark:from-gray-100 md:dark:via-gray-100/80`
    }
    return ''
  }

  return (
    <Card>
      <Table>
        <Table.Header className="bg-white dark:bg-gray-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.Row key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.Head key={header.id} className={cn(getClassNameFor(header.column))} style={{ minWidth: header.column.getSize() }}>
                  <div className="flex items-center gap-1 group/table">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    {header.column.getCanFilter() ? (
                      <ColumnFilters column={header.column} filtersFn={filtersFn} />
                    ) : null}
                    {header.column.columnDef.enableSorting && (
                      <Button
                        aria-label={`Sort ${header.column.columnDef.header}`}
                        variant="ghost"
                        size="xs"
                        onClick={() => header.column.toggleSorting()}
                        className={cn(!header.column.getIsSorted() && "invisible opacity-0", "group-hover/table:visible group-hover/table:opacity-100 transition-opacity")}
                      >
                        <Icon name={header.column.columnDef.invertSorting ? "arrow-bottom" : "arrow-top"} size="xs" className={cn(header.column.getIsSorted() === "asc" && "rotate-180")} />
                      </Button>
                    )}
                  </div>
                </Table.Head>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        {navigation.state === 'loading' ? (
          <TableSkeleton rows={20} columnsLength={columns.length} />
        ) : (
          <Table.Body>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell key={cell.id} className={cn(getClassNameFor(cell.column))}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={table.getFlatHeaders().length} className="h-24 text-center">
                  No results.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        )}
      </Table>
    </Card>
  )
}


