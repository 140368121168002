import { Button, TextField } from '@designsystem'
import axios from 'axios'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Form, useActionData, useLoaderData, useRevalidator, useNavigation, useSubmit } from 'react-router'
import useConfirmModal from '@hooks/useConfirmModal'
import Paginator from '../../../../components/paginator'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from "@headlessui/react"
import { usePopper } from 'react-popper'
import { useToast } from '@hooks/useToast'

export default function Admissions() {
  const { applications, pageIndex, totalPages, search, searchType, isSuperAdmin } = useLoaderData()
  const actionData = useActionData()
  const revalidator = useRevalidator()
  const [sortBy, setSortBy] = useState()
  const formRef = useRef()
  const submit = useSubmit()
  useToast(actionData)

  useEffect(() => {
    if (formRef.current) submit(formRef.current)
  }, [sortBy])

  useEffect(() => {
    if (Number(actionData?.shouldRevalidate)) revalidator.revalidate()
  }, [actionData])

  return (
    <div>
      <div className="pb-4">
        <h1 className="font-bold text-2xl">Search</h1>
        <Form ref={formRef} id="filter-form" method="GET">
          <input type="hidden" name="sortBy" value={sortBy} />
          <fieldset className="space-x-1 flex">
            <div className="min-w-xs">
              <TextField name="search" id="search" defaultValue={search} />
            </div>
            <TextField as="select" name="searchType" id="searchType" defaultValue={searchType}>
              <option value="student_name">by Student name</option>
              <option value="guardian_name">by Guardian name</option>
              <option value="guardian_email">by Guardian email</option>
              <option value="id">by ID</option>
            </TextField>
            <Button type="submit">Search</Button>
          </fieldset>
        </Form>
      </div>
      <table className="border rounded-full w-full">
        <thead className="text-center text-sm">
          <tr>
            <td className="font-bold border px-3">
              <SortablePageTitle setSortBy={setSortBy} title="Application ID" sortKey="id" />
            </td>
            <td className="font-bold border px-3">
              <SortablePageTitle setSortBy={setSortBy} title="Guardian ID" sortKey="applicant_guardian_id" />
            </td>
            <td className="font-bold border px-3">
              <SortablePageTitle setSortBy={setSortBy} title="Guardian name" sortKey="guardian_name" />
            </td>
            <td className="font-bold border px-3">
              <SortablePageTitle setSortBy={setSortBy} title="Guardian email" sortKey="guardian_email" />
            </td>
            <td className="font-bold border px-3">Guardian relationship</td>
            <td className="font-bold border px-3">
              <SortablePageTitle setSortBy={setSortBy} title="Student name" sortKey="student_name" />
            </td>
            <td className="font-bold border px-3">Student grade</td>
            <td className="font-bold border px-3">Guardian form finished</td>
            <td className="font-bold border px-3">Student form finished</td>
            <td className="font-bold border px-3">Flexible tuition state</td>
            <td className="font-bold border px-3">Academic records state</td>
            <td className="font-bold border px-3">Mentor state</td>
            <td className="font-bold border px-3">Student interview state</td>
            <td className="font-bold border px-3">Google drive folder</td>
            <td className="font-bold border px-3">Hubspot deal</td>
            <td className="font-bold border px-3">Smartlook</td>
            <td className="font-bold border px-3">Action</td>
          </tr>
        </thead>
        <tbody className="text-center text-xs">
          {applications.map((application) => (
            <tr key={application.id} className="bg-gray-2 whitespace-nowrap hover:bg-gray-10">
              <td className="px-3 py-2 border-r-1">{application.id}</td>
              <td className="px-3 py-2 border-r-1">{application.applicantGuardianId}</td>
              <td className="py-2 border-r-1">{application.guardianName}</td>
              <td className="py-2 border-r-1">{application.guardianEmail}</td>
              <td className="py-2 border-r-1">{application.guardianRelationship}</td>
              <td className="py-2 border-r-1">{application.studentName}</td>
              <td className="py-2 border-r-1">{application.studentGrade}</td>
              <td className="py-2 border-r-1">{application.guardianFinished ? 'Yes' : 'No'}</td>
              <td className="py-2 border-r-1">{application.studentFinished ? 'Yes' : 'No'}</td>
              <td className="py-2 border-r-1">{application.flexibleTuitionState}</td>
              <td className="py-2 border-r-1">{application.academicRecordsState}</td>
              <td className="py-2 border-r-1">{application.mentorEvaluationState}</td>
              <td className="py-2 border-r-1">{application.studentInterviewState}</td>
              <td className="py-2 border-r-1">
                {application.googleDriveFolder ? (
                  <a href={application.googleDriveFolder} rel="noreferrer" target="_blank">
                    Folder
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td className="py-2 border-r-1">
                {application.hubspotDealUrl ? (
                  <a href={application.hubspotDealUrl} rel="noreferrer" target="_blank">
                    Deal
                  </a>
                ) : (
                  'N/A'
                )}
              </td>
              <td className="py-2 border-r-1">
                <a
                  href={`https://app.smartlook.com/org/8eacd5421057f2ec9a2dd06d/project/gebv43fj32t25qclsqrmevv5/recordings?filterUserId=${application.applicantGuardianId}`}
                  rel="noreferrer"
                  target="_blank">
                  Smartlook
                </a>
              </td>
              <td className="flex justify-evenly py-2 px-3 space-x-2">
                <Actions
                  isSuperAdmin={isSuperAdmin}
                  applicationId={application.id} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-left pt-4">
        <Paginator formId="filter-form" totalPages={totalPages} currentPage={pageIndex + 1} />
      </div>
    </div>
  )
}

const SortablePageTitle = ({ title, sortKey, setSortBy }) => {
  return (
    <div className="flex items-center justify-center">
      {title}
      <div className="grid grid-cols-1 p-2">
        <button type="button" onClick={() => setSortBy(`${sortKey}_asc`)}>
          <FontAwesomeIcon icon={faChevronUp} />
        </button>
        <button type="button" onClick={() => setSortBy(`${sortKey}_desc`)}>
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      </div>
    </div>
  )
}

SortablePageTitle.propTypes = {
  title: PropTypes.string,
  sortKey: PropTypes.string,
  setSortBy: PropTypes.func,
}


function Actions({
  applicationId,
  isSuperAdmin,
}) {
  const [popperElement, setPopperElement] = useState()
  const [referenceElement, setReferenceElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-end' })

  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className="rounded-full py-2 px-4 text-black hover:bg-gray-40 active:bg-gray-50 ">
        <img src="/assets/icons/ellipsis-icon.svg" className="w-6 h-6" alt="Notification Options" title="Notification Options" />
      </Popover.Button>
      <Popover.Panel
        className="z-10"
        ref={setPopperElement}
        style={{ ...styles.popper, filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25))' }}
        {...attributes.popper}
      >
        <ul className="rounded-lg grid items-center overflow-hidden bg-white px-2 pb-2">
          <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to delete application (${applicationId})?`}
              formType="softDelete"
              title="Delete"
            />
          </li>
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to generate guardian PDF for application (${applicationId})?`}
              formType="generateGuardianPdf"
              title="Generate guardian PDF"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to generate student PDF for application (${applicationId})?`}
              formType="generateStudentPdf"
              title="Generate student PDF"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to trigger deal creation for application (${applicationId})?`}
              formType="triggerDealCreation"
              title="Trigger deal creation"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to trigger contact update for application (${applicationId})?`}
              formType="triggerContactUpdate"
              title="Trigger contact update"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to trigger contact creation for application (${applicationId})?`}
              formType="triggerContactCreation"
              title="Trigger contact creation"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to send the TEACHER evaluation request email for application (${applicationId})?`}
              formType="sendMentorEmail"
              extraData={{ isFirstMentor: true }}
              title="Send TEACHER email"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`Do you really want to send the MENTOR evaluation request email for application (${applicationId})?`}
              formType="sendMentorEmail"
              extraData={{ isFirstMentor: false }}
              title="Send MENTOR email"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`You are generating the Enrolled folder for application (${applicationId}). Confirm?`}
              formType="generateEnrolledFolder"
              title="Generate Enrolled folder"
            />
          </li>}
          {isSuperAdmin && <li className="pt-2">
            <ButtonFormWithConfirmation
              applicationId={applicationId}
              message={`You are updating the deal info on Hubspot for application (${applicationId}). Confirm?`}
              formType="updateDealInfo"
              title="Update deal info"
            />
          </li>}
        </ul>
      </Popover.Panel>
    </Popover>
  )
}

Actions.propTypes = {
  isSuperAdmin: PropTypes.bool,
  applicationId: PropTypes.string,
}

const ButtonFormWithConfirmation = ({
  applicationId,
  message,
  formType,
  extraData,
  title,
}) => {
  const navigation = useNavigation()
  const isSending = navigation.state === 'submitting' && (navigation.formData?.get('studentId') || '') == applicationId
  const confirm = useConfirmModal()
  const submit = useSubmit()
  return (
    <Form
      method="POST"
      onSubmit={async (e) => {
        e.preventDefault()
        const form = e.currentTarget
        if (await confirm(message)) {
          submit(form)
        }
      }}
    >
      <input type="hidden" name="formType" value={formType} />
      <input type="hidden" name="applicationId" value={applicationId} />
      {extraData && Object.entries(extraData).map(([key, value]) => <input type="hidden" key={key} name={key} value={value} />)}
      <Button size="sm" as="button" type="submit" variant="outlined" loading={isSending}>
        {title}
      </Button>
    </Form>
  )
}

ButtonFormWithConfirmation.propTypes = {
  applicationId: PropTypes.string,
  message: PropTypes.string,
  formType: PropTypes.string,
  extraData: PropTypes.object,
  title: PropTypes.string,
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  const formType = formData.get('formType')

  if (formType === 'softDelete') {
    try {
      await axios.post('/admissions-soft-delete', { applicationId: formData.get('applicationId') })
      return { shouldRevalidate: true, toast: { message: 'Application deleted!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to delete application!', appearance: 'error' } }
    }
  }

  if (formType === 'generateStudentPdf') {
    try {
      await axios.post('/admissions-generate-pdf', { applicationId: formData.get('applicationId'), isStudent: true })

      return { toast: { message: 'PDF generated!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to generate PDF!', appearance: 'error' } }
    }
  }

  if (formType === 'generateGuardianPdf') {
    try {
      await axios.post('/admissions-generate-pdf', { applicationId: formData.get('applicationId') })

      return { toast: { message: 'PDF generated!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to generate PDF!', appearance: 'error' } }
    }
  }

  if (formType === 'triggerDealCreation') {
    try {
      await axios.post('/admissions-trigger-deal-creation', { applicationId: formData.get('applicationId') })

      return { toast: { message: 'Deal created!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to create deal!', appearance: 'error' } }
    }
  }

  if (formType === 'triggerContactCreation') {
    try {
      await axios.post('/admissions-trigger-contact-creation', { applicationId: formData.get('applicationId') })

      return { toast: { message: 'Contact created!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to create contact!', appearance: 'error' } }
    }
  }

  if (formType === 'triggerContactUpdate') {
    try {
      await axios.post('/admissions-trigger-contact-update', { applicationId: formData.get('applicationId') })

      return { toast: { message: 'Contact updated!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to update contact!', appearance: 'error' } }
    }
  }

  if (formType === 'sendMentorEmail') {
    try {
      await axios.post('/admissions-resend-mentor-evaluation', {
        applicationId: formData.get('applicationId'),
        isFirstMentor: formData.get('isFirstMentor') == 'true',
      })

      return { toast: { message: 'Email sent!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to send email!', appearance: 'error' } }
    }
  }

  if (formType === 'generateEnrolledFolder') {
    try {
      await axios.post('/admissions-generate-enrolled-folder', { applicationId: formData.get('applicationId') })

      return { toast: { message: 'Enrolled folder generated!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to generate enrolled folder!', appearance: 'error' } }
    }
  }

  if (formType === 'updateDealInfo') {
    try {
      await axios.post('/admissions-update-deal-info', { applicationId: formData.get('applicationId') })

      return { toast: { message: 'Deal info updated!', appearance: 'success' } }
    } catch (_) {
      return { toast: { message: 'Failed to update deal info!', appearance: 'error' } }
    }
  }

  return {}
}

export const loader = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get('/admissions-applications-status', {
    params: searchParams,
  })
  return { ...data, search: searchParams.get('search'), searchType: searchParams.get('searchType') }
}
