import React from 'react'
import { Student } from './types'
import { Typography } from '@design-system'
import SoraLink from '@components/link'

interface CellHouseProps extends Pick<Student, 'currentHouseId' | 'currentHouseTitle' | 'currentHouseAdvisor'> { }

export const CellHouse = ({ currentHouseId, currentHouseTitle, currentHouseAdvisor }: CellHouseProps) => {
  return (
    <>
      <Typography variant="callout" asChild className="hover:underline">
        <SoraLink to={`/houses/${currentHouseId}`}>{currentHouseTitle}</SoraLink>
      </Typography>
      <Typography variant="callout" color="secondary">{currentHouseAdvisor}</Typography>
    </>
  )
}
