import * as React from 'react'
import axios from 'axios'
import { Outlet, useLoaderData, useParams } from 'react-router'
import { Tabs, Typography } from '@design-system'

type LoaderData = Awaited<ReturnType<typeof loader>>

async function loader({ params }) {
  const experienceId = params?.experienceId
  const experienceRepresentativeParam = experienceId === 'edit' ? '' : experienceId + '/'

  const { data } = await axios.get(`/backoffice/experiences/${experienceRepresentativeParam}title`)
  return {
    experienceTitle: data.title
  }
}

function Element() {
  const params = useParams()
  const id = params?.id
  const experienceId = params?.experienceId
  const [pageTitle, setPageTitle] = React.useState('')

  const loaderData = useLoaderData() as LoaderData
  const experienceTitle = loaderData?.experienceTitle

  React.useEffect(() => {
    setPageTitle(experienceTitle)
  }, [])

  React.useEffect(() => {
    const titleInput = document.querySelector('input[name="title"]') as HTMLInputElement

    const handleTitleChange = (e) => {
      if (e)
        setPageTitle(e.target.value)
    }

    titleInput?.addEventListener('input', handleTitleChange)
    return () => {
      titleInput?.removeEventListener('input', handleTitleChange)
    }
  }, [])

  const experienceRepresentativeParam = experienceId || id
  return (
    <>
      {experienceRepresentativeParam ? (
        <div className="py-4 bg-screen-primary">
          <div className="max-w-[80vw]">
            <Typography variant="heading-4" weight="bold" className="text-ellipsis line-clamp-1">{pageTitle}</Typography>
          </div>
          <Tabs className="static mb-0 top-0">
            <Tabs.Item title="Edit" to={`./edit`} />
            <Tabs.Item title="Tasks" to={`./tasks`} />
            <Tabs.Item title="Registrations" to={`./registrations`} />
          </Tabs>
        </div>
      ) : null}
      <div className='relative h-full'>
        <div className='absolute top-0 left-0 right-0 bottom-0 overflow-auto'>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export const NEW_ExperienceRoute = {
  Element: Element,
  loader: loader,
}

