import React, { useCallback, useMemo, useRef, useState } from 'react'
import axios from 'axios'
import { Await, Form, LoaderFunctionArgs, useLoaderData, useNavigation, useSearchParams, useSubmit } from 'react-router'
import { Icon, Typography, unstable_Select as Select, DropdownMenu, Button, unstable_Tooltip as Tooltip, Switch, Pagination } from '@design-system'
import { ColumnDef } from '@tanstack/react-table'
import serializeFormData from '@utils/serializeFormData'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { TextField } from '@designsystem'
import { DataTable } from './data-table'
import { HeaderStudent } from './header-student'
import { CellStudentName } from './cell-student-name'
import { CellId } from './cell-id'
import { CellAttendance } from './cell-attendance'
import { CellTaskSubmission } from './cell-task-submission'
import { CellRequestList } from './cell-request-list'
import { HeaderGuardians } from './header-guardians'
import { CellGuardians } from './cell-guardians'
import { CellActions } from './cell-actions'
import { CellProgress } from './cell-progress'
import { CellHouse } from './cell-house'
import { Student } from './types'
import { CellMath } from './cell-math'
import { CellLanguage } from './cell-language'
import { CellPathways } from './cell-pathways'
import { CellStatus } from './cell-status'

async function getStudentsWithMetrics({ students, timeframe }) {
  const { data: metrics } = await axios.get(`/backoffice/students?_loader=metrics&timeframe=${timeframe}&ids=${students.map(s => s.id).join(',')}`)
  const studentsWithMetrics = students.map(student => {
    const studentWithMetrics = {
      ...student,
      ...metrics[student.id],
    }
    return studentWithMetrics
  })
  return studentsWithMetrics
}

async function getFilters() {
  const { data } = await axios.get(`/backoffice/students?_loader=filters`)
  return data
}

async function loader({ request }: LoaderFunctionArgs) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/students?${searchParams.toString()}`)
  //if (searchParams.get('_loader')) {
  //  return data
  //}
  return {
    ...data,
    studentsWithMetrics: getStudentsWithMetrics({ students: data.students, timeframe: data.query.timeframe }),
    filters: getFilters()
  }
}

const action = async ({ request }) => {
  const searchParams = new URL(request.url).searchParams
  const formData = await request.formData()
  try {
    const { data } = await axios.post(`/backoffice/students?${searchParams.toString()}`, serializeFormData(formData))
    return data
  } catch (e) {
    return {
      toast: {
        appearance: 'error',
        message: 'Something went wrong.'
      }
    }
  }
}

const columnLabels: { name: string, label: string }[] = [
  { name: 'trendAttendanceCount', label: 'Attendance' },
  { name: 'trendCompletedTasksCount', label: 'Task Submission' },
  { name: 'currentHouseTitle', label: 'House' },
  { name: 'graduationStatus', label: 'Progress' },
  { name: 'requestListLowestCount', label: 'Request List' },
  { name: 'pathways', label: 'Pathways' },
  { name: 'mathTitles', label: 'Math' },
  { name: 'languageTitles', label: 'Language' },
  { name: 'guardians', label: 'Guardians' },
  { name: 'status', label: 'Status' },
  { name: 'actions', label: 'Actions' },
]

const initialColumnVisibility: Record<string, boolean> = columnLabels.reduce((acc, column) => {
  acc[column.name] = true
  return acc
}, {
  id: true,
  name: true,
})

type Columns = ColumnDef<Student>[]

const columns: Columns = [
  {
    accessorKey: "id",
    enableColumnFilter: false,
    enableSorting: true,
    header: "ID",
    cell: ({ row }) => <CellId {...row.original} />,
    size: 50,
  },
  {
    accessorKey: "name",
    enableSorting: true,
    enableColumnFilter: true,
    header: ({ table }) => <HeaderStudent table={table} />,
    cell: ({ row }) => <CellStudentName {...row.original} />,
    size: 250,
  },
  {
    accessorKey: "trendAttendanceCount",
    enableColumnFilter: false,
    header: "Attendance",
    cell: ({ row }) => <CellAttendance {...row.original} />,
  },
  {
    accessorKey: "trendCompletedTasksCount",
    enableColumnFilter: false,
    header: "Task Submission",
    size: 175,
    cell: ({ row }) => <CellTaskSubmission {...row.original} />,
  },
  {
    accessorKey: 'currentHouseTitle',
    enableSorting: true,
    enableColumnFilter: true,
    header: "House",
    cell: ({ row }) => <CellHouse {...row.original} />,
    size: 150,
  },
  {
    accessorKey: "graduationStatus",
    enableSorting: true,
    enableColumnFilter: true,
    header: "Progress",
    cell: ({ row }) => <CellProgress {...row.original} />,
  },
  {
    accessorKey: "requestListLowestCount",
    enableSorting: true,
    header: "Request List",
    cell: ({ row }) => <CellRequestList {...row.original} />,
    size: 190,
  },
  {
    accessorKey: "pathways",
    enableColumnFilter: true,
    header: "Pathways",
    cell: ({ row }) => <CellPathways {...row.original} />,
    size: 190,
  },
  {
    accessorKey: "mathTitles",
    enableColumnFilter: true,
    header: "Math",
    cell: ({ row }) => <CellMath {...row.original} />,
    size: 190,
  },
  {
    accessorKey: "languageTitles",
    enableColumnFilter: true,
    header: "Language",
    cell: ({ row }) => <CellLanguage {...row.original} />,
    size: 190,
  },
  {
    id: "guardians",
    accessorKey: "guardians[0].name",
    enableColumnFilter: false,
    header: ({ table }) => <HeaderGuardians table={table} />,
    cell: ({ row }) => <CellGuardians {...row.original} />,
    size: 190,
  },
  {
    accessorKey: "status",
    header: "Status",
    enableColumnFilter: false,
    enableSorting: true,
    cell: ({ row }) => <CellStatus {...row.original} />,
    size: 50
  },
  {
    id: "actions",
    enableColumnFilter: false,
    cell: ({ row }) => <CellActions {...row.original} />,
    size: 50
  },
]

interface StudentsData {
  students: Student[]
  studentsWithMetrics: Student[]
  totalPages: number
  currentPage: number
  query: {
    search: string
    timeframe: string
    status: string
    sorting: {
      id: string
      desc: boolean
    }
    columnFilters: {id: string, value: any }[]
  }
}

function Element() {
  const data = useLoaderData() as StudentsData
  const submit = useSubmit()
  let [searchParams] = useSearchParams()
  const navigation = useNavigation()
  const formData = navigation.formData
  const timeframe = String(formData?.get('timeframe') || searchParams.get('timeframe') || data.query.timeframe)
  const status = String(formData?.get('status') || searchParams.get('status') || data.query.status)
  const search = String(formData?.get('search') || searchParams.get('search') || data.query.search)
  const sorting = data.query.sorting
  const columnFilters = data.query.columnFilters
  const [columnVisibility, setColumnVisibility] = useLocalStorage('students-column-visibility', initialColumnVisibility)

  const handleToggleColumnVisibility = (columnName) => {
    if (columnName === 'show_all') {
      return setColumnVisibility(initialColumnVisibility)
    }
    if (columnName === 'hide_all') {
      return setColumnVisibility(columnLabels.reduce((acc, column) => {
        acc[column.name] = false
        return acc
      }, { id: true, name: true }))
    }
    if (columnVisibility[columnName]) {
      setColumnVisibility((prevColumnVisibility) => ({ ...prevColumnVisibility, [columnName]: false }))
    } else {
      setColumnVisibility((prevColumnVisibility) => ({ ...prevColumnVisibility, [columnName]: true }))
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <header className="relative flex flex-col gap-4">
        <div className="flex justify-between items-center gap-2 my-2 lg:my-0">
          <Typography variant="heading-3" weight="bold">Students</Typography>
        </div>
        <div className="flex gap-4">
          <Form method="get" onChange={e => submit(e.currentTarget)} className="grid grid-cols-1 lg:grid-flow-col lg:auto-cols-fr grow gap-4 max-w-screen-lg">
            <TextField
              startAdornment={<Icon name="search" size="sm" />}
              placeholder="Search students..."
              name='search'
              defaultValue={search}
              fullWidth
              autoFocus
            />
            <Select name="status" defaultValue={status}>
              <Select.Trigger className="w-full">
                <Select.Value placeholder="Select a status" />
              </Select.Trigger>
              <Select.Content>
                <Select.Item value="active">Active Students</Select.Item>
                <Select.Item value="admitted">Admitted</Select.Item>
                <Select.Item value="graduated">Graduated</Select.Item>
                <Select.Item value="withdrawn">Withdrawn</Select.Item>
                <Select.Item value="all">All Students</Select.Item>
              </Select.Content>
            </Select>
            {(columnVisibility.trendAttendanceCount || columnVisibility.trendCompletedTasksCount) && (
              <Select name="timeframe" defaultValue={timeframe}>
                <Select.Trigger className="w-full">
                  <Select.Value placeholder={`Attendance, Tasks: ${timeframe === 'currentCycle' ? 'Current Cycle' : 'Last 7 Days'}`}>
                    <div className="whitespace-nowrap text-ellipsis overflow-hidden">
                      Attendance, Tasks: {timeframe === 'currentCycle' ? 'Current Cycle' : 'Last 7 Days'}
                    </div>
                  </Select.Value>
                </Select.Trigger>
                <Select.Content>
                  <Select.Group>
                    <Select.Label>Timeframe for Attendance and Tasks</Select.Label>
                    <Select.Item value="lastSevenDays">Last 7 Days</Select.Item>
                    <Select.Item value="currentCycle">Current Cycle</Select.Item>
                  </Select.Group>
                </Select.Content>
              </Select>
            )}
          </Form>
          <div className="flex gap-2 absolute top-0.5 right-0 lg:static">
            <DropdownMenu>
              <Tooltip content="Customize columns">
                <DropdownMenu.Trigger asChild>
                  <Button color="soft" variant="outlined" size="lg" type="button">
                    <Icon name="gear" size="md" />
                  </Button>
                </DropdownMenu.Trigger>
              </Tooltip>
              <DropdownMenu.Content align="end">
                <DropdownMenu.Group>
                  <DropdownMenu.Label className="flex justify-between gap-6">
                    <span className="w-full mr-auto">Column Visibility</span>
                    <div className="font-normal whitespace-nowrap flex gap-1">
                      <button className="text-blue-40 hover:text-blue-60 dark:text-blue-20 dark:hover:text-blue-10 underline" onClick={() => handleToggleColumnVisibility('show_all')}>Show all</button>
                      /
                      <button className="text-blue-40 hover:text-blue-60 dark:text-blue-20 dark:hover:text-blue-10 underline" onClick={() => handleToggleColumnVisibility('hide_all')} >Hide all</button>
                    </div>
                  </DropdownMenu.Label>
                  {columnLabels.map(({ name, label }) => (
                    <DropdownMenu.SwitchItem key={name} checked={columnVisibility[name]} onClick={() => handleToggleColumnVisibility(name)} onSelect={e => e.preventDefault()}>
                      {label}
                    </DropdownMenu.SwitchItem>
                  ))}
                </DropdownMenu.Group>
              </DropdownMenu.Content>
            </DropdownMenu>
            <Tooltip content="Export">
              <Button color="soft" variant="outlined" size="lg" asChild>
                <a href="#">
                  <Icon name="file-download" size="md" />
                </a>
              </Button>
            </Tooltip>
          </div>
        </div>
      </header>
      <React.Suspense fallback={<DataTable columns={columns} data={data.students} filtersFn={data.filters} columnVisibility={columnVisibility} sorting={sorting} columnFilters={columnFilters} />}>
        <Await
          resolve={data.studentsWithMetrics}
          errorElement={
            <p>Error loading students!</p>
          }
        >
          {(studentsWithMetrics) => (
            <DataTable filtersFn={data.filters} columns={columns} data={studentsWithMetrics} columnVisibility={columnVisibility} sorting={sorting} columnFilters={columnFilters} />
          )}
        </Await>
      </React.Suspense>
      <Pagination totalPages={data.totalPages} currentPage={data.currentPage} />
    </div>
  )
}

export const StudentsRoute = {
  loader,
  action,
  Element,
}
