import React from 'react'
import { Student } from './types'
import { CopyButton } from './copy-button'

interface CellGuardiansProps extends Pick<Student, 'guardians'> { }

export const CellGuardians = ({ guardians }: CellGuardiansProps) => {
  const content = guardians.map(guardian => guardian.email).join(', ')
  return (
    <CopyButton content={content} tooltip="Copy emails">
      {guardians.map((guardian) => (
        <div key={guardian.email} className="first:mb-1 whitespace-nowrap">
          {guardian.name}
        </div>
      ))}
    </CopyButton>
  )
}
