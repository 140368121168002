import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@design-system'
import { useNavigation, useSearchParams } from 'react-router'
import { Transition } from '@headlessui/react'

export const ColumnFilter = ({ field, title, options = [] }) => {
  const wrapperRef = useRef()
  const [show, setShow] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigation = useNavigation()

  const isLoading = navigation.state === 'loading'
  const selectedValue = searchParams.get(field)

  const handleChangeFilter = (params) => {
    if (searchParams.get(params.field) === params.value.toString()) 
      searchParams.delete(params.field) 
    else
      searchParams.set(params.field, params.value)
    
    setSearchParams(searchParams)
    setShow(false)
  }

  const handleClickListener = (event) => {
    const clickedInside = (wrapperRef && wrapperRef.current.contains(event.target))
    if (clickedInside) return
    else setShow(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickListener)
    
    return () => {
      document.removeEventListener('mousedown', handleClickListener)
    }
  }, [])

  return (
    <div className='flex flex-row w-fit items-center gap-2 relative'>
      <span>{title}</span>
      <Button variant='ghost' size='xs' color={selectedValue ? 'accent' : ''} loading={isLoading} onClick={() => { setShow(!show) }}>
        <Icon name='filter' size='xs' />
      </Button>
      <div className='flex-auto' ref={wrapperRef}>
        <Transition 
          show={show} 
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`absolute min-w-32 max-h-32 overflow-y-auto z-20 top-0 left-full bg-white shadow border-2 border-alpha/5 p-2`}>
            <ul className='flex flex-col gap-1'>
              {options.map((item) => (
                <li key={item.value} className={`hover:bg-alpha/5 cursor-pointer p-1 text-nowrap ${selectedValue === item.value.toString() ? 'bg-alpha/20' : ''}`} onClick={() => { handleChangeFilter({ field, value: item.value }) }}>
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        </Transition>
      </div>
    </div>
  )
}
ColumnFilter.propTypes = {
  field: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array,
}
