import React from 'react'
import axios from 'axios'
import { useLoaderData, Outlet, useLocation } from 'react-router'
import SoraLink from '@components/link'
import { Avatar, Button, Icon, Tabs, Typography, unstable_Tooltip as Tooltip } from '@design-system'
import { SearchStudent } from './search'
import { useThisPageTitle } from '@hooks/usePageTitle'

interface StudentRouteProps {
  student: {
    name: string
    profileImageUrl: string
    grade: string
    houseTitle?: string
    houseAdvisorFullName?: string
    schoolStage: string
  }
  tabs: {
    title: string
    to: string
    notification?: number | boolean
  }[]
  students: {
    id: string
    name: string
    profileImageUrl?: string
  }[]
  viewProgressUrl: string
}

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}?${searchParams.toString()}`)
  return data
}

function Element() {
  const data = useLoaderData() as StudentRouteProps
  const { name, profileImageUrl, grade, houseTitle, houseAdvisorFullName } = data?.student
  useThisPageTitle(name)
  const { tabs, students, viewProgressUrl } = data
  const location = useLocation()
  const backUrl: unknown = location.key !== "default" ? -1 : "/employee/students"
  return (
    <>
      <nav className="mb-4">
        <Tooltip content="Back">
          <Button asChild size="sm" variant="outlined" color="soft">
            <SoraLink to={backUrl}>
              <Icon name="arrow-left" size="sm" />
            </SoraLink>
          </Button>
        </Tooltip>
      </nav>
      <header className="flex gap-5 lg:gap-8 mb-8 items-center">
        <Avatar size="9xl">
          <Avatar.Image src={profileImageUrl} />
          <Avatar.Fallback>{name}</Avatar.Fallback>
        </Avatar>
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <Typography variant="heading-3" weight="bold">{name}</Typography>
            <SearchStudent suggestions={students} />
          </div>
          <Typography variant="subheadline">
            {grade || "Grade unknown"}
            {houseTitle && ` | ${houseTitle}`}
            {houseAdvisorFullName && ` | ${houseAdvisorFullName}`}
          </Typography>
        </div>
      </header>
      <Tabs className="mb-8">
        {tabs.map(tab => (<Tabs.Item key={tab.to} title={tab.title} to={tab.to} notification={tab.notification} />))}
        <Tabs.ExternalButton title="View Progress" to={viewProgressUrl} />
      </Tabs>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </React.Suspense>
    </>
  )
}

export const EmployeeStudentRoute = {
  Element,
  loader
}


