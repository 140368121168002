import React, { useEffect } from 'react'
import axios from 'axios'
import { useActionData, useLoaderData, useNavigate } from 'react-router'
import serializeFormData from '@utils/serializeFormData'
import OnboardingGroupModal from '@components/onboarding/onboading-group/modal'
import type { SelectOption } from '@routes/admin/workbench/onboarding/families/$campusFilter/add-families-to-group'
import { useToast } from '@hooks/useToast'

type ActionData = {
  toast?: {
    message: string,
    appearance: 'success' | 'error',
  },
  redirectUrl: string,
} | undefined

export async function loader({ params }) {
  const res = await axios.get(`/pages/admin/workbench/onboarding/groups/${params.campus_filter}/new`)
  return res.data
}

export async function action({ params, request }) {
  const formData = serializeFormData(await request.formData())
  const res = await axios.post(`/pages/admin/workbench/onboarding/groups/${params.campus_filter}/new`, formData)
  if (res.data === true) {
    return {
      toast: {
        message: 'Onboarding group created',
        appearance: 'success',
      },
      redirectUrl: '..'
    }
  }
  return {
    redirectUrl: `../duplicated-group-modal/${res.data}`,
  }
}

export default function WorkbenchOnboardingGroupsNewRoute() {
  const navigate = useNavigate()
  const actionData = useActionData() as ActionData
  const { campus, cycles } = useLoaderData() as { campus: SelectOption[], cycles: SelectOption[] }
  
  useToast(actionData)
  useEffect(() => {
    if (actionData?.redirectUrl) {
      navigate(actionData.redirectUrl)
    }
  }, [actionData?.redirectUrl])

  return <OnboardingGroupModal
    campus={campus}
    cycles={cycles}
    title="New Onboarding"
    onClose={() => navigate('..')}
  />

}