import React from 'react'
import { Student } from './types'
import { CopyButton } from './copy-button'
import { Table } from '@tanstack/react-table'

interface HeaderStudentProps {
  table: Table<Student>
}

export const HeaderStudent = ({ table }: HeaderStudentProps) => {
  const content = table.getRowModel().rows.map((row) => row.original.email).join(', ')
  return (
    <CopyButton content={content} tooltip="Copy emails">
      Student
    </CopyButton>
  )
}
