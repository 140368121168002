import React from 'react'
import { TextField } from '@designsystem'
import { Button, Combobox, useFetcher, Typography, unstable_Tooltip as Tooltip, DropdownMenu, Icon } from '@design-system'

interface StudentAddProps {
  isDark?: boolean
  isFull?: boolean
  isExtracurricular: boolean
}

export const StudentAdd = ({ isDark = false, isFull = false, isExtracurricular }: StudentAddProps) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state !== 'idle'
  const hasData = Boolean(fetcher.data?.students)

  const dialogRef = React.useRef(null)
  const dialogContentRef = React.useRef(null)
  React.useEffect(() => {
    const clickOutsideHandler = (event) => {
      if (dialogContentRef.current && !dialogContentRef.current.contains(event.target) && dialogRef.current.contains(event.target)) {
        handleCancel(event)
      }
    }
    document.addEventListener('click', clickOutsideHandler)
    return () => {
      document.removeEventListener('click', clickOutsideHandler)
    }
  }, [dialogContentRef])

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method:'POST' })
  }
  
  return (
    <>
      {isExtracurricular
        ? <ExternalStudentTrigger fetcher={fetcher} />
        : <InternalStudentTrigger fetcher={fetcher} isFull={isFull} isDark={isDark} isLoading={isLoading} hasData={hasData} />}
      <div ref={dialogRef} className={`fixed inset-0 h-screen w-screen flex flex-col justify-center items-center bg-black/60 ${hasData ? '' : 'hidden'}`}>
        <div ref={dialogContentRef} className='min-w-[600px] bg-white dark:bg-gray-100 shadow border-2 border-gray-30 dark:border-gray-90 z-50 rounded-lg'>
          <div className='w-full border-b border-gray-30 dark:border-gray-90 p-4'>
            <Typography variant="subheadline" weight="bold">
              {fetcher.data?.isExternalStudents ? 'Add external student' : 'Add student'}
            </Typography>
          </div>
          <fetcher.Form method="POST" className='p-2'>
            <input type="hidden" name="_action" value={fetcher.data?.isExternalStudents ? 'add_external_student' : 'add_student'} />
            <div>
              {(hasData) &&
                <div className='flex flex-col space-y-3 p-4'>
                  <Combobox name='student_id' label='Student' placeholder='Select a student' loading={isLoading} search={<Combobox.Search className='pointer-events-auto' autoFocus placeholder="Search a student" />} >
                    {fetcher.data?.students.map(student => {
                      return (
                        <Combobox.Item key={`opt_student_${student.id}`} value={student.id}>
                          {student.name}
                        </Combobox.Item>
                      )
                    })}
                  </Combobox>
                  {!fetcher.data?.isExternalStudents && <TextField as="textarea" name="notes" label="Notes on registration change" placeholder="Add notes..." />}
                </div>
              }
            </div>
            <div className="flex items-center space-x-3 justify-end px-2">
              <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
              <Button type="submit">Save</Button>
            </div>
          </fetcher.Form>
        </div>
      </div>
    </>
  )
}

interface OptionalTooltipProps {
  show: boolean
  content: string
  children: React.ReactNode
}

const OptionalTooltip = ({ show, content, children }: OptionalTooltipProps) => {
  if (show) return (
    <Tooltip content={content}>
      <div>{children}</div>
    </Tooltip>
  )
  return children
}

interface ExternalStudentTriggerProps {
  fetcher: ReturnType<typeof useFetcher>
}

const ExternalStudentTrigger = ({ fetcher }: ExternalStudentTriggerProps) => (
  <DropdownMenu>
    <DropdownMenu.Trigger asChild>
      <Button variant="outlined" color="soft">
        Add student
        <Icon name="chevron-down" size="sm" />
      </Button>
    </DropdownMenu.Trigger>
    <DropdownMenu.Content>
      <DropdownMenu.Item asChild className="p-0">
        <fetcher.Form method="GET">
          <input type="hidden" name="_action" value="get_available_students" />
          <button disabled={fetcher.state !== 'idle'} type="submit" className="w-full text-start p-4">Add student</button>
        </fetcher.Form>
      </DropdownMenu.Item>
      <DropdownMenu.Item asChild className="p-0">
        <fetcher.Form method="GET">
          <input type="hidden" name="_action" value="get_available_external_students" />
          <button disabled={fetcher.state !== 'idle'} type="submit" className="w-full text-start p-4">Add external student</button>
        </fetcher.Form>
      </DropdownMenu.Item>
    </DropdownMenu.Content>
  </DropdownMenu>
)

interface InternalStudentTriggerProps {
  fetcher: ReturnType<typeof useFetcher>
  isFull: boolean
  isDark: boolean
  isLoading: boolean
  hasData: boolean
}

const InternalStudentTrigger = ({ fetcher, isFull, isDark, isLoading, hasData }: InternalStudentTriggerProps) => (
  <fetcher.Form method="GET">
    <input type="hidden" name="_action" value="get_available_students" />
    <OptionalTooltip show={isFull} content="This experience is full">
      <Button variant={isDark ? 'contained' : 'outlined'} color={isDark ? 'dark' : 'soft'} size='sm' loading={isLoading} disabled={isLoading || hasData || isFull}>
        Add student
      </Button>
    </OptionalTooltip>
  </fetcher.Form>
)