import React, { useState } from 'react'
import { useLoaderData, useSearchParams } from 'react-router'
import { faChevronLeft, faChevronRight, faPrint } from '@fortawesome/free-solid-svg-icons'
import { Switch } from '@headlessui/react'
import { Button } from '@designsystem'
import { pdfjs as ReactPdf, Document, Page } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { Combobox, Typography } from '@design-system'

ReactPdf.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

export function StudentTranscriptRoute() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [numOfPages, setNumOfPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  const { loaderData, filename, transcriptFile } = useLoaderData()

  const handleChangeParams = (value, paramName) => {
    setSearchParams((sp) => {
      if (!value) {
        sp.delete(paramName)
      } else {
        sp.set(paramName, value)
      }

      return sp
    })
  }

  const handleDownloadClick = async () => {
    const url = window.URL.createObjectURL(new Blob([transcriptFile]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()

    // Clean up and revoke the URL object
    link.parentNode.removeChild(link)
    window.URL.revokeObjectURL(url)
  }
  
  if (!loaderData.currentSchoolStage) {
    return <div className='text-center'>
      <Typography variant="heading-4" weight="bold">Transcript not available</Typography>
      <p className="py-2">
        <Typography variant="body">Can't view transcript for that student because there's not a valid school stage set</Typography>
      </p>
    </div>
  }

  const canGenerateOfficialTranscript = loaderData.canGenerateOfficialTranscript
  const isFinal = searchParams.get('isFinal') === 'true'
  const isOfficial = searchParams.get('isOfficial') === 'true'
  const showGradDate = searchParams.get('showGraduationDate') === 'true'
  const selectedShoolStage = searchParams.get('schoolStage') || loaderData.currentSchoolStage

  return (
    <div>
      <div className="flex justify-center">
        <div>
          <div className="flex gap-5 justify-center">
            <div className="flex items-center gap-1 text-sm">
              <Typography variant="body" asChild>
                <label>Show Grad. Date</label>
              </Typography>
              <Switch
                checked={showGradDate}
                onChange={(v) => handleChangeParams(v, 'showGraduationDate')}
                className={`${showGradDate ? 'bg-blue-60' : 'bg-gray-20'} relative inline-flex items-center h-5 rounded-full w-10`}
              >
                <span className={`${showGradDate ? 'translate-x-6' : 'translate-x-1'} inline-block w-3 h-3 transform bg-white rounded-full`} />
              </Switch>
            </div>
            <div className="flex items-center gap-2 text-sm">
            <Typography variant="body" asChild>
                <label>Final</label>
              </Typography>
              <Switch
                checked={isFinal}
                onChange={(v) => handleChangeParams(v, 'isFinal')}
                className={`${isFinal ? 'bg-blue-60' : 'bg-gray-20'} relative inline-flex items-center h-5 rounded-full w-10`}
              >
                <span className={`${isFinal ? 'translate-x-6' : 'translate-x-1'} inline-block w-3 h-3 transform bg-white rounded-full`} />
              </Switch>
            </div>
            {canGenerateOfficialTranscript && <div className="flex items-center gap-2 text-sm">
              <Typography variant="body" asChild>
                <label htmlFor="isOfficial">Official</label>
              </Typography>
              <Switch
                id="isOfficial"
                checked={isOfficial}
                onChange={(v) => handleChangeParams(v, 'isOfficial')}
                className={`${isOfficial ? 'bg-blue-60' : 'bg-gray-20'} relative inline-flex items-center h-5 rounded-full w-10`}
              >
                <span className={`${isOfficial ? 'translate-x-6' : 'translate-x-1'} inline-block w-3 h-3 transform bg-white rounded-full`} />
              </Switch>
            </div>}
            
            <div className="flex items-center gap-2 text-sm">
              <Typography variant="body" asChild>
                <label>School Stage</label>
              </Typography>
              <Combobox defaultValue={selectedShoolStage} id="schoolStage" onChange={(e) => {
                handleChangeParams(e.target.value, 'schoolStage')
              }}>
                {loaderData.schoolStages.map((schoolStage) => (
                  <Combobox.Item key={schoolStage} value={schoolStage}>{schoolStage.toUpperCase()}</Combobox.Item>
                ))}
              </Combobox>
            </div>
          </div>
          <div className="flex justify-between pt-4">
            <div className="w-1/3"></div>
            <div className="flex flex-col gap-1 pt-2 w-1/3">
              <div className="flex mx-auto items-justify-between gap-1">
                <Button
                  tooltip="Previous Page"
                  startIcon={faChevronLeft}
                  variant="outlined"
                  size="xs"
                  onClick={() => setCurrentPage(cp => Math.max(1, cp - 1))}
                  disabled={currentPage === 1}
                />
                <Button
                  tooltip="Next Page"
                  startIcon={faChevronRight}
                  variant="outlined"
                  size="xs"
                  onClick={() => setCurrentPage(cp => Math.min(numOfPages, cp + 1))}
                  disabled={currentPage === numOfPages}
                />
              </div>
              <p className="mx-auto text-sm">Page {currentPage}</p>
            </div>
            <div className="flex justify-end w-1/3">
              <div className='w-32'>
                <Button Button
                  display="block"
                  color="cta"
                  startIcon={faPrint}
                  onClick={handleDownloadClick}>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-8 justify-center py-3">
        <div className="p-px border shadow-lg">
          <Document file={transcriptFile} onLoadSuccess={(p) => { setNumOfPages(p.numPages) }}>
            <Page pageNumber={currentPage} />
          </Document>
        </div>
      </div>
    </div>
  )
}
