import React from 'react'
import { Outlet } from 'react-router'
import { Tabs, Typography } from '@design-system'

export default function Impersonate() {
  return (
    <>
      <div className="py-4 bg-screen-primary">
        <div className="max-w-[80vw]">
          <Typography variant="heading-4" weight="bold" className="text-ellipsis line-clamp-1">Family Mirror</Typography>
        </div>
        <Tabs className="static mb-0 top-0">
          <Tabs.Item title="Students" to='students' />
          <Tabs.Item title="Guardians" to='guardians' />
        </Tabs>
      </div>
      <div className='flex justify-center'>
        <Outlet />
      </div>
    </>
  )
}
