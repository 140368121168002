import { TextField } from '@designsystem'
import axios from 'axios'
import React, { Suspense } from 'react'
import { Await, useLoaderData } from 'react-router'
import { Button, useFetcher, Combobox, Typography, unstable_Tooltip as Tooltip, Icon, Alert } from '@design-system'
import SoraLink from '@components/link'

async function getIssues(params) {
  const { data } = await axios.get(`/backoffice/houses/edit/${params.houseId}?_loader=issues`)
  return data
}

async function loader({ params }) {
  const { data } = await axios.get(`/backoffice/houses/edit/${params.houseId}`)
  return {
    ...data,
    issues: getIssues(params)
  }
}

async function action({ params, request }) {
  const formData = await request.formData()
  const { data } = await axios.post(`/backoffice/houses/edit/${params.houseId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).catch(error => {
    return {
      data: {
        toast: {
          appearance: 'error',
          message: error.response.data.error.message
        },
        errors: error.response.data.errors,
      }
    }
  })
  return data
}

function Element() {
  const loaderData = useLoaderData()
  const fetcher = useFetcher()
  const isSubmitting = fetcher.state !== 'idle'
  const initialValues = loaderData.initialValues
  const formErrors = fetcher.data?.errors || []

  return (
    <div className="max-w-4xl">
      <div className="flex gap-2">
        <Tooltip content="Back">
          <Button asChild variant="outlined" color="soft" size="sm" aria-label="Back">
            <SoraLink to="../">
              <Icon name="arrow-left" size="sm" />
            </SoraLink>
          </Button>
        </Tooltip>
        <Typography variant="heading-3" weight="bold">
          Edit House
        </Typography>
      </div>
      <Suspense>
        <Await resolve={loaderData.issues}>
          {({ zoomMeetingIssues, zoomMeetingNeededAction }) => (
            <>
              {zoomMeetingIssues.length > 0 && (
                <Alert variant="danger" className="my-6">
                  <Alert.Title>Zoom Meeting Issues</Alert.Title>
                  <Alert.Description>
                    <ul className="list-disc ml-4">
                      {zoomMeetingIssues.map(issue => (
                        <li key={issue}>{issue}</li>
                      ))}
                    </ul>
                  </Alert.Description>
                </Alert>
              )}
            </>
          )}
        </Await>
      </Suspense>
      <fetcher.Form method="POST" noValidate className="flex flex-col gap-4 mt-4" encType="multipart/form-data">
        <input type='hidden' name="id" value={initialValues.id} />
        <TextField label="Title"
          required
          name="title"
          error={formErrors.find(err => err.fieldName === 'title')?.message}
          defaultValue={initialValues.title} />
        <TextField label="House Crest URL"
          name="logo_url"
          value={initialValues.logo_url}
          readOnly={true}
          error={formErrors.find(err => err.fieldName === 'logo_url')?.message}
        />
        <input type="file" name="file" />
        <Combobox
          label="Advisor"
          name="advisor_id"
          placeholder="Type or select the advisor"
          search={<Combobox.Search placeholder="Search advisors" />}
          error={formErrors.find(err => err.fieldName === 'advisor_id')?.message}
          defaultValue={loaderData.employeesOptions.find(op => op.value === initialValues.advisor_id)?.value}>
          {
            loaderData.employeesOptions.filter(op => !op.label.includes('inactive')).map(op =>
              <Combobox.Item key={op.value} value={op.value}>{op.label}</Combobox.Item>
            )
          }
        </Combobox>
        <Combobox
          label="Secondary Advisor (optional)"
          name="secondary_advisor_id"
          placeholder="Type or select the secondary advisor"
          search={<Combobox.Search placeholder="Search secondary advisors" />}
          error={formErrors.find(err => err.fieldName === 'secondary_advisor_id')?.message}
          defaultValue={loaderData.employeesOptions.find(op => op.value === initialValues.secondary_advisor_id)?.value}>
          {
            loaderData.employeesOptions.filter(op => !op.label.includes('inactive')).map(op =>
              <Combobox.Item key={op.value} value={op.value}>{op.label}</Combobox.Item>
            )
          }
        </Combobox>
        <Combobox
          required
          label="Standup Time"
          name="default_standup_time"
          placeholder="Select the house default standup time"
          defaultValue={initialValues.default_standup_time}
          error={formErrors.find(err => err.fieldName === 'default_standup_time')?.message}
        >
          <Combobox.Item value="10:00:00-05">10:00 am EST (EST/CST Recommended)</Combobox.Item>
          <Combobox.Item value="13:20:00-05">01:20 pm EST (MST/PST Recommended)</Combobox.Item>
        </Combobox>
        <Combobox
          label="President"
          name="president_id"
          placeholder="Type or select the student"
          search={<Combobox.Search placeholder="Search students..." />}
          error={formErrors.find(err => err.fieldName === 'vice_president_id')?.message}
          defaultValue={loaderData.houseStudents.find(op => op.value === initialValues.president_id)?.value}>
          {
            loaderData.houseStudents.filter(op => !op.label.includes('inactive')).map(op =>
              <Combobox.Item key={op.value} value={op.value}>{op.label}</Combobox.Item>
            )
          }
        </Combobox>
        <Combobox
          label="Vice-President"
          name="vice_president_id"
          placeholder="Type or select the student"
          search={<Combobox.Search placeholder="Search students..." />}
          error={formErrors.find(err => err.fieldName === 'vice_president_id')?.message}
          defaultValue={loaderData.houseStudents.find(op => op.value === initialValues.vice_president_id)?.value}>
          {
            loaderData.houseStudents.filter(op => !op.label.includes('inactive')).map(op =>
              <Combobox.Item key={op.value} value={op.value}>{op.label}</Combobox.Item>
            )
          }
        </Combobox>
        <TextField label="House Events Link"
          name="conference_url"
          value={initialValues.conference_url}
          readOnly={true}
          error={formErrors.find(err => err.fieldName === 'conference_url')?.message}
        />
        <div className="self-end flex gap-2">
          <Button asChild variant="outlined" color="soft">
            <SoraLink to="..">Cancel</SoraLink>
          </Button>
          <Button type="submit"
            loading={isSubmitting && fetcher.formData.get('_action') === 'edit_house'}
            name="_action" value="edit_house">
            {isSubmitting && fetcher.formData.get('_action') === 'edit_house' ? 'Updating...' : 'Update'}
          </Button>
        </div>
      </fetcher.Form>
    </div>
  )
}

export const HouseEditRoute = {
  Element,
  loader,
  action,
}
