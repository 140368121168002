import React from 'react'
import { toast } from 'sonner'
import { Button, Icon, unstable_Tooltip as Tooltip } from '@design-system'
import useClipboard from '@hooks/useClipboard'
import { Student } from './types'
import { CopyButton } from './copy-button'

interface CellIdProps extends Pick<Student, 'id'> { }

export const CellId = ({ id }: CellIdProps) => {
  const [, copy] = useClipboard()
  const handleCopy = () => {
    try {
      copy(id)
      toast.success('Copied')
    } catch (e) {
      toast.error('Something went wrong')
    }
  }
  return (
  <span onClick={handleCopy} className="font-bold cursor-pointer">
    {id}
  </span>
)}
