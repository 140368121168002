/* eslint-disable react/prop-types */
import React from 'react'
import { useParams } from 'react-router'
import useTimelineEvents from '@hooks/useTimelineEvents.js'
import SoraLink from '@components/link'
import { TextOnlySlate } from '@components/forms/slate-textarea'

export default function StudentProfileTimeline() {
  const { student_id } = useParams()
  const { events } = useTimelineEvents(student_id)
  return (
    <aside
      className="flex-none border rounded-lg md:w-1/4 h-full lg:max-w-80 ml-auto bg-white dark:bg-gray-100 dark:border-gray-90 overflow-y-auto mb-12 divide-y dark:divide-gray-90"
      id="timeline-el"
    >
      <h3 className="font-lg font-bold p-4">Timeline</h3>
      {events?.length > 0 &&
        events.map((event) => {
          if (
            event.type === 'New Note' ||
            event.type === 'Updated Note' ||
            event.type === 'Deleted Note'
          ) {
            return (
              <div
                className="p-4"
                key={event.id}
                id={
                  event.type === 'New Note'
                    ? `timeline-${event.context.id}`
                    : event.id
                }
              >
                <h3 className="text-lg font-bold">{event.type}</h3>
                <div className="flex flex-row justify-between">
                  <span className="text-sm text-gray-50">
                    {`${event.type === 'New Note' ? `Created` : `Updated`} by: ${event.employee_first_name} ${event.employee_last_name}`}
                  </span>
                  <span className="text-sm text-gray-50">
                    {new Date(event.created_at).toLocaleDateString([], {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                </div>
                <div className="flex flex-col">
                  <h3 className="text-sm">Type: {event.context.type}</h3>
                </div>
                <SoraLink className="text-sm text-accent text-right w-full hover:underline" to={`../notes?note_id=${event.context.id}`} reloadDocument>
                  View in Table
                </SoraLink>
              </div>
            )
          }
          if (
            event.type === 'Information Update' ||
            event.type === 'Information Update (Guardian)' ||
            event.type === 'Information Update (Emergency Contact)' ||
            event.type === 'Information Update (Recovery Email)'
          ) {
            return (
              <div className="p-4" key={event.id}>
                <h3 className="text-lg font-bold">{event.type}</h3>
                <div className="flex flex-row justify-between">
                  <span className="text-sm text-gray-50">
                    {`Updated by: ${event.employee_first_name
                      ? `${event.employee_first_name} ${event.employee_last_name}`
                      : `${event.user_first_name} ${event.user_last_name}`
                      }`}
                  </span>
                  <span className="text-sm text-gray-50">
                    {new Date(event.created_at).toLocaleDateString([], {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                </div>
                <div className="flex flex-col">
                  {Object.keys(event.context.diff).map((key, idx) => {
                    return (
                      <span className="text-sm" key={key + idx}>
                        {key}: {key === 'profile_information' ? (
                          <TextOnlySlate value={event.context.diff[key]} />
                        ) : (
                          event.context.diff[key]
                        )}
                      </span>
                    )
                  })}
                </div>
              </div>
            )
          }
          if (event.type === 'Suspension Update') {
            return (
              <div className="p-4" key={event.id}>
                <h3 className="text-lg font-bold">{event.type}</h3>
                <div className="flex flex-row justify-between">
                  <span className="text-sm text-gray-50">{`Updated by: ${event.employee_first_name} ${event.employee_last_name}`}</span>
                  <span className="text-sm text-gray-50">
                    {new Date(event.created_at).toLocaleDateString([], {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                </div>
                <div className="flex flex-col">
                  {Object.values(event.context).map((val, idx) => {
                    return (
                      <span className="text-sm" key={event.id + idx}>
                        {val}
                      </span>
                    )
                  })}
                </div>
              </div>
            )
          }
          if (event.type === 'Exemption Update') {
            const exemptions = event.context['world languages'] || event.context.electives || event.context || {}
            return (
              <div className="p-4" key={event.id}>
                <h3 className="text-lg font-bold">{event.type}</h3>
                <div className="flex flex-row justify-between">
                  <span className="text-sm text-gray-50">{`Updated by: ${event.employee_first_name} ${event.employee_last_name}`}</span>
                  <span className="text-sm text-gray-50">
                    {new Date(event.created_at).toLocaleDateString([], {
                      month: 'short',
                      day: 'numeric',
                    })}
                  </span>
                </div>
                <div className="flex flex-col text-sm">{
                  Object.values(exemptions).map((exemption, i) => {
                    if (!Object.entries(exemption).length) return <span key={i}>Exemption removed</span>
                    return Object.entries(exemption).map(([k], ii) => (
                      <span className="text-sm" key={i + ii}>
                        {k}: {Array.isArray(exemption[k]) ? exemption[k].map(u => u?.title || u?.id).join(', ') : ''}
                      </span>
                    ))
                  })}
                </div>
              </div>
            )
          }
        })}
    </aside>
  )
}
