import React from 'react'
import { unstable_Tooltip as Tooltip, cn } from '@design-system'
import { Student } from './types'

interface CellRequestListProps extends Pick<Student, 'requestListStem' | 'requestListStemCount' | 'requestListHumanities' | 'requestListHumanitiesCount'> { }

export const CellRequestList = ({ requestListStem, requestListStemCount, requestListHumanities, requestListHumanitiesCount }: CellRequestListProps) => {
  return (
    <ul className="flex flex-col">
      <Tooltip content={requestListStemCount === 0 ? "0 STEM expeditions on request list." :
        <ul className={cn(requestListStem?.length > 1 && "text-center")}>
          {requestListStem?.map((subject) => (
            <li key={subject}>{subject}</li>
          ))}
        </ul>
      }>
        <li>
          {requestListStemCount} STEM
        </li>
      </Tooltip>
      <Tooltip content={requestListHumanitiesCount === 0 ? "0 Humanities expeditions on request list." :
        <ul className={cn(requestListHumanities?.length > 1 && "text-center")}>
          {requestListHumanities?.map((subject) => (
            <li key={subject}>{subject}</li>
          ))}
        </ul>
      }>
        <li>
          {requestListHumanitiesCount} Humanities
        </li>
      </Tooltip>
    </ul>
  )
}
