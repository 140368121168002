import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { SchoolStageTag } from './school-stage-tag'
import { CampusTag } from './campus-tag'
import { MathPlacementSelect } from './math-placement-select'
import { StudentTimeline } from './student-timeline'
import { useFetcher } from 'react-router'
import { Button } from '@design-system'

export const StudentRow = ({ cycleId, student, isReadOnly = true }) => {
  const fetcher = useFetcher()
  const formId = `student_detail_${student.id}`

  const isLoading = fetcher.state !== 'idle' && Number(fetcher.formData?.get('student_id')) === Number(student.id)

  useEffect(() => {
    if (fetcher.data?.timeline) {
      fetcher.submit(document.getElementById(formId))
    }
  }, [cycleId])

  return (
    <>
      <tr className='hover:bg-alpha/10'>
        <td className='p-2'>
          <SchoolStageTag schoolStage={student.school_stage} />
        </td>
        <td className='p-2'>
          <CampusTag index={student.campus_index} title={student.campus} />
        </td>
        <td className='p-2'>{student.house}</td>
        <td className='p-2 flex flex-row items-center justify-between gap-2'>
          <span>{`[${student.id}] ${student.name}`}</span>
          {!fetcher.data?.timeline &&
            <fetcher.Form id={formId}  method="GET" className={`${fetcher.data?.timeline ? 'hidden' : 'block' }`}>
              <input type='hidden' name='_action' value='get_student_math_placement' />
              <input type='hidden' name='student_id' value={student.id} />
              <input type='hidden' name='cycle_id' value={cycleId} />
              <Button type='submit' size='xs' variant='outlined' disabled={isLoading} loading={isLoading}>Details</Button>
            </fetcher.Form>
          }
          {fetcher.data?.timeline &&
            <fetcher.Form method="GET">
              <input type="hidden" name="_action" value="clean" />
              <Button size='xs' variant='outlined'>Hide</Button>
            </fetcher.Form>
          }
        </td>
        <td className='p-2'>{student.expected_graduation.substring(0, 10)}</td>
        <td className='p-2'>
          <MathPlacementSelect studentId={student.id} cycleId={cycleId} currentPlacement={student.timeline_defined} selectedId={student.timeline_id} canEdit={!isReadOnly} />
        </td>
      </tr>
      <tr>
        <td colSpan={6}>
          {(!isLoading && fetcher.data?.timeline) &&
            <div className='overflow-auto border-alpha/10 border-b-3 border-t-3'>
              <StudentTimeline columns={fetcher.data?.timeline.columns} rows={fetcher.data?.timeline.rows} />
            </div>
          }
        </td>
      </tr>
    </>
  )
}
StudentRow.propTypes = {
  cycleId: PropTypes.number,
  student: PropTypes.object,
  isReadOnly: PropTypes.bool,
}
