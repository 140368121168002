import React from 'react'
import axios from 'axios'
import { Form, Outlet, redirect, useLocation } from 'react-router'
import { Button, Icon, Tabs } from '@design-system'
import type { StudentInfo } from '@features/workbench.onboarding.families.$campusFilter'

const downloadCSVTemplate = (data: StudentInfo[]) => {
  const element = document.createElement('a')
  const guardianFieldsLength = Math.max(...data.map((student) => student.guardians.length))
  const guardianFields = Array.from({ length: guardianFieldsLength }, (_, i) => `guardianId${i + 1},guardianName${i + 1},guardianEmail${i + 1},guardianStatus${i + 1},guardianLastCompletedChecklist${i + 1}`)
  const csvContent = [
    ['id', 'name', 'soraEmail', 'gender', 'grade', 'state', 'campus', 'transcriptStatus', 'onboardingStatus', 'studentLastCompletedChecklist', 'startDate', ...guardianFields].join(','),
    ...data.map(student => [
      student.id,
      student.name,
      student.soraEmail,
      student.gender,
      student.grade,
      student.state,
      student.campus,
      student.transcriptStatus ?? '',
      student.onboardingStatus ?? '',
      student.lastCompletedChecklist ?? '',
      student.startDate ?? '',
      ...student.guardians.map(guardian => {
        const sgStatus = student.guardianStatuses.find((gs) => gs.name === guardian.name)
        return [
          guardian.id,
          guardian.name,
          guardian.email,
          sgStatus?.status ?? '',
          sgStatus?.lastCompletedChecklist ?? '',
        ]
      }).flat()
    ].join(','))
  ].join('\n');

  const csvBlob = new Blob([csvContent], { type: 'text/csv' });
  element.href = window.URL.createObjectURL(csvBlob)
  element.setAttribute('download', 'onboarding-families.csv')
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}


async function action({ request }) {
  const res = await axios.get('/backoffice/workbench/onboarding')
  const formData = await request.formData()
  const currentLocation = formData.get('currentLocation')
  downloadCSVTemplate(res.data)
  return redirect(currentLocation ?? '/workbench/onboarding/families/-1')
}

function Element() {
  const location = useLocation()

  return (
    <section className="flex flex-col space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-5xl font-bold">Onboarding</h1>
        {location.pathname.includes('families/') && (
          <Form method="POST">
            <input type="hidden" name="currentLocation" defaultValue={location.pathname + location.search} />
            <Button type="submit" variant="ghost">Download List (CSV)<Icon name="file-download" /> </Button>
          </Form>
        )}
      </div>
      <Tabs>
        <Tabs.Item to="./families/-1" title="Families" />
        <Tabs.Item to="./groups/0" title="Onboarding Groups" />
      </Tabs>
      <Outlet />
    </section>
  )
}

export const WorkbenchOnboardingRoute = { action, Element }