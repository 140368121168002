import React from 'react'
import PropTypes from 'prop-types'
import {
  getExemptionText,
  gradeNumberForELA,
} from '../exemptions.utils'
import { Button } from '@designsystem'
import { Form } from 'react-router'

export default function ExemptionCard({
  topic,
  subject,
  exemption,
  toggleEditing,
  isStaticExemption,
  isReadOnly,
}) {
  return (
    <div className="p-4 bg-white border w-full mb-2">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row space-x-2">
          <h3 className="align-middle text-lg font-bold whitespace-nowrap">
            {isStaticExemption
              ? exemption.course
              : topic.title +
              (subject === 'ELA' && !topic.is_elective
                ? gradeNumberForELA(topic.title)
                : '')}
            : {getExemptionText(exemption.credit)}
          </h3>
          {exemption.is_elective && (
            <span className="rounded-md py-0.5 px-2 bg-blue-40 text-xs text-center self-start text-white ml-2">
              Elective
            </span>
          )}
        </div>
        {!isReadOnly && (
          <div className="flex flex-col items-end space-y-1">
            <Button
              variant="outlined"
              disabled={isStaticExemption}
              onClick={toggleEditing}
            >
              Edit
            </Button>
            {isStaticExemption && (
              <div className="text-xs text-gray-50">Create as an External Outside Elective</div>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex flex-row space-x-4">
          <div className="flex flex-col">
            <h4 className="text-xs font-bold">Grade</h4>
            <span>{exemption.grade}</span>
          </div>
          {!isStaticExemption && (
            <div className="flex flex-col">
              <h4 className="text-xs font-bold">Course</h4>
              <span>{exemption.course}</span>
            </div>
          )}
          <div className="flex flex-col">
            <h4 className="text-xs font-bold">School</h4>
            <span>{exemption.school_name}</span>
          </div>
          <div className="flex flex-col">
            <h4 className="text-xs font-bold">Year</h4>
            <span>{exemption.year}</span>
          </div>
        </div>
        {!isReadOnly && (
          <Form method="POST">
            <input type="hidden" name="_action" value={isStaticExemption ? 'deleteStaticExemption' : 'deleteExemption'} />
            <input type="hidden" name="id" value={exemption.id} />
            <Button
              type="submit"
              color="danger"
              variant="outlined"
            >
              Remove
            </Button>
          </Form>
        )}
      </div>
      {!isStaticExemption && (
        <>
          <h4 className="font-bold text-md mt-2">Exempted Units:</h4>
          <ul
            className="columns-3 list-disc ml-3"
            aria-label={exemption.topic_name + ' exempted units'}
          >
            {topic?.units.filter(u => u.is_exempt).map((exemptedUnit) => (
              <li key={exemptedUnit.title}>
                <p>{exemptedUnit.title}</p>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

ExemptionCard.propTypes = {
  subject: PropTypes.string,
  exemption: PropTypes.object,
  toggleEditing: PropTypes.func,
  onRemoveExemption: PropTypes.func,
  isStaticExemption: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  topic: PropTypes.object,
}
