import React from 'react'
import axios from 'axios'
import { useLoaderData, useParams, useSearchParams } from 'react-router'
import { Card, Typography, cn, Accordion, Alert, Button } from '@design-system'
import { PillTask, PillTaskVariant } from '@blocks/pill-task'
import { PillPerks } from '@blocks/pill-perks'
import SoraLink from '@components/link'
import { CardGraduationProgress, type CardGraduationProgressVariant } from '@blocks/card-graduation-progress'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { ExperienceCategoryVariant, PillExperienceCategory } from '@blocks/pill-experience-category'
import { PathwayVariant, PillPathway } from '@blocks/pill-pathway'

interface StudentRouteOverviewProps {
  overview: {
    expeditionAttendancePresent: number
    expeditionAttendanceTotal: number
    houseEventsAttendancePresent: number
    houseEventsAttendanceTotal: number
    taskCompletionSubmitted: number
    taskCompletionTotal: number
    graduationStatus?: CardGraduationProgressVariant
    graduationStatusUrl: string
  }
  experiences: {
    id: number
    imageUrl: string
    title: string
    category: string
    pathway: string
    employees: { id: number, name: string }[]
    learningBlockTitle: string
    summary: { label: string, value: string | number }[]
    units: { id: number, title: string, variant?: 'new' }[]
    abilities: { id: number, title: string, variant?: 'new' }[]
    tasks: { id: number, title: string, status: PillTaskVariant, hasExtendedDeadline: boolean, isFlagged: boolean, showLatePill: boolean, experienceId: number, studentId: number }[]
    finalTaskStatus?: PillTaskVariant
  }[]
  potentialIssues: string[]
}

export async function loader({ request, params }) {
  const cycleId = new URL(request.url).searchParams.get('cycle_id')
  const { data } = await axios.get(`/backoffice/employee/students/${params.studentId}/overview`, { params: { cycle_id: cycleId } })
  return data
}

function Element() {
  const data = useLoaderData() as StudentRouteOverviewProps
  const [searchParams] = useSearchParams()
  const { student_id } = useParams()
  const issueAlertKey = `${student_id}_${searchParams.get('cycle_id')}`
  const [issueAlert, setIssueAlert] = useLocalStorage('closed_issue_alerts')
  
  return (
    <div className="space-y-8">
      <section className="space-y-8">
        <Typography variant="heading-6" weight="bold">Overview</Typography>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Card>
            <Card.Content>
              <Typography variant="heading-6" weight="bold">{data.overview.expeditionAttendancePresent}/{data.overview.expeditionAttendanceTotal}</Typography>
              <Typography variant="subheadline">Expedition Attendance</Typography>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Typography variant="heading-6" weight="bold">{data.overview.houseEventsAttendancePresent}/{data.overview.houseEventsAttendanceTotal}</Typography>
              <Typography variant="subheadline">House Events Attendance</Typography>
            </Card.Content>
          </Card>
          <Card>
            <Card.Content>
              <Typography variant="heading-6" weight="bold">{data.overview.taskCompletionSubmitted}/{data.overview.taskCompletionTotal}</Typography>
              <Typography variant="subheadline">Task Submission</Typography>
            </Card.Content>
          </Card>
          <CardGraduationProgress variant={data.overview.graduationStatus} url={data.overview.graduationStatusUrl} />
        </div>
      </section>
      <section className="space-y-8">
        <Typography variant="heading-6" weight="bold">Experiences</Typography>
        {(data.potentialIssues.length > 0 && !issueAlert?.[issueAlertKey]) && (
          <Alert variant="notice">
            <Alert.Title>There are potential issues with student schedule:</Alert.Title>
            <Alert.Description>
              <ul className="list-disc ml-4">
                {data.potentialIssues.map((issue) => (
                  <li key={issue}>{issue}</li>
                ))}
              </ul>
            </Alert.Description>
            <Alert.Actions>
              <Button size="xs" asChild>
                <SoraLink to="https://support.soraschools.com/en/articles/10064742-student-overview-alerts-house-advisor-guide" target="_blank" rel="noopener noreferrer">
                  Learn more
                </SoraLink>                
              </Button>
              <Button variant="ghost" size="xs" onClick={() => setIssueAlert({ ...issueAlert, [issueAlertKey]: true })}>Dismiss</Button>
            </Alert.Actions>
          </Alert>
        )}
        <Accordion type="single" collapsible>
          {data.experiences.map(({ id, imageUrl, title, employees, learningBlockTitle, summary, units, abilities, tasks, finalTaskStatus, category, pathway }) => (
            <Accordion.Item key={id} value={String(id)}>
              <Accordion.Header>
                <Accordion.Trigger className={cn(finalTaskStatus ? "items-center" : "flex-col items-start lg:flex-row lg:items-center")}>
                  <img src={imageUrl} alt={title} className="hidden md:flex w-24 lg:w-36 aspect-video rounded-sm object-cover object-center" />
                  <div className="grow">
                    <Typography variant="subheadline" weight="bold">{title}</Typography>
                    <Typography>
                      {employees.map((employee) => employee.name).join(', ')} {learningBlockTitle && `/ ${learningBlockTitle}`}
                    </Typography>
                    <div className='flex flex-row gap-2 items-center'>
                      <PillExperienceCategory variant={category as ExperienceCategoryVariant} />
                      {pathway && <PillPathway variant={category as PathwayVariant} title={pathway} />}
                    </div>
                  </div>
                  {finalTaskStatus ? (
                    <div className="flex flex-col items-center gap-1 shrink-0">
                      <Typography variant="callout" weight="bold">Final Task</Typography>
                      <PillTask variant={finalTaskStatus} />
                    </div>
                  ) : (
                    <ul className="shrink-0 flex flex-col lg:flex-row text-center lg:divide-x">
                      {summary.map(({ label, value }) => (
                        <li key={label} className={cn("lg:px-4 flex lg:flex-col gap-1", value === 0 && "opacity-30")}>
                          <Typography variant="callout" weight="bold">{value}</Typography>
                          <Typography variant="callout">{label}</Typography>
                        </li>
                      ))}
                    </ul>
                  )}
                  <Accordion.Icon />
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className={cn("grid gap-4 my-4", units.length > 0 && abilities.length > 0 && "lg:grid-cols-2")}>
                  {units.length > 0 && (
                    <Card>
                      <Card.Content className="space-y-4">
                        <Typography variant="subheadline" weight="bold">Units ({units.length})</Typography>
                        <div className="flex flex-wrap gap-2">
                          {units?.map(({ id, title, variant }) => (
                            <PillPerks key={id} variant={variant}>{title}</PillPerks>
                          ))}
                        </div>
                      </Card.Content>
                    </Card>
                  )}
                  {abilities.length > 0 && (
                    <Card>
                      <Card.Content className="space-y-4">
                        <Typography variant="subheadline" weight="bold">Abilities ({abilities.length})</Typography>
                        <div className="flex flex-wrap gap-2">
                          {abilities?.map(({ id, title, variant }) => (
                            <PillPerks key={id} variant={variant}>{title}</PillPerks>
                          ))}
                        </div>
                      </Card.Content>
                    </Card>
                  )}
                </div>
                <ul>
                  {tasks?.map(({ id, title, status, hasExtendedDeadline, isFlagged, showLatePill, experienceId, studentId }) => (
                    <li key={id} className="flex justify-between items-center gap-2 py-3 border-t first:border-0 border-gray-30 dark:border-gray-90">
                      <Typography weight="bold" color={status === 'pending' ? "tertiary" : undefined} className="grow hover:underline" asChild>
                        <SoraLink to={`/facilitate/expeditions-learning-goals/${experienceId}/tasks/${id}/students/${studentId}`}>
                          {title}
                        </SoraLink>
                      </Typography>
                      {hasExtendedDeadline && <PillTask variant="extended-deadline" />}
                      {isFlagged && <PillTask variant="flagged" />}
                      {showLatePill && <PillTask variant="late" />}
                      <PillTask variant={status} />
                    </li>
                  ))}
                </ul>
              </Accordion.Content>
            </Accordion.Item>
          ))}
        </Accordion>
      </section>
    </div >
  )
}

export const EmployeeStudentOverviewRoute = {
  Element,
  loader
}


