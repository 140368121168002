import React from 'react'
import axios from 'axios'
import { Link, Outlet, useLoaderData } from 'react-router'
import { Button, Icon, Typography, unstable_Popover as Popover, useFetcher } from '@design-system'
import serializeFormData from '@utils/serializeFormData'
import { RenderedSlate } from '@components/forms/slate-textarea'

interface LoaderData {
  id: number,
  title: string,
  blocked: boolean,
  description: Array<any>,
  dueDate: string,
  todos: Array<{
    id: number,
    title: string,
    description: string,
    actions: Array<{ title: string, redirectTo: string }> | null
  }>
}

async function loader({ params }) {
  const result = await axios.get(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/${params.user_view_type}`)
  return result.data
}

async function action({ request, params }) {
  const formData = serializeFormData(await request.formData())
  try {
    const res = await axios.post(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/${params.user_view_type}`, formData)
    return res.data
  } catch (error) {
    return error.response.data || {
      toast: {
        message: 'Something went wrong. Please try again later.',
        appearance: 'error',
      }
    }
  }
}

function Element() {
  const fetcher = useFetcher()
  const loaderData = useLoaderData() as LoaderData

  return (
    <div className="flex flex-col space-y-6 justify-start">
      <Typography variant="body">{loaderData.description}</Typography>
      <span className="self-start">
        <Button asChild>
          <Link to="./todo/new">Create a Task</Link>
        </Button>
      </span>
      <div className="flex flex-col space-y-2">
        <Typography variant="callout" weight="bold">TASKS</Typography>
        {loaderData.todos?.map((todo) => (
          <div key={`todo-${todo.id}`} className="px-4 py-6 flex rounded-lg border bg-white border-gray-20 flex-col">
            <div className="flex items-center justify-between">
              <Typography variant="body" weight="bold">{todo.title}</Typography>
              <Popover>
                <Popover.Trigger onClick={(e) => e.stopPropagation()}>
                  <Button asChild variant="ghost">
                    <Icon name="more-horiz-filled"></Icon>
                  </Button>
                </Popover.Trigger>
                <Popover.Content className="flex flex-col gap-1" align="center">
                  <Link to={`./todo/${todo.id}`} className="py-1 hover:font-bold w-24 bg-white">
                    Edit Task
                  </Link>
                  <fetcher.Form method="POST" className="py-1 hover:font-bold hover:text-danger-40 w-24 bg-white">
                    <button
                      type="submit"
                      value={todo.id}
                      name="id"
                    >
                      Delete Task
                    </button>
                  </fetcher.Form>
                </Popover.Content>
              </Popover>
            </div>
             {/* @ts-ignore component below is not written in ts so it will yell errors */}
            <RenderedSlate value={todo.description} />
            {todo.actions?.map((action) => (
              <a
                key={`${todo.id}-action-${action.title}`}
                href={action.redirectTo}
                target="_blank"
                className="text-accent underline font-bold"
              >
                {action.title}
              </a>
            ))}
          </div>
        ))}
        <Outlet />
      </div>
    </div>
  )
}

export const OnboardingUserTypeRoute = {
  loader,
  action,
  Element,
}