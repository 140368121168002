import React from 'react'
import { Form, Outlet, redirect, useLoaderData, useSearchParams, useSubmit } from 'react-router'
import axios from 'axios'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { format } from 'date-fns'
import { unstable_Datepicker as Datepicker } from '@design-system'

type LoaderData = {
  formErrors: Record<string, string>,
  initialDate: string,
  minDate: string,
  maxDate: string,
  availableDates: string[],
  sessionId: number,
}

async function loader({ request, params }) {
  const searchParams = new URL(request.url).searchParams
  const { data } = await axios.get(`/backoffice/facilitate/${params.type}/${params.experienceId}/attendance/default?${searchParams.toString()}`)
  if (data.sessionId && Number(params.sessionId) !== Number(data.sessionId)) {
    return redirect(`${data.sessionId}?${searchParams.toString()}`)
  } else if (params.eventId) {
    return redirect(`.?${searchParams.toString()}`)
  }
  return data
}

function Element() {
  const {
    initialDate,
    minDate,
    maxDate,
    availableDates,
    formErrors,
  } = useLoaderData() as LoaderData
  const submit = useSubmit()
  const [searchParams] = useSearchParams()
  const cycleId = searchParams.get('cycle_id')

  return (
    <>
      <Form method="GET" replace className="flex" onChange={e => submit(e.currentTarget)}>
        {cycleId && <input type='hidden' name='cycle_id' value={cycleId} />}
        <Datepicker
          name='date'
          error={formErrors['date']}
          defaultSelected={utcToZonedTime(initialDate, Intl.DateTimeFormat().resolvedOptions().timeZone)}
          maxDate={new Date(maxDate)}
          minDate={new Date(minDate)}
          placeholderText="Pick a date"
          filterDate={(date) => availableDates.includes(format(date, 'yyyy-MM-dd'))}
        />
      </Form>
      <Outlet />
    </>
  )
}

export const FacilitateExperienceAttendanceDefaultRoute = {
  loader,
  Element,
}
