import React, { useCallback, useState } from 'react'
import axios from 'axios'
import debounce from 'lodash/debounce'
import { useLoaderData, redirect, Form, useNavigation, useActionData } from 'react-router'
import Select from '@components/forms/select'
import SessionFields from '@components/expedition-form/sessionFields'
import {
  EXPEDITION_TYPES,
  generateSessionTimes,
  hasDST,
  EXPEDITION_CATEGORIES,
} from './utils.js'
import serializeFormData from '@utils/serializeFormData'
import { Button } from '@design-system'
import SoraLink from '@components/link'
import subHours from 'date-fns/subHours'
import { useToast } from '@hooks/useToast'
import { SessionTimes } from './session-times.jsx'

function Element() {
  const [sessionsSkippedText, setSessionsSkippedText] = useState('')
  const [sessions, setSessions] = useState([])
  const [learningBlockId, setLearningBlockId] = useState(null)

  const loaderData = useLoaderData()
  const template = loaderData.template
  const availableEmployees = loaderData.availableEmployees
  const availableCycles = loaderData.availableCycles
  const learningBlocks = loaderData.learningBlocks

  const navigation = useNavigation()
  const isFormSubmitting = navigation.state === 'submitting'

  const [formValues, setFormValues] = useState({
    cycle_id: null,
    cohort_id: null,
    picked_time: null,
    learning_block_id: null,
    sessions: loaderData.templateSessions,
    employees: [],
  })

  useToast(useActionData())

  const searchFunction = useCallback(
    (options) =>
      debounce((value, callback) => {
        const searchedOptions = options.map((option) => {
          return {
            ...option,
            options: option.options.filter(o => {
              return o.label.toUpperCase().includes(value.toUpperCase())
            }).sort((a, b) =>
              String(a.label).trim().localeCompare(String(b.label).trim())
            )
          }
        })
          .sort((a, b) =>
            String(b.label).trim().localeCompare(String(a.label).trim())
          )
        callback(searchedOptions)
      }, 500),
    []
  )

  const pickedCycle = formValues.cycle_id && availableCycles.find(c => c.cycle_id === formValues.cycle_id)
  
  const minDate = pickedCycle && new Date(pickedCycle.start_timestamp)
  const maxDate = pickedCycle && new Date(pickedCycle.end_timestamp)

  const getSessionKey = (session) => {
    return session.timestamptz.toString()
  }

  const isInvalidSession = (session) => {
    const timestamptz = session.timestamptz
    return (
      timestamptz && (
        new Date(timestamptz) > maxDate ||
        new Date(timestamptz) < minDate
      )
    )
  }

  const isFormValid = formValues.sessions
    && !formValues.sessions.some(s => isInvalidSession(s))
    && formValues.employees.length > 0

  const handleSessionRemove = (index) => () => {
    setFormValues((prevState) => {
      const updatedSessions = prevState.sessions
      updatedSessions.splice(index, 1)
      return {
        ...prevState,
        sessions: updatedSessions,
      }
    })
  }

  const handleAddEmployee = (employee) => {
    setFormValues((prevState) => {
      const wasPreviouslyPicked = prevState.employees.find(
        ({ value }) => value === employee.value
      )
      if (wasPreviouslyPicked) return prevState
      const newEmployees = [...prevState.employees, employee]
      return {
        ...prevState,
        employees: newEmployees,
      }
    })
  }

  const selectedEmployeesIds = formValues.employees.map(({ value }) => value)
  const availableEmployeesOptions = availableEmployees
    .filter(em => selectedEmployeesIds.indexOf(em.id) === -1)

  const availableTemplateEmployees = availableEmployeesOptions
    .filter(e => e.is_template_employee)
    .map(({ id, job_title, first_name, last_name }) => ({
      value: id,
      label: `${job_title}: ${first_name} ${last_name}`,
    }))
  const availableOtherEmployees = availableEmployeesOptions
    .filter(e => !e.is_template_employee)
    .map(({ id, job_title, first_name, last_name }) => ({
      value: id,
      label: `${job_title}: ${first_name} ${last_name}`,
    }))

  const employeesOptions = [
    {
      label: 'Template Employees',
      options: availableTemplateEmployees
    },
    {
      label: 'Other Employees',
      options: availableOtherEmployees
    }
  ]

  const studentOptions = [
    {
      label: 'All Students',
      options: loaderData.studentOptions,
    }
  ]

  const handleReactSelectChange = (name) => ({ value, ...selectProps }) => {
    setFormValues((prevState) => {
      let nextState = { ...prevState, [name]: value }
      if (nextState[name].value !== value && name === 'session_time') {
        nextState.learning_block_id = selectProps.learning_block_id || null
        const { generatedSessionsTimesArray, pushedSessions } = generateSessionTimes({
          cycleStart: minDate,
          desiredSessions: nextState.sessions.length,
          sessionTimes: nextState.session_time,
        })
        setSessionsSkippedText(parseObservationText(pushedSessions))
        nextState.sessions = nextState.sessions.map((session, index) => {
          return {
            ...session,
            timestamptz: generatedSessionsTimesArray[index],
          }
        })
      }
      return nextState
    })
  }

  const getEndCycleOptions = () => {
    const selectedStartCycleIndex = availableCycles.findIndex(({ cycle_id }) => cycle_id === formValues.cycle_id)
    const availableCyclesAfterStartCycle = availableCycles.slice(0, selectedStartCycleIndex + 1)
    return availableCyclesAfterStartCycle.map(({ cycle_id, title }) => {
      return { value: cycle_id, label: title }
    })
  }

  return (
    <div className="space-y-4 pb-20 min-w-250">
      <h3 className="text-5xl p-2">Picking: <span className="font-bold">{template.title}</span></h3>
      <Form
        method="POST"
        className="space-y-4 bg-white p-4 rounded-lg flex flex-col"
      >
        <input type="hidden" name="learning_block_id" value={learningBlockId} />
        <div className="flex flex-row w-full space-x-4">
          <div
            className={`w-full grid ${template.type === 'expedition'
              ? 'grid-cols-3'
              : 'grid-cols-2'
              } gap-2`}
          >
            <label className="w-full">
              <h3 className="mx-3 self-center font-bold text-xl">Type</h3>
              <input type="hidden" name="type" value={template.type} />
              <Select
                defaultValue={EXPEDITION_TYPES.find(t => t.value === template.type)}
                isDisabled
              />
            </label>
            {template.type === 'expedition' && (
              <label className="w-full">
                <h3 className="mx-3 self-center font-bold text-xl">Category</h3>
                <input type="hidden" name="category" value={template.category} />
                <Select
                  defaultValue={EXPEDITION_CATEGORIES.find(t => t.value === template.category) || { value: null, label: 'None' }}
                  isDisabled
                />
              </label>
            )}
            <label>
              <h3 className="mx-3 self-center font-bold text-xl">School Stage</h3>
              <Select
                value={{ value: template.school_stage, label: template.school_stage === 'ms' ? 'Middle School' : 'High School' }}
                placeholder="School Stage"
                isDisabled
              />
            </label>
          </div>
        </div>
        <div className="flex gap-2">
          <label className="w-full flex flex-col justify-between">
            <h3 className="ml-3 font-bold text-xl">{template.type === 'dual_enrollment' ? 'Start Cycle' : 'Cycle'}</h3>
            <Select
              name="cycle_id"
              placeholder="Pick cycle"
              defaultValue={null}
              onChange={handleReactSelectChange('cycle_id')}
              options={availableCycles.map(({ cycle_id, title }) => {
                return { value: cycle_id, label: title }
              })}
            />
          </label>
          {template.type === 'dual_enrollment' && (
            <label className="w-full flex flex-col justify-between">
              <h3 className="ml-3 font-bold text-xl">End Cycle</h3>
              <Select
                name="end_cycle_id"
                placeholder="Pick cycle"
                defaultValue={null}
                onChange={handleReactSelectChange('end_cycle_id')}
                options={getEndCycleOptions()}
              />
            </label>
          )}
          {loaderData.templateSessions.length > 0 && (
            <SessionTimes cycleId={formValues.cycle_id} learningBlocks={learningBlocks} setLearningBlockId={setLearningBlockId} setSessions={setSessions} />
          )}
          <label className="w-full flex flex-col justify-between">
            <h3 className="ml-3 font-bold text-xl">Employees</h3>
            <Select
              options={employeesOptions}
              isMulti
              name="employee_id"
              onChange={handleAddEmployee}
              placeholder="Type or select the employee"
              searchFunction={searchFunction(employeesOptions)}
              GroupHeaderComponent={data => (
                <div className={`flex content-center justify-between text-md font-bold`}>
                  <span>{data?.label}</span>
                  <span className="bg-gray-10 h-4 w-4 rounded-full text-center align-middle">
                    {data?.options.length}
                  </span>
                </div>
              )}
            />
          </label>
          {template.type === 'dual_enrollment' && (
            <label className="w-full flex flex-col justify-between">
              <h3 className="ml-3 font-bold text-xl">Student</h3>
              <Select
                options={studentOptions}
                name="student_id"
                onChange={handleReactSelectChange('student_id')}
                placeholder="Type or select the student"
                searchFunction={searchFunction(studentOptions)}
              />
            </label>
          )}
        </div>
        {sessionsSkippedText && <span className="italic text-sm font-bold">{sessionsSkippedText}</span>}
        {sessions?.length > 0 && (
          <label>
            <h3 className="ml-3 font-bold text-xl mb-2">Sessions</h3>
            <div className="space-y-4">
              {sessions?.filter(s => Boolean(s.timestamptz)).map((session, i) => (
                <SessionFields
                  key={getSessionKey(session)}
                  name={`sessions[${getSessionKey(session)}]`}
                  values={session}
                  maxDate={maxDate}
                  minDate={minDate}
                  isInTemplateSessionTimePick
                  sessionPosition={i}
                  onRemove={handleSessionRemove(i)}
                />
              ))}
            </div>
          </label>
        )}
        <div className="self-end space-x-4">
          <Button variant="ghost" asChild>
            <SoraLink to="../library">
              Back to list
            </SoraLink>
          </Button>
          <Button
            type="submit"
            disabled={!isFormValid || isFormSubmitting}
            loading={isFormSubmitting}
          >
            {isFormSubmitting ? 'Submitting' : 'Submit'}
          </Button>
        </div>
      </Form>
    </div>
  )
}

const parseObservationText = (pushedSessions) => {
  if (!pushedSessions || pushedSessions.length === 0) return null
  const sessionDates = pushedSessions.map((d) => d.toLocaleDateString([], { day: 'numeric', month: 'short' }))
  if (sessionDates.length === 1) return `Session from this expedition was pushed due to holidays on ${sessionDates.join('')}`
  const lastHoliday = sessionDates.pop()
  return `*Sessions from this expedition were pushed due to holidays on ${sessionDates.join(',  ')} and ${lastHoliday}`
}

const loader = async ({ params, request }) => {
  const searchParams = new URL(request.url).searchParams
  const action = searchParams.get('_action')
  const targetCycleId = searchParams.get('target_cycle_id')
  const learningBlockId = searchParams.get('learning_block_id')

  const { data } = await axios.get(`/backoffice/template/${params.templateId}/use-picked-template`, { params: { targetCycleId, action, learningBlockId } })
  return data
}

const action = async ({ request, params }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)

  const sessions = serializedFormData.sessions ? Object.values(serializedFormData.sessions).map(s => {
    const date = new Date(s.timestamptz)
    const isDST = hasDST(date)
    return {
      ...s,
      timestamptz: isDST ? subHours(date, 1).toISOString() : date.toISOString(),
      sequence_number: Number(s.sequence_number),
      rte_description: JSON.parse(s.rte_description),
    }
  }) : []
  const employeesIds = formData.getAll('employee_id').map(Number)

  const {
    category,
    cycle_id: cycleId,
    end_cycle_id: endCycleId,
    cohort_id: cohortId,
    learning_block_id: learningBlockId,
    student_id: studentId,
  } = serializedFormData


  const templateId = params.templateId
  const parsedData = {
    templateId,
    category,
    sessions,
    cycleId,
    endCycleId,
    cohortId,
    learningBlockId,
    employeesIds,
    studentId,
  }

  try {
    const { data } = await axios.post(`/backoffice/template/${templateId}/use-picked-template`, parsedData)
    if (data.experienceId) {
      return redirect(`/experiences/${data.experienceId}/edit?cycle_id=${cycleId}`)
    }
    return redirect(`/employee/expeditions/edit?cycle_id=${cycleId}`)
  } catch (e) {
    console.log(e)
    return {
      toast: {
        message: e?.response?.data?.message || 'There was an error while converting this template.',
        appearance: 'error'
      }
    }
  }
}

export const UsePickedTemplateRoute = {
  loader,
  Element,
  action,
}
