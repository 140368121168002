import React from 'react'
import PropTypes from 'prop-types'
import { Button, useFetcher } from '@design-system'

export const OrphanEventRemovalSync = ({ cycleId, isProcessing = false }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' || isProcessing

  return (
    <fetcher.Form method="POST">
      <input type="hidden" name="_action" value="orphan_removal_sync" />
      <input type="hidden" name="cycleId" value={cycleId} />
      <Button type='submit' loading={isLoading} disabled={isLoading}>
        Remove orphan events   
      </Button>
    </fetcher.Form>
  )
}
OrphanEventRemovalSync.propTypes = {
  cycleId: PropTypes.number.isRequired,
  isProcessing: PropTypes.bool,
}
