import React from 'react'
import axios from 'axios'
import { Form, Link, Outlet, useActionData, useLoaderData, useNavigate, useSubmit } from 'react-router'
import useConfirmModal from '@hooks/useConfirmModal'
import serializeFormData from '@utils/serializeFormData'
import formatDateForOnboarding from '@components/onboarding/utils/formatDateForOnboarding'
import { useToast } from '@hooks/useToast'
import { Typography, unstable_Select as Select, Button, Icon, unstable_Popover as Popover } from '@design-system'

interface Group {
  id: number,
  title: string,
  checklistInfo: Array<{
    id: number,
    title: string,
    total: number,
    released: number,
  }>
}

interface LoaderData {
  groups: Record<string, Group[]>,
  cycles: Array<{
    value: string,
    label: string,
  }>
}

interface ActionData {
  toast: {
    message: string,
    appearance: 'success' | 'error'
  }
}

async function loader({ params, request }) {
  const url = new URL(request.url)
  const searchParams = url.searchParams
  const res = await axios.get(`/backoffice/workbench/onboarding/groups/${params.campusId}`, {
    params: searchParams
  })
  return res.data
}

async function action({ request, params }) {
  const formData = serializeFormData(await request.formData())
  try {
    await axios.post(`/backoffice/workbench/onboarding/groups/${params.campusId}`, formData)
    return {
      toast: { message: 'Group deleted', appearance: 'success' },
    }
  } catch (error) {
    return {
      toast: { message: 'Failed to delete group', appearance: 'error' },
    }
  }
}

function Element() {
  const submit = useSubmit()
  const navigate = useNavigate()
  const confirm = useConfirmModal()
  const loaderData = useLoaderData() as LoaderData

  useToast(useActionData() as ActionData)

  const handleDeleteGroup = async (groupId: number) => {
    if (await confirm({
      title: 'Are you sure you want to delete this group?',
      subtitle: 'This will also delete all the checklists, tasks and remove all the users from that group.'
    }))
      submit({ group_id: String(groupId) }, { method:'POST' })
  }

  return (
    <div className="flex flex-col justify-start space-y-6">
      <Form method="GET" className="w-64 mt-6">
        <Select name="start_date" onValueChange={(value) => submit({ start_date: value }, { method: 'GET' })}>
          <Select.Trigger>
            <Select.Value placeholder="School Start Date" />
          </Select.Trigger>
          <Select.Content>
            {loaderData.cycles.map((cycle) => (
              <Select.Item key={cycle.value} value={String(cycle.value)}>
                {cycle.label}
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Form>
      <span>
        <Button asChild>
          <Link to="new">Create Onboarding</Link>
        </Button>
      </span>
      <div className="space-y-8">
        {Object.keys(loaderData.groups).map((key) => (
          <div key={`group_starting_${key}`} className="space-y-4">
            <Typography variant="heading-3">School starting on <b>{formatDateForOnboarding(key)}</b></Typography>
            <div className="flex space-x-8">
              {loaderData.groups[key].map((group) => (
                <div
                  key={`group_${group.id}`}
                  onClick={() => {
                    navigate(`../../../onboarding/${group.id}/content`)
                  }}
                  className="rounded-lg cursor-pointer flex flex-col space-y-4 p-5 border bg-white border-gray-15 w-64"
                >
                  <div className="flex justify-between items-center w-full">
                    <Typography variant="heading-4">{group.title}</Typography>
                    <Popover>
                      <Popover.Trigger onClick={(e) => e.stopPropagation()}>
                        <Button asChild variant="ghost">
                          <Icon name="more-horiz-filled"></Icon>
                        </Button>
                      </Popover.Trigger>
                      <Popover.Content asChild>
                        <div className="flex flex-col gap-1">
                          <span className="py-1 hover:font-bold w-32 bg-white">
                            <Link to={`./edit/${group.id}`}>
                              Edit Group
                            </Link>
                          </span>
                          <span className="py-1 hover:font-bold text-danger-40 w-32 bg-white">
                            <button
                              onClick={(e) => {
                                e.stopPropagation()
                                handleDeleteGroup(group.id)
                              }}
                            >
                              Delete Group
                            </button>
                          </span>
                        </div>
                      </Popover.Content>
                    </Popover>
                  </div>
                  {group.checklistInfo.map((checklist) => (
                    <div key={`checklist_${checklist.id}`} className="flex flex-col space-y-1">
                      <Typography variant="body" weight="bold">{checklist.title}</Typography>
                      <Typography variant="footnote">{`${checklist.released}/${checklist.total} checklists released`}</Typography>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))
        }
      </div >
      <Outlet />
    </div >
  )
}

export const OnboardingGroupsCampusId = {
  loader,
  action,
  Element
}