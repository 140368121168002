import React from 'react'
import axios from 'axios'
import { Link, Outlet, useLoaderData, useParams } from 'react-router'

interface LabelValue {
  value: number,
  label: string,
}

async function loader() {
  const res = await axios.get(`/backoffice/workbench/onboarding/groups`)
  return res.data
}

function Element() {
  const params = useParams()
  const campus = useLoaderData() as LabelValue[]

  return (
    <div className="flex flex-col mr-[84px]">
      <div className="space-x-3 flex">
        {campus?.map((filter) => (
          <Link
            key={filter.value}
            to={String(filter.value)}
            className={`${params.campusId === String(filter.value) ? 'bg-black border-gray-100 text-white' : 'bg-gray-10 border-gray-10 text-gray-100'} rounded-lg border px-5 py-3 font-bold`}
          >
            {filter.label}
          </Link>
        ))}
      </div>
      <Outlet />
    </div>
  )
}

export const OnboardingGroupsRoute = {
  loader,
  Element,
}