import * as React from 'react'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import { useActionData, useLoaderData, useNavigate, useParams, useSubmit, Form as ReactRouterForm, useNavigation } from 'react-router'
import { TextField } from '@designsystem'
import { useToast } from '@hooks/useToast'
import { handleZodValidation } from './validation'
import serializeFormData from '@utils/serializeFormData'
import SlateTextarea from '@components/forms/slate-textarea'
import { ONE_MEGABYTE, DEFAULT_REDIRECT_TO } from './constants'
import { Todo, TaskAction, TaskActionType, UploadedFileData, formResponseTodoSchema, } from './types'
import { Button, Icon, unstable_Popover as Popover, Typography, Dialog } from '@design-system'

type LoaderData = {
  isVisibleForGuardian: boolean,
  isVisibleForStudent: boolean,
  todoData?: Todo,
}

type ToastData = {
  toast: {
    message: string,
    appearance: 'success' | 'error',
  },
  redirectUrl?: string,
}

type ActionData =
  { type: 'info', payload: ToastData } |
  { type: 'data', payload: UploadedFileData & { actionId: string } }
  | undefined

async function loader({ params }) {
  const result = await axios.get(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/${params.user_view_type}/todo/${params.todo_id}`)
  return result.data
}

async function action({ request, params }) {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  const action = serializedFormData._action

  if (action === 'upload_file') {
    const uploadType = serializedFormData.upload_type
    const file = serializedFormData.file
    if (!file) {
      return {
        type: 'info',
        payload: {
          toast: {
            message: 'Please attach a file to upload',
            type: 'error',
          }
        }
      }
    }
    if (uploadType === 'file') {
      if (file.size > 10 * ONE_MEGABYTE) {
        return {
          type: 'info',
          payload: {
            toast: {
              message: 'File is too large. Please upload a file that is less than 10MB.',
              type: 'error',
            }
          }
        }
      }
    }
    if (uploadType === 'video') {
      if (file.size > 20 * ONE_MEGABYTE) {
        return {
          type: 'info',
          payload: {
            toast: {
              message: 'Video is too large. Please upload a video that is less than 20MB.',
              type: 'error',
            }
          }
        }
      }
    }
    const res = await axios.post(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/${params.user_view_type}/todo/${params.todo_id}`, formData)
    return {
      type: 'data',
      payload: { ...res.data.uploadedFileData, actionId: formData.get('action_id') },
    }
  }


  if (serializedFormData?.actions) {
    let newValue = Array.isArray(serializedFormData.actions) ? serializedFormData.actions : Object.values(serializedFormData.actions)
    serializedFormData.actions = newValue
  }

  const validationResult = handleZodValidation({ data: serializedFormData, schema: formResponseTodoSchema })
  if (validationResult.type === 'error') {
    return {
      type: 'info',
      payload: {
        toast: {
          message: `An error ocurred during the validation of the fields. Please check the form and try again.`,
          type: 'error',
        }
      }
    }
  }

  const body = validationResult.data

  try {
    delete body.file
    await axios.post(`/backoffice/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/${params.user_view_type}/todo/${params.todo_id}`, body)
    return {
      type: 'info',
      payload: {
        redirectUrl: `/workbench/onboarding/${params.onboarding_group_id}/content/checklist/${params.checklist_id}/${params.user_view_type}`,
        toast: {
          message: 'Task created successfully',
          appearance: 'success',
        },
      },
    }
  } catch (error) {
    return {
      type: 'info',
      payload: {
        toast: {
          message: 'Something went wrong while creating this task',
          appearance: 'error',
        }
      }
    }
  }
}

function Element() {
  const params = useParams()
  const navigate = useNavigate()
  const loaderData = useLoaderData() as LoaderData
  const actionData = useActionData() as ActionData

  useToast(actionData?.payload as ToastData)
  React.useEffect(() => {
    if (actionData?.type === 'info' && actionData.payload.redirectUrl) {
      navigate(actionData.payload.redirectUrl)
    }
  }, [actionData])

  return (
    <OnboardingTodoModal
      uploadData={actionData?.type === 'data' ? actionData.payload : undefined}
      todo={loaderData.todoData}
      onClose={() => navigate('../')}
      userType={params.user_view_type}
      checklistId={Number(params.checklist_id)}
      isVisibleForGuardian={loaderData.isVisibleForGuardian}
      isVisibleForStudent={loaderData.isVisibleForStudent}
    />
  )

}

interface OnboardingTodoModalProps {
  userType: string,
  checklistId: number,
  onClose: () => void,
  uploadData: UploadedFileData & { actionId: string } | undefined,
  isVisibleForGuardian: boolean,
  isVisibleForStudent: boolean,
  todo?: Todo
}

function normalizeURL(url: string) {
  if (url.length < DEFAULT_REDIRECT_TO.length) return DEFAULT_REDIRECT_TO

  const parserdUrl = url.replaceAll('http://', '').replaceAll('https://', '')
  return `https://${parserdUrl}`
}

function OnboardingTodoModal({ onClose, checklistId, isVisibleForGuardian, isVisibleForStudent, todo, uploadData }: OnboardingTodoModalProps) {
  const [todoActions, setTodoActions] = React.useState<TaskAction[]>([])
  const submit = useSubmit()
  const navigation = useNavigation()
  const isSubmitting = navigation.state === 'submitting'

  React.useEffect(() => {
    if (todo?.actions) {
      setTodoActions(todo?.actions.map((action) => ({ ...action, id: uuid(), previewData: undefined })))
    }
  }, [])

  React.useEffect(() => {
    setTodoActions((prevState) => {
      return prevState.map((action) => {
        if (uploadData && action.id === uploadData.actionId) {
          return { ...action, previewData: uploadData }
        }
        return action
      })
    })
  }, [uploadData])

  const handleAddAction = (type: TaskActionType) => {
    setTodoActions((prevState) => [...prevState, { title: '', redirectTo: DEFAULT_REDIRECT_TO, id: uuid(), type, thumbnailUrl: '' }])
  }

  const handleRemoveAction = (id: string) => {
    setTodoActions((prevState) => [...prevState.filter((action) => action.id !== id)])
  }

  const handleUploadFile = ({ uploadType, actionId }: { uploadType: 'file' | 'video', actionId: string }) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0])
    formData.append('_action', 'upload_file')
    formData.append('action_id', actionId)
    formData.append('upload_type', uploadType)
    submit(formData, { method:'POST', encType: 'multipart/form-data' })
  }

  const handleRemoveUploadedFile = (actionId: string) => {
    const fileInput = document.getElementById(`file-${actionId}`) as HTMLInputElement
    if (fileInput) {
      fileInput.value = ''
    }
    setTodoActions((prevState) => {
      return prevState.map((action) => {
        if (action.id === actionId) {
          return { ...action, previewData: undefined, redirectTo: '' }
        }
        return action
      })
    })
  }

  return (
    <Dialog
      open
      onClose={onClose}
    >
      <Dialog.Content>
        <Dialog.Header>
          <Dialog.Title>Task Form</Dialog.Title>
          <Dialog.Description>{todo?.phaseTitle ?? 'Create your task here'}</Dialog.Description>
        </Dialog.Header>
        <ReactRouterForm method="POST" encType="multipart/form-data" >
          <Dialog.Body>
            <input type="hidden" name="onboarding_checklist_id" value={checklistId} />
            {todo?.id && <input type="hidden" name="id" value={todo?.id} />}
            <TextField label="Title" name="title" required defaultValue={todo?.title} />
            <div className="flex gap-2 flex-col">
              <label>
                Student Description
              </label>
              <SlateTextarea
                /* @ts-ignore: Unreachable code error */
                noAttachments
                value={todo?.studentDescription}
                readOnly={!isVisibleForStudent}
                name="rte_student_description"
                placeholder="Type student description here"
                className="h-48"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label>
                Guardian Description
              </label>
              <SlateTextarea
                /* @ts-ignore: Unreachable code error */
                noAttachments
                value={todo?.guardianDescription}
                readOnly={!isVisibleForGuardian}
                name="rte_guardian_description"
                placeholder="Type guardian description here"
                className="h-48"
              />
            </div>
            <div className="flex justify-between">
              <label>
                Actions (Optional)
              </label>
              <Popover>
                <Popover.Trigger>
                  <Button type="button" variant="ghost">
                    <Icon name="plus"></Icon>
                  </Button>
                </Popover.Trigger>
                <Popover.Content className="flex flex-col gap-3" align="end">
                  <button onClick={() => handleAddAction('link')} type="button" className="w-20 text-start cursor-pointer hover:bg-10 bg-white hover:font-semibold">Add Link</button>
                  <button onClick={() => handleAddAction('file')} type="button" className="w-20 text-start cursor-pointer hover:bg-10 bg-white hover:font-semibold">Add File</button>
                  <button onClick={() => handleAddAction('video')} type="button" className="w-20 text-start cursor-pointer hover:bg-10 bg-white hover:font-semibold">Add Video</button>
                </Popover.Content>
              </Popover>
            </div>
            <div className="flex flex-col gap-4 max-h-96 overflow-y-auto">
              {todoActions.length > 0 && todoActions.map((action, index) => (
                <TaskActionItem
                  key={action.id}
                  index={index}
                  action={action}
                  onRemove={handleRemoveAction}
                  onRemoveUploadedFile={handleRemoveUploadedFile}
                  onFileUpload={handleUploadFile}
                  isSubmitting={isSubmitting}
                />
              ))}
            </div>
          </Dialog.Body>
          <Dialog.Footer>
            <Button onClick={onClose} variant="outlined" type="button">Cancel</Button>
            <Button type="submit" disabled={isSubmitting}>Save</Button>
          </Dialog.Footer>
        </ReactRouterForm>
      </Dialog.Content>
    </Dialog>
  )
}

interface TaskActionItemProps {
  onRemove: (id: string) => void,
  action: TaskAction,
  isSubmitting: boolean,
  index: number,
  onRemoveUploadedFile: (actionId: string) => void,
  onFileUpload: (params: { uploadType: 'file' | 'video', actionId: string }) => (e: React.ChangeEvent<HTMLInputElement>) => void
}

function TaskActionItem({ isSubmitting, onRemove, action, index, onFileUpload, onRemoveUploadedFile }: TaskActionItemProps) {
  return (
    <div key={action.id} className="pl-4 py-6 bg-gray-5 rounded-lg flex items-center space-x-3">
      <div className="flex flex-col w-full space-y-4">
        <TextField
          required
          fullWidth
          label="Title"
          defaultValue={action.title}
          name={`actions[${index}][title]`}
        />
        <ActionSpecificFields
          action={action}
          index={index}
          onFileUpload={onFileUpload}
          onRemoveUploadedFile={onRemoveUploadedFile}
          isSubmitting={isSubmitting}
        />
      </div>
      <Button
        type="button"
        variant="ghost"
        onClick={() => onRemove(action.id)}
      >
        <Icon name="trash" />
      </Button>
    </div>
  )

}

type ActionSpecificFieldsProps = Omit<TaskActionItemProps, 'onRemove'>

function ActionSpecificFields({ action, index, onFileUpload, onRemoveUploadedFile, isSubmitting }: ActionSpecificFieldsProps) {
  switch (action.type) {
    case 'link':
      return (
        <>
          <TextField
            required
            fullWidth
            label="Redirect To"
            pattern="https://.*"
            defaultValue={action.redirectTo}
            name={`actions[${index}][redirect_to]`}
            onChange={(e) => e.target.value = normalizeURL(e.target.value)}
          />
          <input type="hidden" name={`actions[${index}][type]`} value="link" />
        </>
      )
    case 'file':
      return (
        <>
          <input type="hidden" name={`actions[${index}][type]`} value="file" />
          <input type="hidden" name={`actions[${index}][redirect_to]`} defaultValue={action?.previewData?.url ?? action.redirectTo} />
          <label htmlFor={`file-${action.id}`}>
            File
          </label>
          <input
            required={action.redirectTo === ''}
            type="file"
            name="file"
            accept=".pdf,.docx,.csv,.xlsx,.pptx,.doc,.xls,.ppt"
            multiple={false}
            onChange={onFileUpload({ uploadType: 'file', actionId: action.id })}
            id={`file-${action.id}`}
          />
          <Typography variant="footnote">Supported format: .pdf, .docx, .csv, .xlsx, .pptx, .doc, .xls, .ppt. File should be no larger than 10MB.</Typography>
          {isSubmitting && <ContentPreviewLoading />}
          {action.previewData || (action.redirectTo && action.redirectTo !== DEFAULT_REDIRECT_TO)
            ? <ContentPreview
              onRemoveUploadedFile={() => onRemoveUploadedFile(action.id)}
              previewData={action.previewData ?? { type: 'file', url: action.redirectTo, fileName: action.title }}
            />
            : null}
        </>
      )
    case 'video':
      return (
        <>
          <input type="hidden" name={`actions[${index}][type]`} value="video" />
          <input type="hidden" name={`actions[${index}][redirect_to]`} value={action?.previewData?.url ?? action.redirectTo} />
          <label htmlFor={`file-${action.id}`}>Video</label>
          <input
            required={action.redirectTo === ''}
            type="file"
            name="file"
            accept=".heic,.mp4,.webm,.mov"
            multiple={false}
            onChange={onFileUpload({ uploadType: 'video', actionId: action.id })}
            id={`file-${action.id}`}
          />
          <Typography variant="footnote">Supported formats: .heic, .mp4, .webm, .mov. File should be no larger than 20MB.</Typography>
          {isSubmitting && <ContentPreviewLoading />}
          {action.previewData || (action.redirectTo && action.redirectTo !== DEFAULT_REDIRECT_TO)
            ? <ContentPreview
              onRemoveUploadedFile={() => onRemoveUploadedFile(action.id)}
              previewData={action.previewData ?? { type: 'video', url: action.redirectTo, fileName: action.title, thumbnailUrl: action.thumbnailUrl }}
            />
            : null}
        </>
      )
    default:
      return null
  }
}

function ContentPreviewLoading() {
  return <div className="rounded-lg animate-pulse bg-gray-30 w-96 h-24"></div>
}

interface ContentPreviewProps {
  previewData: UploadedFileData,
  onRemoveUploadedFile: () => void
}

function ContentPreview({ previewData, onRemoveUploadedFile }: ContentPreviewProps) {
  return (
    <div className="p-4 rounded-lg bg-white shadow-md border border-gray-10 content-center relative w-full max-h-96">
      {previewData.type === 'video' && (
        <video
          poster={previewData.thumbnailUrl}
          className="object-cover object-center w-full h-full rounded-md"
          controls
        >
          <source
            src={previewData.url}
            type="video/mp4"
          />
        </video>
      )}
      {previewData.type === 'file' && (
        <a href={previewData.url} target="_blank" rel="noreferrer" className="flex items-center max-w-96 justify-between border p-4 rounded-lg border-gray-20">
          <div className="flex items-center gap-4">
            <div className="rounded-lg p-4 bg-gray-10">
              <Icon name="paperclip-2" />
            </div>
            <div className="flex flex-col w-60">
              <Typography variant="body" weight="bold" className="text-ellipsis overflow-hidden whitespace-nowrap">{previewData.fileName}</Typography>
              <Typography variant="footnote">{previewData.url.slice(-3)}</Typography>
            </div>
          </div>
          <Icon name="external-link" />
        </a>
      )}
      <button className="bg-danger-40 text-white p-1 cursor-pointer w-fit flex items-center rounded-full -top-2 -right-3 absolute" type="button" onClick={onRemoveUploadedFile}>
        <Icon name="cross" size="xs" />
      </button>
    </div>
  )
}



export const Onboarding$TodoIdRoute = {
  action, loader, Element,
}
