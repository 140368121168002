import React from 'react'
import { Typography, unstable_Tooltip as Tooltip } from '@design-system'
import { Student } from './types'

interface CellMathProps extends Pick<Student, 'mathTitles'> { }

export const CellMath = ({ mathTitles }: CellMathProps) => {
  return (
    <>
      {mathTitles?.map(title => <Tooltip key={title} content={title}><p className='w-44 whitespace-nowrap overflow-hidden text-ellipsis'>{title}</p></Tooltip>)}
    </>
  )
}
