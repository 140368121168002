import { Button, Typography } from '@design-system'
import React, { useState } from 'react'
import { useFetcher } from 'react-router'

interface ArchiveFutureInstancesBannerProps {
  startHidden?: boolean
  shouldProposeArchivingFutureExperienceInstances: boolean
  futureInstances: {
    experience_title: string
    cycle_title: string
    experience_id: number
  }[]
}

export function ArchiveFutureInstancesBanner({ startHidden = true, shouldProposeArchivingFutureExperienceInstances, futureInstances }: ArchiveFutureInstancesBannerProps) {
  const fetcher = useFetcher()
  const [hidden, setHidden] = useState(startHidden)

  if (!shouldProposeArchivingFutureExperienceInstances) return null

  const isLoading = fetcher.state !== 'idle'
  return (
    <li
      className={`p-4 bg-yellow-100 rounded-lg justify-start items-center gap-10 inline-flex ${hidden ? 'hidden' : ''}`}
      aria-hidden={hidden}
    >
      <div className="grow">
        <Typography variant="body" weight="bold" className="pb-2">
          Archive future instances
        </Typography>
        <Typography variant="body">
          This is a multi-cycle experience and has instances in future cycles.
        </Typography>
        <Typography variant="body" className="pb-2">
          If the student finished the course in advance and the future instances are no longer needed, you can archive them.
        </Typography>
        <Typography variant="body">
          Experiences that will be archived:
        </Typography>
        <ul className="list-disc ml-5">
          {futureInstances.map(inst => (
            <li>
              <Typography>
                <span>{inst.cycle_title}</span>
                <span> - </span>
                <span>{inst.experience_title}</span>
              </Typography>
            </li>
          ))}
        </ul>
      </div>

      <div className="justify-start items-center gap-2 flex">
        <fetcher.Form method="POST" className="space-x-2">
          <Button size="sm" type="submit" name="_action" value="archive_future_instances" loading={isLoading && fetcher.formData.get('_action') === 'archive_future_instances'} aria-busy={isLoading && fetcher.formData.get('_action') === 'archive_future_instances'} disabled={isLoading && fetcher.formData.get('_action') !== 'archive_future_instances'}>
            Archive future instances
          </Button>
        </fetcher.Form>
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setHidden(true)}
          aria-label="Dismiss review section"
          disabled={isLoading}
        >
          Dismiss
        </Button>
      </div>
    </li>
  )
}
