import React, { useEffect, useState } from 'react'
import { useActionData, useFetcher, useNavigate, useSearchParams } from 'react-router'
import { useToast } from '@hooks/useToast'
import Slideover from '@components/slideover'
import isSoraHomeLink from '@utils/isSoraHomeLink'
import openExternalLink from '@utils/openExternalLink'
import { Icon, Button, Typography } from '@design-system'
import { Notification, INotification } from '@blocks/notifications-list-item'
import { NotificationsFilter, NotificationTabs } from '@blocks/notifications-filter'

interface ActionData {
  redirect: string,
  openInNewTab: boolean,
  toast?: {
    message: string,
    appearance: 'success' | 'error',
  }
}

interface NotificationProps {
  notifications: INotification[],
  unreadNotificationsCount: number,
}

export function Notifications({ notifications, unreadNotificationsCount }: NotificationProps) {
  const [notificationsOpen, setNotificationsOpen] = useState(false)
  return (
    <>
      <Button type="button" size="sm" variant="outlined" color="soft" className="relative" onClick={() => setNotificationsOpen(true)}>
        <Icon name="bell" size="sm">
          {unreadNotificationsCount > 0 &&
            <span className={`${unreadNotificationsCount > 9 ? '-right-1 w-5' : '-right-0.5 w-4'} absolute z-40 -top-0.5 h-4`}>
              <span className="flex items-center justify-center h-full w-full rounded-full bg-accent">
                <small data-testid="notifications-number" className="text-white dark:text-gray-100 text-xxs mt-px font-bold">{unreadNotificationsCount > 9 ? '9+' : unreadNotificationsCount}</small>
              </span>
            </span>
          }
        </Icon>
      </Button>
      {notificationsOpen &&
        <NotificationsDrawer
          open
          notifications={notifications}
          onCloseNotifications={() => setNotificationsOpen(false)}
        />
      }
    </>
  )
}

type NotificationsDrawerProps = {
  open: boolean,
  onCloseNotifications: () => void,
  notifications: INotification[],
}

function NotificationsDrawer({ open, notifications, onCloseNotifications }: NotificationsDrawerProps) {
  return (
    <Slideover
      open={open}
      title={<Typography variant="heading-4" weight="bold">Notifications</Typography>}
      maxWidth="max-w-none sm:w-[520px]"
      setOpen={onCloseNotifications}
    >
      {open && <NotificationsContent notifications={notifications} onCloseNotifications={onCloseNotifications} />}
    </Slideover>
  )
}

type NotificationsContentProps = Pick<NotificationsDrawerProps, 'notifications' | 'onCloseNotifications'>

function NotificationsContent({ onCloseNotifications, notifications }: NotificationsContentProps) {
  const fetcher = useFetcher()
  const [selectedTab, setSelectedTab] = useState<NotificationTabs>(NotificationTabs.ALL)
  const navigate = useNavigate()
  const [_, setSearchParams] = useSearchParams()
  const actionData = useActionData() as ActionData
  useToast(actionData)

  useEffect(() => {
    if (actionData?.redirect) {
      actionData.openInNewTab ? openExternalLink(actionData.redirect) : navigate(actionData.redirect)
    }
  }, [actionData?.redirect])

  const handleDelete = (id: number) => fetcher.submit({ notificationId: id, _action: 'deleteNotification' }, { method:'POST' })

  const handleMarkAsRead = (id: number, isRead: boolean) => fetcher.submit({ notificationId: id, _action: 'readNotification', isRead }, { method:'POST' })

  const handleReadAllNotifications = () => fetcher.submit({ _action: 'readAllNotifications' }, { method:'POST' })

  const handleNavigateToNotificationsPage = (page: 'view' | 'preferences' | 'details', notificationId?: number) => {
    const pageSuffix = page === 'view' ?
      `view/${selectedTab}`
      : page === 'preferences'
        ? 'preferences'
        : `view/${selectedTab}/details/${notificationId}`
    const path = `/notifications/${pageSuffix}`

    onCloseNotifications()
    return navigate(path)
  }

  const handleSelectTab = (tab: NotificationTabs) => {
    setSelectedTab(tab)
    setSearchParams((prevParams) => ({ ...prevParams, type: tab }))
  }

  const handleReadAndRedirect = (notificationId: number, read: boolean, redirectUrl?: string, content?: Array<unknown>) => {
    if (!read) handleMarkAsRead(notificationId, read)
    if (content) return handleNavigateToNotificationsPage('details', notificationId)
    if (redirectUrl) {
      if (isSoraHomeLink(redirectUrl)) {
        onCloseNotifications()
        navigate(buildCorrectRedirect(redirectUrl))
      } else {
        openExternalLink(redirectUrl)
      }
    }
  }
  return (
    <section className="flex justify-between flex-col h-full">
      <div className="flex space-y-4 flex-col">
        <NotificationsFilter
          selectedTab={selectedTab}
          onSelectTab={handleSelectTab}
          onClickPreferencesIcon={() => handleNavigateToNotificationsPage('preferences')}
        />
        <div className="flex justify-end">
          <Button variant="ghost" color="accent" size="xs" onClick={handleReadAllNotifications}>
            Mark all as Read
          </Button>
        </div>
        <Notification.List hasNotifications={notifications?.length > 0}>
          {notifications?.map((notification) => (
            <Notification.Item
              key={notification.id}
              {...notification}
              onClick={() => handleReadAndRedirect(notification.id, notification.read, notification.redirectUrl, notification.content)}
              onDeleteNotification={() => handleDelete(notification.id)}
            />
          ))}
        </Notification.List>
      </div>
      <span style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.25)' }} className="rounded-full justify-center sticky bottom-5 self-center">
        <Button onClick={() => handleNavigateToNotificationsPage('view')}>
          View all
        </Button>
      </span>
    </section>
  )
}

function buildCorrectRedirect(url: string) {
  const parsedUrl = new URL((url.startsWith('/') && window.location.origin) + url)
  return parsedUrl.pathname + parsedUrl.search
}