import React from 'react'
import PropTypes from 'prop-types'
import { Button, Combobox, Icon, useFetcher } from '@design-system'
import { TemplateRemove } from './template-remove'

export const TemplateSelector = ({ planId, position, templateId, templateTitle }) => {
  const fetcher = useFetcher()
  const isLoading = fetcher.state === 'loading' && fetcher.formData?.get('cyclePosition') === String(position)

  const handleCancel = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('_action', 'cancel')
    fetcher.submit(formData, { method:'POST' })
  }

  if (!isLoading && fetcher.data?.templates) { 
    return (
      <fetcher.Form method="POST" className='flex flex-col'>
        <input type="hidden" name="_action" value='update_plan_template' />
        <input type="hidden" name="planId" value={planId || ''} />
        <input type="hidden" name="cyclePosition" value={position} />
        <Combobox name='templateId' className='rounded border-alpha/30' placeholder='Select one template' defaultValue={templateId} search={<Combobox.Search placeholder="Search a template" />}>
          {fetcher.data.templates?.map((group) => (
            <Combobox.Group key={`${planId}_${group.label}`} heading={group.label}>
              {group.items.map(item => (
                <Combobox.Item  key={item.id} value={item.id}>{item.title}</Combobox.Item>
              ))}
            </Combobox.Group>
          ))}
        </Combobox>
        <div className='flex flex-row gap-2 justify-end py-2'>
          <Button variant='outlined' size='sm' onClick={handleCancel}>
            Cancel
          </Button>
          <Button type='submit' size='sm' loading={isLoading} disabled={isLoading}>
            Update
          </Button>
        </div>
      </fetcher.Form>
    )
  }

  return (
    <div className='flex flex-row gap-1 items-center'>
      <span className={templateTitle ? '' : 'text-alpha/40'}>{templateTitle || 'No template selected yet'}</span>
      <fetcher.Form method="GET">
        <input type="hidden" name="_action" value='get_plan_templates' />
        <Button type='submit' variant='ghost' size='xs' loading={isLoading}>
          <Icon name='edit-2' size='xs' />
        </Button>
      </fetcher.Form>
      <TemplateRemove planId={planId} templateId={templateId} />
    </div>
  )
}
TemplateSelector.propTypes = {
  planId: PropTypes.number,
  position: PropTypes.number,
  templateId: PropTypes.number,
  templateTitle: PropTypes.string,
  templates: PropTypes.array,
}
