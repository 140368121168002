import React from 'react'
import { Student } from './types'
import { Button, DropdownMenu, Icon, unstable_Tooltip as Tooltip } from '@design-system'
import SoraLink from '@components/link'

interface CellActionsProps extends Pick<Student, 'id'> { }

export const CellActions = ({ id }: CellActionsProps) => {
  return (
    <DropdownMenu>
      <Tooltip content="More actions">
        <span>
          <DropdownMenu.Trigger asChild>
            <Button variant="ghost" size="xs">
              <span className="sr-only">Open menu</span>
              <Icon name="more-horiz-filled" size="xs" />
            </Button>
          </DropdownMenu.Trigger>
        </span>
      </Tooltip>
      <DropdownMenu.Content align="end">
        <DropdownMenu.Item asChild>
          <SoraLink to={`/employee/students/${id}`}>
            Go to Student Profile
          </SoraLink>
        </DropdownMenu.Item>
        <DropdownMenu.Item asChild>
          <SoraLink to={`/employee/students/${id}/notes/form`}>
            Add note
          </SoraLink>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu>
  )
}
