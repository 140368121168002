import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@designsystem'
import { useFetcher } from '@design-system'

export const SessionTimes = ({ cycleId, learningBlocks = [], setSessions, setLearningBlockId }) => {
  const fetcher = useFetcher()

  if (fetcher.data?.sessions) {
    setSessions(fetcher.data?.sessions)
  }

  const options = cycleId ? [
    { label: 'Custom', value: null },
    ...learningBlocks.map(lb => ({ label: lb.title, value: lb.id }))
  ] : []

  return (
    <fetcher.Form method="GET" className="w-full flex flex-col justify-between">
      <input type="hidden" name="_action" value="session-times" />
      <input type="hidden" name="target_cycle_id" value={cycleId} /> 
      <label className="w-full flex flex-col justify-between">
        <h3 className="ml-3 font-bold text-xl">Sessions Times</h3>
        <small className="ml-3 mb-3">All session times are in (Eastern Time)</small>
        <Select
          defaultValue={null}
          name="learning_block_id"
          options={options} 
          placeholder="Pick Session Time"
          onChange={(event) => {
            setLearningBlockId(Number(event.currentTarget.value))
            fetcher.submit(event.target.form)
          }}
          disabled={options.length === 0}
        />
      </label>
    </fetcher.Form>
  )
}
SessionTimes.propTypes = {
  cycleId: PropTypes.number,
  learningBlocks: PropTypes.array,
  setSessions: PropTypes.func,
  setLearningBlockId: PropTypes.func,
}
