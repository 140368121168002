import React from 'react'
import { useLoaderData, Await, Navigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ExcuseReport } from './excuse-report'

const EmployeeAttendancesReportRoute = () => {
  const loaderData = useLoaderData()
  return (
    <React.Suspense fallback={<Loading/>}>
      <Await resolve={loaderData} errorElement={<Error/>}>
        {({ data }) => <ExcuseReport data={data}/>}
      </Await>
    </React.Suspense>
  )
}

function Loading() {
  return (
    <div className="flex flex-col items-center justify-center">
      <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-black dark:text-white" />
      <span>Loading excuses</span>
    </div>
  )
}
function Error() { return <Navigate to="/" replace={true} /> }

export default EmployeeAttendancesReportRoute
