import React from 'react'
import axios from 'axios'
import { useThisPageTitle } from '@hooks/usePageTitle'
import serializeFormData from '@utils/serializeFormData'
import { redirect, useActionData, useLoaderData } from 'react-router'
import WorkbenchUnitForm from './form'
import { useToast } from '@hooks/useToast'

export default function NewUnitWorkbenchRoute() {
  const loaderData = useLoaderData()
  const topics = loaderData.topics || []
  useThisPageTitle(`Workbench Create Unit`)
  useToast(useActionData())

  return (
    <WorkbenchUnitForm topics={topics} />
  )
}

export const loader = async () => {
  const result = await axios.get(`/pages/admin/workbench/units`)
  return result?.data
}

export const action = async ({ request }) => {
  const formData = await request.formData()
  const serializedFormData = serializeFormData(formData)
  try {
    await axios.post(`/pages/admin/workbench/units`, { ...serializedFormData })
    return redirect('/workbench/units')
  } catch (error) {
    console.error(error)
    return {
      toast: { message: 'We encountered an error while creating the unit. If the issue persists, please reach out to our technical support for help.', appearance: 'error' }
    }
  }
}
